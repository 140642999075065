// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import omit from "lodash/omit";
import pick from "lodash/pick";
// assets
import { bold, header, property } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
// styles
import { Accordion, Button, Container, Dropdown, Input, Loader } from "semantic-ui-react";
// components
import Question from "../Question";
// redux
import { logout } from "store/User";

const loader = (
    <Loader active size="large">
        Загрузка практического урока
    </Loader>
);

class Practice extends Component {
    state = {
        activeIndex: 0,
        adding: false,
        deleting: false,
        editing: false,
        loading: false,
        position: 0,
        lessonId: "",
    };

    componentDidMount() {
        // fetch practice
        const id = this.props.match.params.id;
        this.fetchPractice(id);
    }

    fetchPractice = (id) => {
        this.setState({ loading: true });
        axios.get(adminRoutes.practices.getPractice(id)).then(
            (response) => {
                const practice = omit(response.data.message, ["questions"]);
                const questions = pick(response.data.message, ["questions"]).questions;
                // build question position options
                this.positions = [{ text: "В начало", value: 0 }];
                if (questions.length > 0) {
                    this.positions.push({ text: "В конец", value: questions.length });
                }
                for (let i = 0; i < questions.length - 1; i++) {
                    this.positions.push({
                        text: `После ${i + 1} вопроса`,
                        value: i + 1,
                    });
                }
                // set state
                this.setState({
                    loading: false,
                    practice: practice,
                    questions: questions,
                    position: questions.length,
                    lessonId: practice.lesson ? practice.lesson._id : "",
                });
                // fetch lessons
                this.fetchLessons();
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    fetchLessons = () => {
        this.setState({ loading: true });
        axios.get(adminRoutes.lessons.getLessons()).then(
            (response) => {
                // build lesson options
                this.lessonOptions = [];
                if (response.data.message.length > 0) {
                    this.lessonOptions.push({
                        text: "Ни к какому",
                        value: null,
                    });
                    for (let i = 0; i < response.data.message.length; i++) {
                        const lesson = response.data.message[i];
                        this.lessonOptions.push({
                            text: `${lesson.section}. ${lesson.name}`,
                            value: lesson._id,
                        });
                    }
                }

                this.setState({ loading: false });
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    editPractice = () => {
        const r = window.confirm("Редактировать описание этого практического урока?");
        if (r) {
            this.setState({ editing: true });
            // build practice payload
            let practice = pick(this.state.practice, ["name", "section", "groupName", "groupNumber"]);
            axios
                .post(adminRoutes.practices.editPractice(this.state.practice._id), {
                    practice: {
                        ...practice,
                        lessonId: this.state.lessonId,
                    },
                })
                .then(
                    () => {
                        this.setState({ editing: false });
                        this.fetchPractice(this.state.practice._id);
                    },
                    (error) => {
                        this.setState({ editing: false });
                        console.error(error);
                    }
                );
        }
    };

    deletePractice = () => {
        const r = window.confirm("Удалить этот практический урок?");
        if (r) {
            this.setState({ deleting: true });
            axios.delete(adminRoutes.practices.deletePractice(this.state.practice._id)).then(
                () => {
                    this.setState({ deleting: false });
                    this.props.history.push("/admin/practices");
                },
                (error) => {
                    this.setState({ deleting: false });
                    console.error(error);
                }
            );
        }
    };

    addQuestion = (question) => {
        const r = window.confirm("Добавить новый вопрос?");
        if (r) {
            this.setState({ adding: true });
            const payload = {
                question: question,
                position: this.state.position,
            };

            // axios call
            axios.post(adminRoutes.practices.addQuestion(this.state.practice._id), payload).then(
                () => {
                    this.fetchPractice(this.state.practice._id);
                    this.setState({ adding: false });
                },
                (error) => {
                    this.setState({ adding: false });
                    console.error(error);
                }
            );
        }
    };

    handleAccordion = (e, data) => {
        const { index } = data;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;
        this.setState({ activeIndex: newIndex });
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    handleChangePractice = (e, data) => {
        let result = data.value;
        this.setState((prevState) => ({
            practice: {
                ...prevState.practice,
                [data.name]: result,
            },
        }));
    };

    render() {
        let questions = [];
        let practice;
        let name;

        if (this.state.practice) {
            name = {
                name: this.state.practice.name,
                section: this.state.practice.section,
            };
            if (this.state.questions && this.state.questions.length !== 0) {
                for (let i = 0; i < this.state.questions.length; i++) {
                    questions.push({
                        key: `admin-practice-question-${i}`,
                        title: `Вопрос ${i + 1}`,
                        content: {
                            content: (
                                <Question
                                    question={this.state.questions[i]}
                                    index={i}
                                    practiceId={this.state.practice._id}
                                    fetchPractice={this.fetchPractice}
                                />
                            ),
                        },
                    });
                }
            }
            practice = (
                <div style={{ fontSize: "15px" }}>
                    {header(`${name.section}. ${name.name}`, "22px", 200)}
                    {bold("Название практического урока")}
                    <Input
                        fluid
                        name="name"
                        placeholder="Название урока"
                        value={this.state.practice.name}
                        onChange={this.handleChangePractice}
                        style={{ marginBottom: "1em" }}
                    />
                    {bold("Секция")}
                    <Input
                        fluid
                        name="section"
                        placeholder="Секция"
                        value={this.state.practice.section}
                        onChange={this.handleChangePractice}
                        style={{ marginBottom: "1em" }}
                    />
                    {bold("Название группы")}
                    <Input
                        fluid
                        name="groupName"
                        placeholder="Название группы"
                        value={this.state.practice.groupName || ""}
                        onChange={this.handleChangePractice}
                        style={{ marginBottom: "1em" }}
                    />
                    {bold("Номер группы")}
                    <Input
                        fluid
                        name="groupNumber"
                        placeholder="Номер группы"
                        type="number"
                        value={this.state.practice.groupNumber || ""}
                        onChange={this.handleChangePractice}
                        style={{ marginBottom: "1em" }}
                    />
                    {property("Количество вопросов", this.state.questions.length)}
                    {bold("К какому уроку относится?")}
                    <Dropdown
                        fluid
                        selection
                        name="lessonId"
                        value={this.state.lessonId}
                        options={this.lessonOptions || []}
                        onChange={this.handleChange}
                        style={{ marginBottom: "1em" }}
                    />
                    <Button
                        loading={this.state.editing}
                        onClick={this.editPractice}
                        style={{
                            backgroundColor: "#32936f",
                            color: "#fff",
                            borderRadius: "0px",
                            boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
                            marginBottom: "8px",
                        }}
                    >
                        Редактировать практический урок
                    </Button>
                    <Button
                        loading={this.state.deleting}
                        onClick={this.deletePractice}
                        style={{
                            backgroundColor: "#cc515d",
                            color: "#fff",
                            borderRadius: "0px",
                            boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
                            marginBottom: "2em",
                        }}
                    >
                        Удалить практический урок
                    </Button>
                    {header("Вопросы урока", "20px", 400)}
                    <Accordion fluid styled panels={questions} style={{ marginBottom: "2em", padding: "1em" }} />
                    {/* new question */}
                    {header("Добавить новый вопрос", "20px", 400)}
                    {bold("Куда добавить блок?")}
                    <Dropdown
                        fluid
                        selection
                        name="position"
                        value={this.state.position}
                        options={this.positions}
                        onChange={this.handleChange}
                        style={{ marginBottom: "1em" }}
                    />
                    <Question
                        fetchPractice={this.fetchPractice}
                        practiceId={this.state.practice._id}
                        position={this.state.position}
                        new
                    />
                </div>
            );
        }

        return <Container>{this.state.loading ? loader : practice}</Container>;
    }
}

export default connect(
    (store) => ({}),
    {
        logout,
    }
)(Practice);
