// modules
import React from "react";
// assets
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./index.css";
// styles
// components
// redux

const ImageProgressbar = (props) => {
    const { imgSrc, percentage } = props;

    return (
        <div className="dashboard-navigation-image-progressbar-container" onClick={() => (window.location = "/")}>
            <div className="dashboard-navigation-image-progressbar-circle">
                <CircularProgressbar percentage={percentage} />
            </div>
            <div className="dashboard-navigation-image-progressbar-image-wrapper">
                <img className="dashboard-navigation-image-progressbar-image" src={imgSrc} alt="" />
            </div>
        </div>
    );
};

export default ImageProgressbar;
