// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import axios from "axios";
// assets
import { bold } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
import { compareById } from "assets/utils";
// styles
import { Button, Checkbox, Container, Dropdown, Header, Image, Input, List, Loader, Message } from "semantic-ui-react";
import "./index.css";
// components
import Editor from "../../../Editor";
// redux
import { logout } from "store/User";

const loader = (
    <Loader active size="large">
        Загрузка курса
    </Loader>
);

const redirect = (context, path) => {
    context.props.history.push(path);
};

class Course extends Component {
    state = {
        loading: false,
        editing: false,
        showCoverUploadMsg: false,
        courseId: "",
        course: {
            name: "",
            description: "",
            language: "",
            hidden: false,
            cover: "",
            formulas: "",
        },
    };

    componentDidMount() {
        const courseId = this.props.match.params.id;
        this.setState({
            courseId: courseId,
        });
        this.fetchCourse(courseId);
    }

    fetchCourse = (courseId) => {
        this.setState({ loading: true });
        axios.get(adminRoutes.courses.getCourse(courseId)).then(
            (response) => {
                this.setState({ loading: false, course: response.data.message });
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    handleChange = (e, data) => {
        let result = data.value;
        if (data.name === "hidden") {
            result = data.checked;
        }

        this.setState((prevState) => ({
            ...prevState,
            course: {
                ...prevState.course,
                [data.name]: result,
            },
        }));
    };

    handleFormulasChange = (value) => {
        this.setState((prevState) => ({
            ...prevState,
            course: {
                ...prevState.course,
                formulas: value,
            },
        }));
    };

    handleEditCourse = () => {
        // show alert if course ID was not loaded
        if (!this.state.courseId) {
            alert("Произошла ошибка");
            return;
        }

        // show alert if name was not entered
        if (!this.state.course.name) {
            alert("Название курса не было введено");
            return;
        }

        const r = window.confirm("Редактировать описание этого курса?");
        if (r) {
            this.setState({ editing: true });

            // edit course
            axios.post(adminRoutes.courses.editCourse(this.state.courseId), { course: { ...this.state.course } }).then(
                () => {
                    this.setState({ editing: false });
                    this.fetchCourse(this.state.courseId);
                },
                () => {
                    this.setState({ editing: false });
                    alert("Ошибка редактирования курса");
                }
            );
        }
    };

    handleDeleteCourse = () => {
        // show alert if course ID was not loaded
        if (!this.state.courseId) {
            alert("Произошла ошибка");
            return;
        }

        const r = window.confirm("Удалить этот курс?");
        if (r) {
            // delete course
            axios.delete(adminRoutes.courses.deleteCourse(this.state.courseId)).then(
                () => {
                    // force redirect
                    window.location = "/admin/courses";
                },
                () => {
                    alert("Ошибка удаления курса");
                }
            );
        }
    };

    handleCoverUpload = async (e) => {
        // create a new form data
        const data = new FormData();
        // append file
        data.append("file", e.target.files[0]);
        // get file extension
        let extension = e.target.files[0].name.split(".");
        extension = extension[extension.length - 1];
        // append filename
        data.append("filename", `${this.state.courseId}-course-cover.${extension}`);
        // set uploading to true
        this.setState({ uploading: true });
        // axios call
        const response = await axios.post(adminRoutes.upload.uploadImage(), data).catch((error) => {
            alert('Произошла ошибка при загрузке обложки, попробуйте еще раз');
            this.setState({ uploading: false });
            return;
        });
        const shouldRefreshPage = this.state.course.cover;
        // set course cover
        this.setState((prevState) => ({
            ...prevState,
            course: {
                ...prevState.course,
                cover: response.data.message.link,
            },
            uploading: false,
            showCoverUploadMsg: true,
        }), async () => {
            // update course
            await axios.post(adminRoutes.courses.editCourse(this.state.courseId), { course: { ...this.state.course } })
            if (shouldRefreshPage) {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                setTimeout(() => {
                    this.handleDismissCoverUploadMsg();
                }, 3000);
            }
        });
    };

    handleDismissCoverUploadMsg = () => {
        this.setState({ showCoverUploadMsg: false });
    }

    render() {
        const { showCoverUploadMsg } = this.state;
        const { t } = this.props;
        const languageOptions = [
            {
                key: "ru",
                text: t("ru"),
                value: "ru",
            },
            {
                key: "kk",
                text: t("kk"),
                value: "kk",
            },
        ];

        let content;
        let lessonItems = [];
        if (this.state.course && this.state.course.lessons && this.state.course.lessons.length !== 0) {
            let lessons = [...this.state.course.lessons];
            lessons = lessons.sort(compareById);
            for (let i = 0; i < lessons.length; i++) {
                const lesson = lessons[i];
                const name = lesson.section ? `${lesson.section}. ${lesson.name}` : lesson.name;

                lessonItems.push(
                    <List.Item key={"admin-lesson-" + i}>
                        <List.Content>
                            <List.Header
                                style={{
                                    color: "#3697dd",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    marginBottom: "8px",
                                    textDecoration: lessons[i].hidden ? "line-through" : "",
                                }}
                                onClick={() => {
                                    redirect(this, `/admin/courses/${this.state.courseId}/lessons/${lesson._id}`);
                                }}
                            >
                                {name}
                                {lesson.hidden ? (
                                    <span style={{ marginLeft: "8px", fontWeight: "bold" }}>(урок скрыт)</span>
                                ) : null}
                            </List.Header>
                        </List.Content>
                    </List.Item>
                );
            }
        }
        content = (
            <div>
                <Header size="large" textAlign="center">
                    {this.state.course.name}
                </Header>

                <div style={{ marginBottom: "1em" }}>
                    {bold("Название курса")}
                    <Input
                        fluid
                        required
                        name="name"
                        placeholder="Название курса"
                        value={this.state.course.name}
                        onChange={this.handleChange}
                    />
                </div>

                <div style={{ marginBottom: "1em" }}>
                    {bold("Описание курса")}
                    <Input
                        fluid
                        required
                        name="description"
                        placeholder="Описание курса"
                        value={this.state.course.description}
                        onChange={this.handleChange}
                    />
                </div>

                <div style={{ marginBottom: "1em" }}>
                    {bold("Язык курса")}
                    <Dropdown
                        fluid
                        selection
                        required
                        name="language"
                        placeholder="Язык курса"
                        value={this.state.course.language}
                        onChange={this.handleChange}
                        options={languageOptions}
                    />
                </div>

                <div style={{ marginBottom: "1em" }}>
                    {bold("Скрыть курс?")}
                    <Checkbox
                        checked={this.state.course.hidden}
                        name="hidden"
                        toggle
                        onChange={this.handleChange}
                    />
                </div>

                <div style={{ marginBottom: "1em" }}>
                    {bold("Формулы")}
                    <Editor html={this.state.course.formulas} onChange={this.handleFormulasChange} />
                </div>

                <div style={{ marginBottom: "1em" }}>
                    {bold("Обложка курса")}
                    <Image
                        className="admin-courses-course-cover"
                        src={this.state.course.cover}
                        alt="Course cover"
                    />
                    {showCoverUploadMsg && (
                        <Message 
                            positive
                            onDismiss={this.handleDismissCoverUploadMsg}
                            header="Загрузка обложки курса"
                            content="Обложка курса обновлена"
                            style={{ marginTop: "1em" }}
                        />
                    )}
                </div>

                <div style={{ marginBottom: "1em" }}>
                    {this.state.uploading ? (
                        <Loader active inline="centered" size="large" />
                    ) : (
                        <input type="file" onChange={this.handleCoverUpload} />
                    )}
                    <div style={{ marginTop: "1em" }}>
                        <b>Размер файла не должен превышать 10 МБ</b>
                    </div>
                </div>

                <Button
                    type="button"
                    onClick={this.handleEditCourse}
                    style={{
                        backgroundColor: "#254441",
                        color: "#fff",
                        borderRadius: "0px",
                        boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
                        marginBottom: "1em",
                    }}
                >
                    Редактировать курс
                </Button>
                <Button
                    type="button"
                    onClick={this.handleDeleteCourse}
                    style={{
                        backgroundColor: "#cc515d",
                        color: "#fff",
                        borderRadius: "0px",
                        boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
                        marginBottom: "1em",
                    }}
                >
                    Удалить курс
                </Button>

                <Header size="medium" textAlign="center">
                    Уроки курса
                </Header>
                <Button
                    className="admin-courses-course-new-lesson"
                    onClick={() => redirect(this, `/admin/courses/${this.state.courseId}/lessons/new`)}
                >
                    Добавить урок в курс
                </Button>
                <List divided relaxed="very">
                    {lessonItems}
                </List>
            </div>
        );

        return <Container>{this.state.loading ? loader : content}</Container>;
    }
}

export default connect(
    (store) => ({}),
    {
        logout,
    }
)(withTranslation()(Course));
