// modules
import { applyMiddleware, combineReducers, createStore } from 'redux';
// middleware
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
// reducers
import Config from './Config';
import UI from './UI';
import User from './User';
import { setLocale } from './Config';
import { set } from './User';

// root reducer
const reducer = combineReducers({
  config: Config,
  ui: UI,
  user: User
});

// middleware
const middleware = applyMiddleware(promise(), thunk);
// store
const store = createStore(reducer, middleware);

// restore locale from local storage
// const locale = localStorage.getItem('workternship-locale');
// if (locale) {
//   // set locale
//   store.dispatch(setLocale(locale));
// }

// restore user from local storage
const userString = localStorage.getItem('workternship-user');
if (userString) {
  // parse JSON-encoded string
  const user = JSON.parse(userString);
  // set user
  store.dispatch(set(user));
}

export default store;
