// modules
import React, { Component } from "react";
import axios from "axios";
// assets
import { bold } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
// styles
import { Button } from "semantic-ui-react";
import "react-quill/dist/quill.snow.css";
// components
import ReactQuill from "react-quill";
// redux

// edit style
const buttonStyle = (backgroundColor) => ({
    backgroundColor: backgroundColor,
    color: "#fff",
    borderRadius: "0px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    marginBottom: "1em",
});

class ExtraMaterial extends Component {
    // load state from props
    state = {
        extraMaterial: this.props.extraMaterial ? this.props.extraMaterial : "",
        editing: false,
    };

    // load updated block from props to state
    componentDidUpdate(prevProps) {
        if (
            this.props.extraMaterial !== this.state.extraMaterial &&
            this.props.extraMaterial !== prevProps.extraMaterial
        ) {
            this.setState({
                extraMaterial: this.props.extraMaterial,
            });
        }
    }

    handleChange = (value) => {
        this.setState({ extraMaterial: value });
    };

    handleClear = () => {
        this.setState({ extraMaterial: "" }, () => {
            this.handleEdit();
        });
    };

    handleEdit = () => {
        const r = window.confirm("Редактировать доп. материалы?");
        if (r) {
            this.setState({ editing: true });
            axios
                .post(adminRoutes.lessons.editExtraMaterial(this.props.id), { extraMaterial: this.state.extraMaterial })
                .then(
                    () => {
                        this.setState({ editing: false });
                        this.props.fetchLesson(this.props.id);
                    },
                    () => {
                        this.setState({ editing: false });
                    }
                );
        }
    };

    render() {
        return (
            <div>
                <div style={{ marginBottom: "2em" }}>
                    {bold("Дополнительные материалы:")}
                    <ReactQuill value={this.state.extraMaterial} onChange={this.handleChange} />
                </div>

                <div>
                    <Button loading={this.state.editing} onClick={this.handleEdit} style={buttonStyle("#1099d3")}>
                        Редактировать доп. материалы
                    </Button>
                    <Button onClick={this.handleClear} style={buttonStyle("#cc515d")}>
                        Очистить
                    </Button>
                </div>
            </div>
        );
    }
}

export default ExtraMaterial;
