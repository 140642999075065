// modules
import React, { useCallback, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import axios from 'axios';
import { nanoid } from 'nanoid';
// assets
import { dashboardRoutes } from 'assets/routes';
// styles
import 'react-quill/dist/quill.snow.css';
// components
import { Button } from 'semantic-ui-react';
import { InputFile } from 'semantic-ui-react-input-file';
import MathjaxWrapper from 'components/MathjaxWrapper';
// redux

const StyledFileUploadBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledInputContainer = styled.div`
  margin-bottom: 1em;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledFilePreview = styled.div`
  margin-left: 1em;
  font-size: 16px;
  font-weight: 400;
`;

const StyledUploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:8080" : "https://workternship.com";

const FileUploadBlock = ({ block, courseId, lessonId, highlighted, text }) => {
  const [previewFile, setPreviewFile] = useState(null);
  const [file, setFile] = useState(null);
  const [isFileUploaded, setFileUploaded] = useState(block.path);

  const handleFileChange = useCallback(event => {
    const { files } = event.target;

    if (files[0].size / 1000000 > 20) {
      alert('Размер файла слишком большой');
      return;
    }

    setPreviewFile(URL.createObjectURL(files[0]));
    setFile(files[0]);
  });

  const handleFileUpload = useCallback(() => {
    if (!file) {
      alert('Файл не был выбран');
      return;
    }

    // create a new form data
    const data = new FormData();
    // append file
    data.append('file', file);
    // get file extension
    let extension = file.name.split('.');
    extension = extension[extension.length - 1];
    // append file name
    data.append('filename', file.name);
    // append file path
    data.append('filepath', `${nanoid(12)}.${extension}`);

    // upload file
    axios
      .post(dashboardRoutes.uploadUserFile(courseId, lessonId, block._id), data)
      .then(() => {
        setFileUploaded(true);
      })
      .catch(err => {
        console.error(err);
      });
  });

  return (
    <StyledFileUploadBlockContainer className={highlighted ? 'highlighted' : ''}>
      <div className="ql-editor">
        <MathjaxWrapper text={ReactHtmlParser(text)} />
      </div>

      <StyledInputContainer>
        {!isFileUploaded && (
          <InputFile
            input={{
              id: 'file-upload-component',
              onChange: handleFileChange
            }}
          />
        )}
        {previewFile && file && file.name && (
          <StyledFilePreview>
            <a href={previewFile} rel="noopener noreferrer" target="_blank">
              {file.name}
            </a>
          </StyledFilePreview>
        )}
        {block && block.name && block.path && (
          <StyledFilePreview>
            <a href={`${baseUrl}${block.path}`} rel="noopener noreferrer" target="_blank">
              {block.name}
            </a>
          </StyledFilePreview>
        )}
      </StyledInputContainer>

      <StyledUploadButtonContainer>
        <Button disabled={isFileUploaded} primary onClick={handleFileUpload}>
          Загрузить файл
        </Button>
      </StyledUploadButtonContainer>
    </StyledFileUploadBlockContainer>
  );
};

export default FileUploadBlock;
