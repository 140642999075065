// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
import { logoutAndScrollToTop } from 'utils';
// styles
import { Loader, Menu, Sidebar } from 'semantic-ui-react';
// components
// redux
import { toggleSidebarMenuVisibility } from '../../../store/UI';

// styled components
const StyledSidebarMenuWrapper = styled.div`
    .ui.sidebar {
        background-color: #184686;
    }

    ${media.lessThan('small')`
        .ui.sidebar {
            width: 320px;
            padding: 16px;
        }
    `}

    ${media.between('small', 'medium')`
        .ui.sidebar {
            width: 400px;
            padding: 20px;
        }
    `}

    ${media.between('medium', 'large')`
        .ui.sidebar {
            width: 480px;
            padding: 24px;
        }
    `}

    ${media.greaterThan('large')`
        .ui.sidebar {
            width: 560px;
            padding: 24px 24px 80px 24px;
        }
    `}
`;

const StyledCrossButton = styled.svg`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  color: white;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledUserWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon img {
        width: 100%;
    }

    .name {
        color: white;
        font-weight: 600;
        text-transform: uppercase;
    }

    ${media.lessThan('small')`
        margin-bottom: 24px;

        .icon {
            margin-right: 12px;
            width: 64px;
        }

        .name {
            font-size: 16px;
        }
    `}

    ${media.between('small', 'medium')`
        margin-bottom: 32px;

        .icon {
            margin-right: 16px;
            width: 72px;
        }

        .name {
            font-size: 18px;
        }
    `}

    ${media.greaterThan('medium')`
        margin-bottom: 40px;

        .icon {
            margin-right: 16px;
            width: 80px;
        }

        .name {
            font-size: 18px;
        }
    `}
`;

const StyledLinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    a,
    span {
        width: fit-content;
        cursor: pointer;
        color: white;
        font-weight: 500;

        ${media.lessThan('small')`
            margin-bottom: 8px;
            font-size: 14px;
        `}

        ${media.between('small', 'medium')`
            margin-bottom: 12px;
            font-size: 16px;
        `}

        ${media.greaterThan('medium')`
            margin-bottom: 16px;
            font-size: 18px;
        `}

        &:hover {
            color: white;
            padding-bottom: 1px;
            border-bottom: 1px solid white;
        }
    }
`;

const StyledItemsList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  border-top: 1px solid white;
`;

const StyledItem = styled.div`
    line-height: 1.4;

    a {
        pointer-events: ${props => (props.available ? 'auto' : 'none')};
        color: ${props => (props.available ? '#dbdbdb' : '#838383')};
    }

    a.active {
        font-weight: 600;
        color: white;
    }

    a:hover {
        color: white;
    }

    ${media.lessThan('small')`
        margin-bottom: 4px;
        font-size: 14px;
    `}

    ${media.between('small', 'medium')`
        margin-bottom: 4px;
        font-size: 16px;
    `}

    ${media.greaterThan('medium')`
        margin-bottom: 8px;
        font-size: 18px;
    `}
`;

class SidebarMenu extends Component {
  logout = () => {
    // hide sidebar menu
    this.props.toggleSidebarMenuVisibility(false);
    // logout
    logoutAndScrollToTop();
  };

  render() {
    const { t } = this.props;
    const hasCompletedPayment = this.props.payment && this.props.payment.completed;

    const items = this.props.sidebarMenuItems.map((item, index) => (
      <StyledItem key={`sidebar-menu-item-${index}`} available={hasCompletedPayment && item.available}>
        <NavLink activeClassName="active" onClick={() => this.props.toggleSidebarMenuVisibility(false)} to={item.to}>
          <span className="section">{item.section ? `${item.section}. ` : item.section}</span>
          <span>{item.displayName ? item.displayName : item.name}</span>
        </NavLink>
      </StyledItem>
    ));

    return (
      <StyledSidebarMenuWrapper>
        <Sidebar
          as={Menu}
          animation="overlay"
          direction="right"
          inverted
          vertical
          onHide={() => this.props.toggleSidebarMenuVisibility(false)}
          visible={this.props.isSidebarMenuVisible}
        >
          <StyledCrossButton
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => this.props.toggleSidebarMenuVisibility(false)}
          >
            <line x1="0" y1="0" x2="24" y2="24" stroke="white" strokeWidth="2" />
            <line x1="0" y1="24" x2="24" y2="0" stroke="white" strokeWidth="2" />
          </StyledCrossButton>

          <StyledUserWrapper>
            {/* <div className="icon"></div> */}
            <div className="name">{`${this.props.lastName} ${this.props.firstName}`}</div>
          </StyledUserWrapper>

          <StyledLinksWrapper>
            <Link to="/dashboard/profile" onClick={() => this.props.toggleSidebarMenuVisibility(false)}>
              {t('dashboard.profile.title')}
            </Link>
            <Link to="/dashboard/courses" onClick={() => this.props.toggleSidebarMenuVisibility(false)}>
              {t('landing.courseList')}
            </Link>
            <Link to="/" onClick={() => this.props.toggleSidebarMenuVisibility(false)}>
              {t('landing.title')}
            </Link>
            <span onClick={this.logout}>{t('logout')}</span>
          </StyledLinksWrapper>

          {this.props.loading ? <Loader active inverted size="large" /> : <StyledItemsList>{items}</StyledItemsList>}
        </Sidebar>
      </StyledSidebarMenuWrapper>
    );
  }
}

const mapStateToProps = state => ({
  lastName: state.user.user.lastName,
  firstName: state.user.user.firstName,
  payment: state.user.user.payment,
  isSidebarMenuVisible: state.ui.isSidebarMenuVisible,
  sidebarMenuItems: state.ui.sidebarMenuItems
});

const mapDispatchToProps = dispatch => ({
  toggleSidebarMenuVisibility: value => dispatch(toggleSidebarMenuVisibility(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SidebarMenu)));
