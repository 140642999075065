// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { adminRoutes } from 'assets/routes';
import { mongoObjectId } from 'assets/utils';
// styles
// components
import { Button, Container, Form, Header, Image, Input, Loader, Table } from 'semantic-ui-react';
import { InputFile } from 'semantic-ui-react-input-file';
// redux
import { logout } from 'store/User';

class DialoguePersons extends Component {
  state = {
    loading: false,
    uploading: false,
    dialoguePersons: [],
    dialoguePerson: {
      _id: '',
      name: '',
      image: '',
      previewImage: ''
    }
  };

  componentDidMount() {
    this.fetchDialoguePersons();
  }

  fetchDialoguePersons = () => {
    this.setState({ loading: true });

    axios
      .get(adminRoutes.dialoguePersons.getDialoguePersons())
      .then(response => {
        this.setState({ loading: false, dialoguePersons: response.data.message });
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response.status === 401) {
          this.props.logout();
        }
      });
  };

  createDialoguePerson = async () => {
    const { name, image } = this.state.dialoguePerson;

    // validation
    if (!name || !image) {
      alert('Выберите имя и изображения персонажа!');
      return;
    }

    const r = window.confirm('Создать нового персонажа?');
    if (r) {
      // generate id
      const _id = mongoObjectId();
      // set uploading to true
      this.setState({ uploading: true });
      try {
        // upload image
        const uploadResponse = await this.uploadDialoguePersonImage(_id, image);
        // create dialogue person with uploaded image
        await axios.post(adminRoutes.dialoguePersons.createDialoguePerson(), {
          dialoguePerson: {
            _id: _id,
            name: name,
            image: uploadResponse.data.message.link
          }
        });
        // get dialogue persons
        this.fetchDialoguePersons();
        // reset state
        this.setState({
          uploading: false,
          dialoguePerson: {
            _id: '',
            name: '',
            image: '',
            previewImage: ''
          }
        });
      } catch (err) {
        this.setState({ uploading: false });
        console.error(err);
      }
    }
  };

  editDialoguePerson = async dialoguePerson => {
    const { _id, name, previewImage } = dialoguePerson;
    let { image } = dialoguePerson;

    // validation
    if (!_id || !name || !image) {
      alert('Что-то пошло не так!');
      return;
    }

    const r = window.confirm('Редактировать персонажа?');
    if (r) {
      // set uploading to true
      this.setState({ uploading: true });
      try {
        // upload image
        if (previewImage) {
          const uploadResponse = await this.uploadDialoguePersonImage(_id, image);
          image = uploadResponse.data.message.link;
        }
        // edit dialogue person
        await axios.post(adminRoutes.dialoguePersons.editDialoguePerson(_id), {
          dialoguePerson: {
            name: name,
            image: image
          }
        });
        // get dialogue persons
        this.fetchDialoguePersons();
        // reset state
        this.setState({ uploading: false });
      } catch (err) {
        this.setState({ uploading: false });
        console.error(err);
      }
    }
  };

  deleteDialoguePerson = id => {
    // validation
    if (!id) {
      alert('Что-то пошло не так!');
      return;
    }

    const r = window.confirm('Удалить персонажа?');
    if (r) {
      axios
        .delete(adminRoutes.dialoguePersons.deleteDialoguePerson(id))
        .then(() => {
          // get dialogue persons
          this.fetchDialoguePersons();
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  uploadDialoguePersonImage = (id, image) =>
    new Promise((resolve, reject) => {
      // create a new form data
      const data = new FormData();
      // append file
      data.append('file', image);
      // get file extension
      let extension = image.name.split('.');
      extension = extension[extension.length - 1];
      // append filename
      data.append('filename', `dialogue-person-${id}.${extension}`);
      // upload image
      axios
        .post(adminRoutes.upload.uploadImage(), data)
        .then(response => resolve(response))
        .catch(err => reject(err));
    });

  handleDialoguePersonNameChange = event => {
    const { value } = event.target;

    this.setState(prevState => ({
      dialoguePerson: {
        ...prevState.dialoguePerson,
        name: value
      }
    }));
  };

  handleDialoguePersonImageChange = event => {
    const { files } = event.target;

    this.setState(prevState => ({
      dialoguePerson: {
        ...prevState.dialoguePerson,
        image: files[0],
        previewImage: URL.createObjectURL(files[0])
      }
    }));
  };

  handleExistingDialoguePersonNameChange = event => {
    const { id, value } = event.target;

    const dialoguePersons = [...this.state.dialoguePersons];
    const index = dialoguePersons.findIndex(x => x._id === id);
    if (index !== -1) {
      dialoguePersons[index].name = value;
    }
    this.setState({ dialoguePersons: dialoguePersons });
  };

  handleExistingDialoguePersonImageChange = event => {
    const { files } = event.target;
    const { id } = event.target.dataset;

    const dialoguePersons = [...this.state.dialoguePersons];
    const index = dialoguePersons.findIndex(x => x._id === id);
    if (index !== -1) {
      dialoguePersons[index].previewImage = URL.createObjectURL(files[0]);
      dialoguePersons[index].image = files[0];
    }
    this.setState({ dialoguePersons: dialoguePersons });
  };

  render() {
    const { dialoguePersons, uploading } = this.state;
    const tableBody = [];

    if (dialoguePersons && dialoguePersons.length !== 0) {
      // add dialogue person to list
      dialoguePersons.forEach((dialoguePerson, index) => {
        tableBody.push(
          <Table.Row key={`dialogue-person-row-${index}`}>
            <Table.Cell>
              <Input
                id={dialoguePerson._id}
                value={dialoguePerson.name}
                onChange={this.handleExistingDialoguePersonNameChange}
              />
            </Table.Cell>
            <Table.Cell>
              <Image
                src={dialoguePerson.previewImage || dialoguePerson.image}
                circular
                size="small"
                style={{ marginBottom: '1em' }}
              />
              <InputFile
                input={{
                  id: 'dialogue-person-edit-image',
                  'data-id': dialoguePerson._id,
                  onChange: this.handleExistingDialoguePersonImageChange
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <Button primary onClick={() => this.editDialoguePerson(dialoguePerson)}>
                Редактировать
              </Button>
            </Table.Cell>
            <Table.Cell>
              <Button negative onClick={() => this.deleteDialoguePerson(dialoguePerson._id)}>
                Удалить
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <DocumentTitle title="Управление учителями">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container>
            <Header size="large" textAlign="center">
              Управление персонажами диалогов
            </Header>

            <Table celled style={{ marginBottom: '4em' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Имя</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Изображение</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Редактировать персонажа</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Удалить персонажа</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{tableBody}</Table.Body>
            </Table>

            <Container>
              <Header size="medium" textAlign="center">
                Создать нового персонажа
              </Header>
              <Form>
                <Form.Field>
                  <label>Имя персонажа</label>
                  <input placeholder="Введите имя" onChange={this.handleDialoguePersonNameChange} />
                </Form.Field>
                <Form.Field>
                  <label>Изображение персонажа</label>
                  {this.state.dialoguePerson.previewImage && (
                    <Image
                      src={this.state.dialoguePerson.previewImage || ''}
                      circular
                      size="medium"
                      style={{ marginBottom: '1em' }}
                    />
                  )}
                  <InputFile
                    input={{
                      id: 'dialogue-person-create-image',
                      onChange: this.handleDialoguePersonImageChange
                    }}
                  />
                </Form.Field>
                <Button type="submit" positive loading={uploading} onClick={this.createDialoguePerson}>
                  Создать персонажа
                </Button>
              </Form>
            </Container>
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  null,
  mapDispatchToProps
)(DialoguePersons);
