// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
// styles
// components
import Xarrow from 'react-xarrows';
// redux
import { getUser } from 'store/User';

const StyledCenteredContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-align: right;

    .text { 
        margin-bottom: 16px;
    }

    .link {
        margin-bottom: 24px;
        cursor: pointer;
        color: rgb(40, 130, 210);
    }

    .link:hover {
        text-decoration: underline;
    }

    .img {
        width: 320px;
    }

    ${media.lessThan('small')`
        font-size: 16px;
    `}

    ${media.between('small', 'medium')`
        font-size: 18px;
    `}

    ${media.between('medium', 'large')`
        font-size: 22px;
    `}

    ${media.greaterThan('large')`
        font-size: 24px;
    `}
`;

const StyledHintBox = styled.div`
    background-color: #ffffff;
    border: 1px solid black;
    border-radius: 8px;
    line-height: 1.2;
    font-weight: 400;

    ${media.lessThan('medium')`
        display: none;
    `}

    ${media.between('medium', 'large')`
        padding: 12px;
        font-size: 16px;
    `}

    ${media.greaterThan('large')`
        padding: 12px 16px;
        font-size: 18px;
    `}
`;

const StyledArrowWrapper = styled.div`
  ${media.lessThan('medium')`
        display: none;
    `}
`;

class Visualization extends Component {
  render() {
    const { t } = this.props;

    let lastLessonId = '';
    let link = '';

    if (this.props.user) {
      // search for course last lesson
      const userCourse = this.props.user.courses.find(x => x._id === this.props.courseId);

      if (userCourse) {
        lastLessonId = userCourse.lastLesson._id;
      } else {
        let lesson = this.props.lessons.find(x => !x.hidden);
        lastLessonId = lesson ? lesson._id : '';
      }

      link = `/dashboard/courses/${this.props.courseId}/lesson/${lastLessonId}`;
    }

    return (
      <DocumentTitle title={t('dashboard.course.visualization.header')}>
        <div>
          <StyledCenteredContainer>
            <div className="text">{t('dashboard.course.visualization.text') + this.props.user.firstName + '!'}</div>
            <div className="link" onClick={() => (window.location = link)}>
              {t('dashboard.course.visualization.continue')}
            </div>
          </StyledCenteredContainer>

          <StyledHintBox id="formulas-square" style={{ position: 'fixed', left: '144px', top: '120px' }}>
            {t('dashboard.course.visualization.hint.formulas')}
          </StyledHintBox>
          <StyledHintBox id="search-square" style={{ position: 'fixed', left: '144px', top: '208px' }}>
            {t('dashboard.course.visualization.hint.search')}
          </StyledHintBox>
          <StyledHintBox id="menu-square" style={{ position: 'fixed', right: '120px', top: '120px' }}>
            {t('dashboard.course.visualization.hint.menu')}
          </StyledHintBox>
          <StyledHintBox id="top-square" style={{ position: 'fixed', left: '144px', bottom: '120px' }}>
            {t('dashboard.course.visualization.hint.top')}
          </StyledHintBox>
          <StyledHintBox id="bottom-square" style={{ position: 'fixed', left: '144px', bottom: '32px' }}>
            {t('dashboard.course.visualization.hint.bottom')}
          </StyledHintBox>

          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="formulas-square" end="formulas-button" />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="search-square" end="search-button" />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="print-square" end="print-button" />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="menu-square" end="menu-button" />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="top-square" end="top-button" />
          </StyledArrowWrapper>
          <StyledArrowWrapper>
            <Xarrow color="black" strokeWidth={1} headSize={10} start="bottom-square" end="bottom-button" />
          </StyledArrowWrapper>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(getUser())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Visualization));
