// modules
import React from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
// assets
// styles
import "react-quill/dist/quill.snow.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
// redux

const StyledTextBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`;

const TextBlock = (props) => {
    return (
        <StyledTextBlockContainer className={props.highlighted ? "highlighted" : ""}>
            <div className="ql-editor">
                <MathjaxWrapper text={ReactHtmlParser(props.text)} />
            </div>
        </StyledTextBlockContainer>
    );
};

export default TextBlock;
