// modules
import React, { Component } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// components
// import ImageModal from "./ImageModal";

const StyledEditorContainer = styled.div`
    font-weight: 400;
`;

class Editor extends Component {
    // state = {
    //     showImageModal: false,
    // };

    // handleImageModal = (value) => {
    //     this.setState({ showImageModal: value });
    // };

    modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, false] }],
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code"],
                [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                [{ align: ["", "center", "right"] }],
                ["link", "image", "video"],
                ["clean"],
            ],
            handlers: {
                // image: (value) => {
                //     this.handleImageModal(true);
                // },
            },
        },
    };

    formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "code",
        "list",
        "bullet",
        "indent",
        "align",
        "link",
        "image",
        "video",
    ];

    render() {
        return (
            <StyledEditorContainer>
                {/* <ImageModal open={this.state.showImageModal} onClose={() => this.handleImageModal(false)} /> */}
                <ReactQuill
                    placeholder={"Можете начать писать здесь"}
                    modules={this.modules}
                    formats={this.formats}
                    value={this.props.html}
                    onChange={this.props.onChange}
                />
            </StyledEditorContainer>
        );
    }
}

export default Editor;
