// modules
import React, { Component } from "react";
import axios from "axios";
import ReactAudioPlayer from "react-audio-player";
import { nanoid } from "nanoid";
// assets
import { bold } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
import { blockOptions, mongoObjectId } from "assets/utils";
// styles
import { Button, Dropdown, Image, Message } from "semantic-ui-react";
// components
import IframeBlock from "./IframeBlock";
import IndefiniteQuestionBlock from "./IndefiniteQuestionBlock";
import QuestionBlock from "./QuestionBlock";
import TextBlock from "./TextBlock";
import InteractiveBlock from "./InteractiveBlock";
// redux

// add style
const addStyle = {
    backgroundColor: "#0dab76",
    color: "#fff",
    borderRadius: "0px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    marginTop: "1em",
    marginBottom: "1em",
};
// edit style
const editStyle = {
    backgroundColor: "#1099d3",
    color: "#fff",
    borderRadius: "0px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    marginBottom: "1em",
};
// delete style
const deleteStyle = {
    backgroundColor: "#cc515d",
    color: "#fff",
    borderRadius: "0px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    marginBottom: "1em",
};

class Block extends Component {
    // load state from props
    state = {
        block: this.props.block ? this.props.block : {},
        editing: false,
        deleting: false,
        uploading: false,
    };

    // optimizing render strategy
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.block !== nextState.block || this.props.block !== nextProps.block) {
            return true;
        }
        return false;
    }

    // load updated block from props to state
    componentDidUpdate(prevProps) {
        if (this.props.block !== this.state.block && this.props.block !== prevProps.block) {
            this.setState({
                block: this.props.block,
            });
        }
    }

    handleChange = (e, data) => {
        if (this.props.new) {
            // handling new block
            if (data.name === "blockType") {
                this.setState((prevState) => ({
                    block: {
                        ...prevState.block,
                        _id: mongoObjectId(),
                    },
                }));

                // create array with 5 strings for interactive block
                if (data.value === "interactive") {
                    const popupTextList = ["", "", "", "", ""];
                    this.setState((prevState) => ({
                        block: {
                            ...prevState.block,
                            popupTextList: popupTextList,
                        },
                    }));
                }
            }
        }

        let result = data.value;
        if (data.name === "testQuestion" || data.name === "highlighted") {
            result = data.checked;
        }
        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                [data.name]: result,
            },
        }));
    };

    handleTextChange = (value) => {
        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                text: value,
            },
        }));
    };

    handlePopupTextChange = (e, data) => {
        const index = parseInt(data.name);
        const value = data.value;

        // copy popup text array
        const popupTextList = [...this.state.block.popupTextList];
        // replace specific value in array
        popupTextList.splice(index, 1, value);

        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                popupTextList: popupTextList,
            },
        }));
    };

    handleEdit = () => {
        const r = window.confirm("Редактировать этот блок?");
        if (r) {
            this.setState({ editing: true });
            axios
                .post(adminRoutes.lessons.editBlock(this.props.lessonId, this.props.index), { block: this.state.block })
                .then(
                    () => {
                        this.setState({ editing: false });
                        this.props.fetchLesson(this.props.lessonId);
                    },
                    () => {
                        this.setState({ editing: false });
                    }
                );
        }
    };

    handleDelete = () => {
        const r = window.confirm("Удалить этот блок?");
        if (r) {
            this.setState({ deleting: true });
            axios.delete(adminRoutes.lessons.deleteBlock(this.props.lessonId, this.props.index)).then(
                () => {
                    this.setState({ deleting: false });
                    this.props.fetchLesson(this.props.lessonId);
                },
                () => {
                    this.setState({ deleting: false });
                }
            );
        }
    };

    handleNewQuestionBlock = (block, correctAnswer, options) => {
        if (!this.state.block.openQuestion) {
            if (correctAnswer.length === 0) {
                alert("Правильный ответ не выбран");
                return;
            }
            // if (this.state.block.multiple) {
            //     if (correctAnswer.length === 0) {
            //         alert("Правильный ответ не выбран");
            //         return;
            //     }
            // } else if (!this.state.block.multiple) {
            //     if (correctAnswer === "") {
            //         alert("Правильный ответ не выбран");
            //         return;
            //     }
            // }
            // stringify and set options
            block.options = JSON.stringify(options);
        }
        // stringify and set correct answer(-s)
        block.correctAnswer = JSON.stringify(correctAnswer);
        // set _id and block type
        block._id = this.state.block._id;
        block.blockType = this.state.block.blockType;

        this.setState(
            {
                block: block,
            },
            () => {
                this.props.handleNewBlock(this.state.block);
                this.setState({
                    block: {},
                });
            }
        );
    };

    handleEditQuestionBlock = (block, correctAnswer, options) => {
        if (!this.state.block.openQuestion) {
            if (this.state.block.multiple) {
                if (correctAnswer.length === 0) {
                    alert("Правильный ответ не выбран");
                    return;
                }
            } else if (!this.state.block.multiple) {
                if (correctAnswer === "") {
                    alert("Правильный ответ не выбран");
                    return;
                }
            }
            // stringify and set options
            block.options = JSON.stringify(options);
        }
        // stringify and set correct answer(-s)
        block.correctAnswer = JSON.stringify(correctAnswer);
        // set _id and block type
        block._id = this.state.block._id;
        block.blockType = this.state.block.blockType;
        // set audio
        block.audio = this.state.block.audio;
        // set dialogue person
        block.person = this.state.block.person;

        this.setState(
            {
                block: block,
            },
            () => {
                this.handleEdit();
            }
        );
    };

    handleImageSelect = async (e) => {
        // create a new form data
        const data = new FormData();
        // append file
        data.append("file", e.target.files[0]);
        // get file extension
        let extension = e.target.files[0].name.split(".");
        extension = extension[extension.length - 1];
        // append filename
        data.append("filename", `lesson-${this.props.lessonId}-block-${this.state.block._id}.${extension}`);
        // set uploading to true
        this.setState({ uploading: true });
        // axios call
        const response = await axios.post(adminRoutes.upload.uploadImage(), data).catch((error) => {
            this.setState({ uploading: false });
            return;
        });
        // set picture to state
        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                picture: response.data.message.link,
            },
            uploading: false,
        }));
    };

    handleAudioSelect = async (e) => {
        // create a new form data
        const data = new FormData();
        // append file
        data.append("file", e.target.files[0]);
        // get file extension
        let extension = e.target.files[0].name.split(".");
        extension = extension[extension.length - 1];
        if (extension !== "mp3" && extension !== "wav" && extension !== "ogg") {
            alert("Неподдерживаемый формат аудио");
            return;
        }
        // append filename
        data.append("filename", `lesson-${this.props.lessonId}-block-${this.state.block._id}.${extension}`);
        // set uploading to true
        this.setState({ uploading: true });
        // axios call
        const response = await axios.post(adminRoutes.upload.uploadAudio(), data).catch((error) => {
            this.setState({ uploading: false });
            return;
        });
        // set audio to state
        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                audio: response.data.message.link,
            },
            uploading: false,
        }));
    };

    handleAudioDelete = async (e) => {
        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                audio: "",
            },
        }));
    };

    handleFileSelect = async (e) => {
        // create a new form data
        const data = new FormData();
        // append file
        data.append("file", e.target.files[0]);
        // get file extension
        let extension = e.target.files[0].name.split(".");
        extension = extension[extension.length - 1];
        // append filename
        const fileId = this.props.new ? nanoid(12) : this.state.block._id;
        data.append("filename", `lesson-${this.props.lessonId}-file-${fileId}.${extension}`);
        // set uploading to true
        this.setState({ uploading: true });
        // axios call
        const response = await axios.post(adminRoutes.upload.uploadFile(), data).catch((error) => {
            this.setState({ uploading: false });
            return;
        });
        // set audio to state
        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                attachedFile: response.data.message.link,
            },
            uploading: false,
        }));
    };

    handleFileDelete = async (e) => {
        this.setState((prevState) => ({
            block: {
                ...prevState.block,
                attachedFile: "",
            },
        }));
    };

    render() {
        let block;
        if (this.state.block.blockType) {
            switch (this.state.block.blockType) {
                case "question":
                    block = (
                        <QuestionBlock
                            block={this.state.block}
                            editing={this.state.editing}
                            new={this.props.new}
                            handleNewQuestionBlock={this.handleNewQuestionBlock}
                            handleEditQuestionBlock={this.handleEditQuestionBlock}
                        />
                    );
                    break;
                case "indefiniteQuestion":
                    block = (
                        <IndefiniteQuestionBlock
                            block={this.state.block}
                            onTextChange={this.handleTextChange}
                            onChange={this.handleChange}
                        />
                    );
                    break;
                case "text":
                case "fileUpload":
                    block = (
                        <TextBlock
                            block={this.state.block}
                            onChange={this.handleChange}
                            onTextChange={this.handleTextChange}
                        />
                    );
                    break;
                case "iframe":
                    block = <IframeBlock block={this.state.block} onChange={this.handleChange} />;
                    break;
                case "interactive":
                    block = (
                        <InteractiveBlock
                            block={this.state.block}
                            onChange={this.handleChange}
                            onTextChange={this.handleTextChange}
                            onPopupTextChange={this.handlePopupTextChange}
                        />
                    );
                    break;
                default:
                    block = null;
            }
        }

        let buttons = [];
        if (this.props.new) {
            if (this.state.block) {
                if (this.state.block.blockType !== "question") {
                    buttons.push(
                        <Button
                            key={"admin-lesson-" + this.state.block._id + "-add-button"}
                            type="button"
                            onClick={() => {
                                if (this.state.block.blockType) {
                                    this.props.handleNewBlock(this.state.block);
                                    this.setState({ block: {} });
                                } else {
                                    alert("Тип блока не был выбран");
                                }
                            }}
                            style={addStyle}
                        >
                            Добавить блок
                        </Button>
                    );
                }
            }
        } else {
            if (this.state.block.blockType !== "question") {
                buttons.push(
                    <Button
                        key={"admin-lesson-" + this.state.block._id + "-edit-button"}
                        loading={this.state.editing}
                        onClick={this.handleEdit}
                        style={editStyle}
                    >
                        Редактировать блок
                    </Button>
                );
            }
            buttons.push(
                <Button
                    key={"admin-lesson-" + this.state.block._id + "-delete-button"}
                    loading={this.state.deleting}
                    onClick={this.handleDelete}
                    style={deleteStyle}
                >
                    Удалить блок
                </Button>
            );
        }

        return (
            <div>
                <div style={{ marginBottom: "1em" }}>
                    {bold("Тип блока:")}
                    <Dropdown
                        fluid
                        selection
                        options={blockOptions}
                        name="blockType"
                        value={this.state.block.blockType}
                        onChange={this.handleChange}
                    />
                </div>
                <div style={{ marginBottom: "1em" }}>{block}</div>

                <div style={{ marginBottom: "2em", padding: "8px", border: "1px solid #000" }}>
                    {bold("Аудиофайл:")}
                    {this.state.block.audio && (
                        <div style={{ marginBottom: "1em" }}>
                            <ReactAudioPlayer src={this.state.block.audio} controls style={{ marginBottom: "1em" }} />
                            <br />
                            <Button negative onClick={this.handleAudioDelete}>
                                Удалить аудио
                            </Button>
                        </div>
                    )}
                    <input type="file" accept=".mp3,.ogg,.wav" onChange={this.handleAudioSelect} />
                </div>

                <div style={{ marginBottom: "2em", padding: "8px", border: "1px solid #000" }}>
                    {bold("Файл:")}
                    {this.state.block.attachedFile && (
                        <div style={{ marginBottom: "1em" }}>
                            {/* <ReactAudioPlayer src={this.state.block.attachedFile} controls style={{ marginBottom: "1em" }} /> */}
                            {/* <br /> */}
                            <p>Файл: {this.state.block.attachedFile}</p>
                            <Button negative onClick={this.handleFileDelete}>
                                Удалить приклепленный файл
                            </Button>
                        </div>
                    )}
                    <input type="file" onChange={this.handleFileSelect} />
                </div>

                <Message
                    info
                    icon="hand pointer outline"
                    header="Обращение к пользователю"
                    content="Если хочешь обратиться к пользователю, напиши $$placeholder$$, и я заменю это его/её никнеймом"
                />
                <div style={{ marginBottom: "1em" }}>
                    {bold("Выберите собеседника:")}
                    <Dropdown
                        fluid
                        selection
                        options={this.props.dialoguePersons.map(item => ({
                            text: item.name,
                            value: item.image
                        }))}
                        name="person"
                        value={this.state.block.person}
                        onChange={this.handleChange}
                        style={{ marginBottom: "1em " }}
                    />
                    <Image circular size="small" src={this.state.block.person ? this.state.block.person : ""} />
                </div>
                {buttons}
            </div>
        );
    }
}

export default Block;
