// modules
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DocumentTitle from 'react-document-title';
// components
import { Embed } from 'semantic-ui-react';
import LoginModal from './LoginModal';
// import RegisterModal from './RegisterModal';
// redux
// utils
// assets
import logo from 'assets/images/landing-logo.png';
import rightArrow from 'assets/images/landing-right-arrow.svg';
import chat from 'assets/images/landing-chat.svg';
// styles
import './index.css';

const Landing = () => {
  const buttonHref =
    'https://docs.google.com/forms/d/e/1FAIpQLScN-SBpuovsiQV1-qXGtXRnSr6q4Qk18nzG-IkUOh7vI5V_vw/viewform';
  const chatHref = 'https://t.me/workternship';

  const [isLoginModalShown, toggleLoginModal] = useState(false);
  const isAuthenticated = useSelector(state => !!state.user.user.email);
  const role = useSelector(state => state.user.user.scope);

  return (
    <DocumentTitle title="Workternship">
      <Fragment>
        <LoginModal open={isLoginModalShown} onClose={() => toggleLoginModal(false)} />
        {/* <RegisterModal open /> */}
        <div className="landing-container">
          <div className="landing-header">
            <img src={logo} alt="" />
            {/* <h1>Начни стажировку на реальной профессии, не выходя из дома</h1> */}
            <h1>Обучающие курсы</h1>
          </div>
          {/* <div className="landing-video">
            <Embed id='' source='youtube' />
          </div> */}
          {/* <div className="landing-points">
            <div>
              <h3>Сделайте "Тест-драйв" карьеры в симуляторе</h3>
              <p>Выбор профессии должен быть осознанным. Поработайте на виртуальной стажировке, чтобы понять, подходит ли вам профессия или нет.</p>
            </div>
            <div>
              <h3>Ваше преимущество перед другими соискателями</h3>
              <p>В симуляторе вы столкнетесь с реальными задачами выбранной профессии. Вы сможете продемонстрировать свои навыки работодателю, что значительно повысит ваши шансы на трудоустройство.</p>
            </div>
            <div>
              <h3>Получите опыт работы на реальной профессии</h3>
              <p>Симулятор позволит вам получить базовые навыки и понимание предметной области определенной профессии.</p>
            </div>
          </div>
          <div className="landing-description">
            <p>
              Не нужно иметь хорошее резюме, опыт работы или рекоммендации.
              <br />
              Важно просто показать как вы умеете работать.
            </p>
            <p>
              Отбор на виртуальную стажировку не предусмотрен. <br />
              Workternship дает шанс проявить себя каждому.
            </p>
            <p>
              У нас готово более 10 симуляторов стажировок в различных направлениях.
            </p>
          </div> */}
          <div className="landing-buttons">
            {/* <a className="signButton" href={buttonHref} target="_blank" rel="noopener noreferrer">
              Записаться на бета-тест <img className="rightArrow" src={rightArrow} alt="" />
            </a> */}
            {isAuthenticated ? (
              <Link className="signButton" to={role === "user" ? "/dashboard" : "/admin"}>Перейти к курсам</Link>
            ) : (
            <button className="signButton" onClick={() => toggleLoginModal(true)}>
              Вход для зарегистрированных
            </button>
            )}
          </div>
          <div className="landing-footer">&copy; workternship 2021</div>

          <a className="landing-chat" href={chatHref} target="_blank" rel="noopener noreferrer">
            <img src={chat} alt="" />
          </a>
        </div>
      </Fragment>
    </DocumentTitle>
  );
};

export default Landing;
