// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
import logo from 'assets/images/landing-logo.png';
import { MdInfoOutline, MdSearch, MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { scrollToTop, scrollToBottom } from '../../../utils/scroll';
// styles
// components
import FormulasModal from './FormulasModal';
import SearchModal from './SearchModal';
import ImageProgressbar from './ImageProgressbar';
import StyledNavButton from 'components/StyledNavButton';
// import VerticalProgressbar from "./VerticalProgressbar";
// redux
import { toggleFormulasModalVisibility, toggleSearchModalVisibility } from '../../../store/UI';

const StyledNavContainer = styled.div`
  @media print {
    display: none;
  }

  ${media.lessThan('600px')`
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 50;
        width: 100%;
        height: 64px;
        border-top: 2px solid #4682e0;
        background-color: #fcfcfc;
    `}

  ${media.lessThan('400px')`
        height: 48px;
    `}
`;

const StyledFormulasButton = styled(StyledNavButton)`
    ${media.lessThan('400px')`
        left: 56px;
        bottom: 6px;
    `}

    ${media.between('400px', '600px')`
        left: 80px;
        bottom: 6px;
    `}

    ${media.between('600px', '800px')`
        left: 16px;
        top: 88px;
    `}
        
    ${media.greaterThan('800px')`
        left: 24px;
        top: 112px;
    `}
`;

const StyledSearchButton = styled(StyledNavButton)`
    ${media.lessThan('400px')`
        left: 104px;
        bottom: 6px;
    `}

    ${media.between('400px', '600px')`
        left: 148px;
        bottom: 6px;
    `}

    ${media.between('600px', '800px')`
        left: 16px;
        top: 160px;
    `}

    ${media.greaterThan('800px')`
        left: 24px;
        top: 200px;
    `}
`;

const StyledTopButton = styled(StyledNavButton)`
    ${media.lessThan('600px')`
        display: none !important;
    `}

    ${media.between('600px', '800px')`
        left: 16px;
        bottom: 88px;
    `}
        
    ${media.greaterThan('800px')`
        left: 24px;
        bottom: 112px;
    `}
`;

const StyledBottomButton = styled(StyledNavButton)`
    ${media.lessThan('600px')`
        display: none !important;
    `}

    ${media.between('600px', '800px')`
        left: 16px;
        bottom: 16px;
    `}
        
    ${media.greaterThan('800px')`
        left: 24px;
        bottom: 24px;
    `}
`;

class Navigation extends Component {
  handlePrint = () => {
    window.print();
  };

  render() {
    const { currentBlock, numBlocks } = this.props;

    // calculate covered lesson percentage
    let percentage = ((currentBlock + 1) / numBlocks) * 100;
    percentage = Math.floor(percentage);

    return (
      <StyledNavContainer>
        <FormulasModal
          text={this.props.formulas}
          open={this.props.isFormulasModalVisible}
          onClose={() => this.props.toggleFormulasModalVisibility(false)}
        />
        <SearchModal
          courseId={this.props.courseId}
          lastLesson={this.props.lastLesson}
          lessonKeywords={this.props.lessonKeywords}
          open={this.props.isSearchModalVisible}
          onClose={() => this.props.toggleSearchModalVisibility(false)}
        />
        <ImageProgressbar demo={this.props.demo} percentage={percentage} imgSrc={logo} />
        {/* <VerticalProgressbar currentBlock={currentBlock} numBlocks={numBlocks} /> */}

        <StyledFormulasButton id="formulas-button" onClick={() => this.props.toggleFormulasModalVisibility(true)}>
          <MdInfoOutline />
        </StyledFormulasButton>

        <StyledSearchButton id="search-button" onClick={() => this.props.toggleSearchModalVisibility(true)}>
          <MdSearch />
        </StyledSearchButton>

        <StyledTopButton id="top-button" onClick={scrollToTop}>
          <MdKeyboardArrowUp />
        </StyledTopButton>

        <StyledBottomButton id="bottom-button" onClick={scrollToBottom}>
          <MdKeyboardArrowDown />
        </StyledBottomButton>

        {/* <Button
          id="timer-button"
          circular
          color="blue"
          className="dashboard-timer-button"
          onClick={() => this.props.toggleTimerVisibility(!this.props.isTimerVisible)}
        >
          {this.props.isTimerVisible ? <MdAlarm /> : <MdAlarmOff />}
        </Button> */}
      </StyledNavContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFormulasModalVisible: state.ui.isFormulasModalVisible,
  isSearchModalVisible: state.ui.isSearchModalVisible
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleFormulasModalVisibility: value => dispatch(toggleFormulasModalVisibility(value)),
  toggleSearchModalVisibility: value => dispatch(toggleSearchModalVisibility(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Navigation));
