// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import axios from "axios";
// assets
import { dashboardRoutes, practicesRoutes } from "assets/routes";
import { compareById } from "assets/utils";
// styles
import { Loader } from "semantic-ui-react";
// components
import Lesson from "./Lesson";
import Materials from "./Materials";
import Navigation from "../Navigation";
import Practice from "./Practice";
import PracticesList from "./Practice/PracticesList";
import Visualization from "./Visualization";
// redux
import { setSidebarMenuItems } from "store/UI";
import { getUser, logout } from "store/User";

class Course extends Component {
    state = {
        loading: false,
        courseId: this.props.match.params.courseId,
        course: {},
        introLessonName: "",
        lessons: [],
        practices: [],
        progress: 0,
        innerWidth: window.innerWidth,
    };

    componentDidMount() {
        this.fetchCourse(this.state.courseId);
    }

    handleProgress = (currentBlock, numBlocks) => {
        this.setState({ currentBlock: currentBlock, numBlocks: numBlocks });
    };

    fetchCourse = async (courseId) => {
        this.setState({ loading: true });

        // fetch course information
        let course = await axios.get(dashboardRoutes.fetchCourseInfo(courseId)).catch((error) => {
            console.error("Error fetching course information" + error);
            if (error.response.status === 401) {
                this.props.logout();
            }
        });

        // fetch intro lesson name
        let introLessonName = await axios.get(dashboardRoutes.fetchIntroLessonName(courseId)).catch((error) => {
            console.error("Error fetching intro lesson name" + error);
            if (error.response.status === 401) {
                this.props.logout();
            }
        });

        // fetch lessons
        let lessons = await axios.get(dashboardRoutes.fetchLessons(courseId)).catch((error) => {
            console.error("Error fetching lessons " + error);
            if (error.response.status === 401) {
                this.props.logout();
            }
        });

        // fetch practices
        let practices = await axios.get(practicesRoutes.getPractices(courseId)).catch((error) => {
            console.error("Error fetching practices" + error);
            if (error.response.status === 401) {
                this.props.logout();
            }
        });

        if (course) {
            course = course.data.message;
        }
        if (introLessonName) {
            introLessonName = introLessonName.data.message;
        }
        if (lessons) {
            lessons = lessons.data.message;
        }
        if (practices) {
            practices = practices.data.message;
        }

        // fetch user
        await this.props.getUser();

        // create list
        let items = [];

        // find user course
        const userCourse = this.props.user.courses.find((x) => x._id === this.state.courseId);
        // find last lesson
        const lastLesson = userCourse ? userCourse.lastLesson : { _id: "", id: 1 };

        // add items
        items = lessons.map((lesson, index) => ({
            available: lesson.id <= lastLesson.id,
            id: lesson.id,
            section: lesson.section,
            name: lesson.name,
            displayName: lesson.displayName,
            to: `/dashboard/courses/${this.state.courseId}/lesson/${lesson._id}`,
        }));

        // sort items by sections
        items = items.sort(compareById);
        // set items
        this.props.setSidebarMenuItems(items);

        // set data to state
        this.setState({
            loading: false,
            course: course,
            introLessonName: introLessonName,
            lessons: lessons,
            practices: practices,
            lastLesson: lastLesson,
        });
    };

    render() {
        return (
            <DocumentTitle title={"dashboard.course.visualization.header"}>
                <div>
                    <Navigation
                        currentBlock={this.state.currentBlock}
                        numBlocks={this.state.numBlocks}
                        formulas={this.state.course.formulas}
                        courseId={this.state.courseId}
                        lastLesson={this.state.lastLesson}
                        lessonKeywords={this.state.lessons.map((x) => ({
                            _id: x._id,
                            id: x.id,
                            title: `${x.section}. ${x.name}`,
                            keywords: x.keywords,
                        }))}
                    />
                    {this.state.loading ? (
                        <Loader active size="large" />
                    ) : (
                        <Switch>
                            <Route
                                exact
                                path="/dashboard/courses/:courseId"
                                render={(props) => (
                                    <Visualization courseId={this.state.courseId} lessons={this.state.lessons} />
                                )}
                            />
                            <Route
                                exact
                                path="/dashboard/courses/:courseId/materials"
                                render={(props) => <Materials />}
                            />
                            <Route
                                path="/dashboard/courses/:courseId/practices/all"
                                render={(props) => <PracticesList />}
                            />
                            <Route
                                path="/dashboard/courses/:courseId/practice/:practiceId"
                                render={(props) => (
                                    <Practice
                                        handleProgress={this.handleProgress}
                                        innerWidth={this.state.innerWidth}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path="/dashboard/courses/:courseId/lesson/:lessonId"
                                render={(props) => (
                                    <Lesson
                                        handleProgress={this.handleProgress}
                                        innerWidth={this.state.innerWidth}
                                        {...props}
                                    />
                                )}
                            />
                            <Redirect to="/dashboard" />
                        </Switch>
                    )}
                </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    getUser: () => dispatch(getUser()),
    logout: () => dispatch(logout()),
    setSidebarMenuItems: (items) => dispatch(setSidebarMenuItems(items)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(Course))
);
