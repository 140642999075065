// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import axios from "axios";
import { scroller } from "react-scroll";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import media from "styled-media-query";
// assets
import notFound from "assets/images/404.png";
import stop from "assets/images/stop.svg";
import nextArrow from "assets/images/next-arrow.png";
import { dashboardRoutes } from "assets/routes";
// styles
import { Image, Loader } from "semantic-ui-react";
// components
import StarsComponent from "./StarsComponent";
import LessonStatsModal from "./LessonStatsModal";
import IframeBlock from "./IframeBlock";
import IndefiniteQuestion from "./IndefiniteQuestion";
import Question from "./Question";
import TextBlock from "./TextBlock";
import InteractiveBlock from "components/InteractiveBlock";
import FileUploadBlock from "./FileUploadBlock";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux
import { getUser, logout } from "store/User";

const StyledLessonContainer = styled.div`
    min-height: 70vh;
    font-weight: 400;
`;

const StyledErrorContainer = styled.div`
    text-align: center;

    ${media.lessThan("400px")`
        font-size: 14px;

        .image {
            width: 100%;
        }
    `}

    ${media.between("400px", "600px")`
        font-size: 16px;
        
        .image {
            width: 80%;
        }
    `}

    ${media.between("600px", "800px")`
        font-size: 18px;

        .image {
            width: 80%;
        }
    `}

    ${media.greaterThan("800px")`
        font-size: 22px;

        .image {
            width: 60%;
        }
    `}
`;

const StyledLessonNameContainer = styled.div`
    margin-bottom: 2em;
    text-align: center;
    line-height: 1.4;
    font-weight: 600;

    ${media.lessThan("400px")`
        font-size: 18px;

        .section-name {
            margin-right: 0.4em;
        }
    `}

    ${media.between("400px", "600px")`
        font-size: 22px;

        .section-name {
            margin-right: 0.6em;
        }
    `}

    ${media.between("600px", "800px")`
        font-size: 24px;

        .section-name {
            margin-right: 1em;
        }
    `}

    ${media.greaterThan("800px")`
        font-size: 36px;
        
        .section-name {
            margin-right: 1em;
        }
    `}
`;

const StyledBlockWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .highlighted {
        background-color: rgb(218, 255, 218) !important;
        border: 3px solid rgb(45, 153, 86);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.75);
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .ql-video {
        display: block;
        margin: 0 auto;
    }

    .ql-editor {
        padding-left: 0;
        padding-right: 0;
    }

    ${media.lessThan("400px")`
        margin-bottom: 32px;
        padding: 0px;
        font-size: 12px;

        h3 {
            font-size: 16px;
        }

        .highlighted {
            padding: 16px;
        }

        .ql-video {
            width: 240px;
            height: 135px;
        }
    `}

    ${media.between("400px", "600px")`
        margin-bottom: 40px;
        padding: 0px 24px;
        font-size: 14px;

        h3 {
            font-size: 18px;
        }

        .highlighted {
            padding: 16px;
        }

        .ql-video {
            width: 320px;
            height: 180px;
        }
    `}

    ${media.between("600px", "800px")`
        margin-bottom: 64px;
        padding: 0px 40px;
        font-size: 16px;

        h3 {
            font-size: 18px;
        }

        .highlighted {
            padding: 24px;
        }

        .ql-video {
            width: 400px;
            height: 225px;
        }
    `}

    ${media.between("800px", "1100px")`
        margin-bottom: 72px;
        padding: 0px 64px;
        font-size: 18px;

        h3 {
            font-size: 20px;
        }

        .highlighted {
            padding: 32px;
        }

        .ql-video {
            width: 640px;
            height: 360px;
        }
    `}

    ${media.greaterThan("1100px")`
        margin-bottom: 80px;
        padding: 0px 96px;
        font-size: 20px;

        h3 {
            font-size: 22px;
        }

        .highlighted {
            padding: 40px;
        }

        .ql-video {
            width: 800px;
            height: 450px;
        }
    `}
`;

const StyledPersonContainer = styled.div`
    ${media.lessThan("400px")`
        margin-right: 8px;
    `}

    ${media.between("400px", "600px")`
        margin-right: 12px;
    `}

    ${media.between("600px", "800px")`
        margin-right: 16px;
    `}

    ${media.greaterThan("800px")`
        margin-right: 24px;
    `}
`;

const StyledPersonImage = styled.img`
    border-radius: 50%;

    ${media.lessThan("400px")`
        width: 40px;
        height: 40px;
    `}

    ${media.between("400px", "600px")`
        width: 48px;
        height: 48px;
    `}

    ${media.between("600px", "800px")`
        width: 54px;
        height: 54px;
    `}

    ${media.greaterThan("800px")`
        width: 72px;
        height: 72px;
    `}
`;

const StyledBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const StyledNextBlockButtonContainer = styled.div`
    button {
        border: none;
        border-radius: 8px;
        outline: none;
        background-color: #419488;
        color: white;
        cursor: pointer;
        font-weight: 600;
    }

    button:hover {
        background-color: #327c71;
    }

    ${media.lessThan("400px")`
        margin-left: 32px;

        button {
            padding: 10px 20px;
            font-size: 14px;
        }
    `}

    ${media.between("400px", "600px")`
        margin-left: 48px;

        button {
            padding: 12px 24px;
            font-size: 16px;
        }
    `}

    ${media.between("600px", "800px")`
        margin-left: 72px;

        button {
            padding: 12px 24px;
            font-size: 18px;
        }
    `}

    ${media.greaterThan("800px")`
        margin-left: 120px;
        
        button {
            padding: 14px 28px;
            font-size: 22px;
        }
    `}
`;

const StyledExtraMaterialsContainer = styled.div`
    text-align: center;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);

    .header {
        font-weight: 600;
    }

    ${media.lessThan("400px")`
        margin: 0px 0px 24px 0px;
        padding: 16px;
        font-size: 14px;

        .header {
            font-size: 16px;
            margin-bottom: 16px;
        }
    `}

    ${media.between("400px", "600px")`
        margin: 0px 40px 32px 40px;
        padding: 24px;
        font-size: 16px;

        .header {
            font-size: 18px;
            margin-bottom: 24px;
        }
    `}

    ${media.between("600px", "800px")`
        margin: 0px 72px 40px 72px;
        padding: 24px;
        font-size: 16px;

        .header {
            font-size: 18px;
            margin-bottom: 32px;
        }
    `}

    ${media.between("800px", "1100px")`
        margin: 0px 96px 40px 96px;
        padding: 24px;
        font-size: 18px;

        .header {
            font-size: 20px;
            margin-bottom: 32px;
        }
    `}

    ${media.greaterThan("1100px")`
        margin: 0px 120px 48px 120px;
        padding: 32px;
        font-size: 18px;

        .header {
            font-size: 20px;
            margin-bottom: 32px;
        }
    `}
`;

const StyledShortStatsContainer = styled.div`
    color: black;
    text-align: center;
    font-weight: 600;

    ${media.lessThan("400px")`
        margin-bottom: 24px;
        font-size: 16px;
    `}

    ${media.between("400px", "600px")`
        margin-bottom: 32px;
        font-size: 18px;
    `}

    ${media.between("600px", "800px")`
        margin-bottom: 40px;
        font-size: 18px;
    `}

    ${media.between("800px", "1100px")`
        margin-bottom: 40px;
        font-size: 20px;
    `}

    ${media.greaterThan("1100px")`
        margin-bottom: 48px;
        font-size: 22px;
    `}
`;

const StyledSendStatsButtonContainer = styled.div`
    display: flex;
    justify-content: center;

    button {
        cursor: pointer;
        border: none;
        outline: none;
        background-color: #419488;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.5);
        color: white;
        font-weight: 600;
    }

    ${media.lessThan("400px")`
        button {
            padding: 8px;
            font-size: 14px;
            border-radius: 8px;
        }
    `}

    ${media.between("400px", "600px")`
        button {
            padding: 8px 12px;
            font-size: 16px;
            border-radius: 8px;
        }
    `}

    ${media.between("600px", "800px")`
        button {
            padding: 12px 16px;
            font-size: 18px;
            border-radius: 12px;
        }
    `}

    ${media.greaterThan("800px")`
        button {
            padding: 16px 24px;
            font-size: 22px;
            border-radius: 16px;
        }
    `}
`;

const StyledNextLessonLinkContainer = styled.div`
    display: flex;
    justify-content: center;
    
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .container:hover {
        cursor: pointer;
    }

    .container:hover > .link {
        color: slategrey;
        border-bottom: 1px solid slategrey;
    }

    .link {
        color: black;
        line-height: 1.2;
        text-decoration: none;
        text-transform: lowercase;
    }

    .inactive { 
        cursor: default;
    }

    .img {
        margin-left: 48px;
        width: 64px;
        height: 64px;
    }

    ${media.lessThan("400px")`
        margin-top: 2em;

        .link {
            font-size: 20px;
        }

        .img {
            margin-left: 16px;
            width: 32px;
            height: 32px;
        }
    `}

    ${media.between("400px", "600px")`
        margin-top: 2em;

        .link {
            font-size: 22px;
        }

        .img {
            margin-left: 24px;
            width: 40px;
            height: 40px;
        }
    `}

    ${media.between("600px", "800px")`
        margin-top: 4em;

        .link {
            font-size: 24px;
        }

        .img {
            margin-left: 32px;
            width: 48px;
            height: 48px;
        }
    `}

    ${media.greaterThan("800px")`
        margin-top: 4em;

        .link {
            font-size: 32px;
        }

        .img {
            margin-left: 40px;
            width: 64px;
            height: 64px;
        }
    `}
`;

// set base URL for redirects
const baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:8080" : "https://workternship.com";

class Lesson extends Component {
    state = {
        answering: false,
        loading: false,
        forbidden: false,
        notFound: false,
        courseId: this.props.match.params.courseId,
        lessonId: this.props.match.params.lessonId,
        isLessonStatsModalVisible: false,
    };

    componentDidMount() {
        // fetch lesson
        this.fetchLesson(this.state.courseId, this.state.lessonId);
    }

    componentDidUpdate(prevProps) {
        // fetch lesson on router location changes
        let courseId = this.props.match.params.courseId;
        let lessonId = this.props.match.params.lessonId;

        if (prevProps.match.params.lessonId !== lessonId) {
            // update state
            this.setState({ lessonId: lessonId });

            // scroll to top
            window.scrollTo(0, 0);
            // fetch lesson
            this.fetchLesson(courseId, lessonId);
        }
    }

    fetchLesson = async (courseId, lessonId) => {
        this.setState({ loading: true, forbidden: false, notFound: false });

        let response = await axios.get(dashboardRoutes.fetchLesson(courseId, lessonId)).catch((error) => {
            switch (error.response.status) {
                case 401:
                    this.props.logout();
                    setTimeout(() => {
                        window.location = "/";
                    }, 500);
                    break;
                case 403:
                    this.setState({ loading: false, forbidden: true });

                    break;
                case 404:
                    this.setState({ loading: false, notFound: true });

                    break;
                default:
                    this.props.logout();
                    return;
            }
        });
        if (response) {
            response = response.data.message;
            // pick out lesson and user lesson
            const lesson = response.lesson;
            const userLesson = response.userLesson;
            // populate lesson with user answers
            lesson.completed = userLesson.completed;
            lesson.correct = userLesson.correct;
            lesson.currentBlock = userLesson.currentBlock;
            // iterate over blocks to fill answers
            for (let i = 0; i < userLesson.answers.length; i++) {
                let index = lesson.blocks.findIndex((x) => x._id === userLesson.answers[i]._id);
                lesson.blocks[index].answer = userLesson.answers[i].answer;
                lesson.blocks[index].correct = userLesson.answers[i].correct;
                lesson.blocks[index].time = userLesson.answers[i].time;
            }
            // iterate over blocks to fill user files
            if (userLesson.userFiles) {
                for (let userFile of userLesson.userFiles) {
                    let index = lesson.blocks.findIndex((x) => x._id === userFile._id);
                    lesson.blocks[index].name = userFile.name;
                    lesson.blocks[index].path = userFile.path;
                }
            }

            // set lesson
            this.setState({ loading: false, answering: false, lesson: lesson }, () => {
                // handle progress
                this.props.handleProgress(this.state.lesson.currentBlock, this.state.lesson.length);
            });
        }
    };

    toggleLessonStatsModalVisibility = (value) => {
        this.setState({ isLessonStatsModalVisible: value });
    };

    handleChange = (e, data) => {
        this.setState({ [data.name]: data.value });
    };

    handleAnswering = (value) => {
        this.setState({ answering: value });
    };

    handleCorrect = () => {
        this.setState({
            lesson: {
                ...this.state.lesson,
                correct: this.state.lesson.correct + 1,
            },
        });
    };

    handleMarkTime = (index, time) => {
        if (this.state.lesson) {
            const blocks = [...this.state.lesson.blocks];
            blocks[index]["time"] = time;
            this.setState((prevState) => ({
                lesson: {
                    ...prevState.lesson,
                    blocks: blocks,
                },
            }));
        }
    };

    handleUpdateLastBlock = () => {
        this.setState(
            {
                lesson: {
                    ...this.state.lesson,
                    currentBlock: this.state.lesson.currentBlock + 1,
                },
            },
            () => {
                // scroll
                scroller.scrollTo("last-block", {
                    duration: 750,
                    delay: 100,
                    smooth: "easeInOutCubic",
                    offset: -24,
                });
                // handle progress
                this.props.handleProgress(this.state.lesson.currentBlock, this.state.lesson.length);
                // payload
                const payload = {
                    completed: this.state.lesson.completed,
                    currentBlock: this.state.lesson.currentBlock,
                };
                // axios call
                if (this.props.demo) {
                    axios.post(dashboardRoutes.updateIntroBlock(this.state.courseId), payload);
                } else {
                    axios.post(dashboardRoutes.updateBlock(this.state.courseId, this.state.lessonId), payload);
                }
            }
        );
    };

    handleCompleted = () => {
        this.setState({ lesson: { ...this.state.lesson, completed: true } }, () => {
            // scroll
            scroller.scrollTo("dashboard-lesson-stars", {
                duration: 750,
                delay: 100,
                smooth: "easeInOutCubic",
                offset: -40,
            });
            // payload
            const payload = {
                completed: this.state.lesson.completed,
                currentBlock: this.state.lesson.currentBlock,
                results: this.state.lesson.correct,
            };
            // axios call
            if (this.props.demo) {
                axios.post(dashboardRoutes.updateIntroBlock(this.state.courseId), payload);
                // get user
                this.props.getUser();
            } else {
                axios.post(dashboardRoutes.updateBlock(this.state.courseId, this.state.lessonId), payload);
            }
        });
    };

    handleUpdateLastLesson = async () => {
        if (this.state.lesson && this.state.lesson.nextLesson._id) {
            let course = this.props.courses.find((x) => x._id === this.state.courseId);

            // payload
            const payload = { lastLesson: this.state.lesson.nextLesson };

            if (this.state.lesson._id === course.lastLesson._id) {
                // update last lesson at server
                // axios call
                await axios.post(dashboardRoutes.updateLesson(this.state.courseId), payload).catch((error) => {
                    alert(`Ошибка: ${error.toString()}`);
                });
                // get user
                this.props.getUser();
                setTimeout(() => {
                    // redirect
                    window.location = `/dashboard/courses/${this.state.courseId}/lesson/${this.state.lesson.nextLesson._id}`;
                }, 1000);
            } else {
                // just redirect to next lesson
                setTimeout(() => {
                    window.location = `/dashboard/courses/${this.state.courseId}/lesson/${this.state.lesson.nextLesson._id}`;
                }, 1000);
            }
        }
    };

    handleNextClick = () => {
        if (this.state.lesson) {
            const { currentBlock, length } = this.state.lesson;
            if (currentBlock === length - 1) {
                this.handleCompleted();
            } else {
                this.handleUpdateLastBlock();
            }
        }
    };

    render() {
        const { t } = this.props;

        let forbiddenContainer;
        let notFoundContainer;
        let lessonSectionName = "";
        let lessonName = "Урок";
        let lesson = [];
        let questionNumber = 1;

        if (this.state.forbidden) {
            forbiddenContainer = (
                <StyledErrorContainer>
                    <p>{t("dashboard.course.lesson.forbidden.header")}</p>
                    <p>{t("dashboard.course.lesson.forbidden.text")}</p>
                    <Image className="dashboard-lesson-forbidden-image" centered src={stop} alt="" />
                </StyledErrorContainer>
            );
        }

        if (this.state.notFound) {
            notFoundContainer = (
                <StyledErrorContainer>
                    <p>{t("dashboard.course.lesson.notfound.text")}</p>
                    <Image className="dashboard-lesson-not-found-image" centered src={notFound} alt="" />
                </StyledErrorContainer>
            );
        }

        if (this.state.lesson && !this.state.forbidden && !this.state.notFound) {
            // set lesson name
            lessonName = this.state.lesson.name;
            // set lesson section name
            if (this.state.lesson.section) {
                lessonSectionName = this.state.lesson.section + ". ";
            }

            // add lesson name container
            lesson.push(
                <StyledLessonNameContainer key="dashboard-lesson-name">
                    <span className="section-name">{lessonSectionName}</span>
                    <span>{lessonName}</span>
                </StyledLessonNameContainer>
            );

            if (this.state.lesson.blocks && this.state.lesson.blocks.length !== 0) {
                // lesson blocks
                for (let i = 0; i <= this.state.lesson.currentBlock; i++) {
                    // replace placeholder with first name
                    let text = this.state.lesson.blocks[i].text;
                    if (!!text) {
                        text = text.replace("$$placeholder$$", this.props.firstName);
                    }
                    // select component based on block type
                    let item;
                    switch (this.state.lesson.blocks[i].blockType) {
                        case "indefiniteQuestion":
                            item = (
                                <IndefiniteQuestion
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleMarkTime={this.handleMarkTime}
                                    courseId={this.state.courseId}
                                    lessonId={this.state.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "question":
                            item = (
                                <Question
                                    block={this.state.lesson.blocks[i]}
                                    handleAnswering={this.handleAnswering}
                                    handleCorrect={this.handleCorrect}
                                    handleMarkTime={this.handleMarkTime}
                                    demo={this.props.demo}
                                    courseId={this.state.courseId}
                                    lessonId={this.state.lessonId}
                                    id={this.state.id}
                                    index={i}
                                    innerWidth={this.props.innerWidth}
                                    section={this.state.lesson.section}
                                    showTimer={this.props.isTimerVisible}
                                    nickname={this.props.firstName}
                                    questionNumber={questionNumber}
                                    text={text}
                                />
                            );
                            questionNumber++;
                            break;
                        case "text":
                            item = (
                                <TextBlock
                                    highlighted={this.state.lesson.blocks[i].highlighted}
                                    person={this.state.lesson.blocks[i].person}
                                    text={text}
                                />
                            );
                            break;
                        case "iframe":
                            item = <IframeBlock src={this.state.lesson.blocks[i].iframeSrc} />;
                            break;
                        case "interactive":
                            item = (
                                <InteractiveBlock
                                    highlighted={this.state.lesson.blocks[i].highlighted}
                                    person={this.state.lesson.blocks[i].person}
                                    popupTextList={this.state.lesson.blocks[i].popupTextList}
                                    text={text}
                                />
                            );
                            break;
                        case "fileUpload":
                            item = (
                                <FileUploadBlock
                                    block={this.state.lesson.blocks[i]}
                                    courseId={this.state.courseId}
                                    lessonId={this.state.lessonId}
                                    highlighted={this.state.lesson.blocks[i].highlighted}
                                    text={text}
                                />
                            );
                            break;
                        default:
                            item = null;
                    }
                    // push item
                    lesson.push(
                        <StyledBlockWrapper>
                            <StyledPersonContainer>
                                {this.state.lesson.blocks[i].person && <StyledPersonImage src={this.state.lesson.blocks[i].person} alt="" />}
                            </StyledPersonContainer>
                            <StyledBlockContainer
                                key={`dashboard-lesson-block-${i}`}
                                name={i === this.state.lesson.currentBlock ? "last-block" : ""}
                            >
                                {item}
                                {this.state.lesson.blocks[i].audio && <CustomAudioPlayer audio={this.state.lesson.blocks[i].audio} />}
                                {this.state.lesson.blocks[i].attachedFile && (
                                    <div>
                                        Приложенный файл: 
                                        <a href={`${baseUrl}${this.state.lesson.blocks[i].attachedFile}`} rel="noopener noreferrer" target="_blank"> скачать</a>
                                    </div>
                                )}
                            </StyledBlockContainer>
                        </StyledBlockWrapper>
                    );
                }

                // push next block button
                if (!this.state.lesson.completed && !this.state.answering) {
                    lesson.push(
                        <StyledNextBlockButtonContainer key="dashboard-lesson-next-block-button">
                            <button
                                // size={
                                //     this.props.innerWidth > 700
                                //         ? "huge"
                                //         : this.props.innerWidth > 600
                                //         ? "large"
                                //         : this.props.innerWidth > 500
                                //         ? "medium"
                                //         : "tiny"
                                // }
                                onClick={this.handleNextClick}
                            >
                                {t("dashboard.course.lesson.nextBlock")}
                            </button>
                        </StyledNextBlockButtonContainer>
                    );
                }
            }

            if (this.state.lesson.completed) {
                // lesson extra material
                if (this.state.lesson.extraMaterial) {
                    lesson.push(
                        <StyledExtraMaterialsContainer key="dashboard-lesson-extra-materials">
                            <div className="header">{t("dashboard.course.lesson.extraMaterials")}:</div>
                            {ReactHtmlParser(this.state.lesson.extraMaterial)}
                        </StyledExtraMaterialsContainer>
                    );
                }

                if (this.state.lesson.prevLesson._id && this.state.lesson.nextLesson._id) {
                    // stars
                    lesson.push(
                        <StarsComponent
                            key="dashboard-lesson-stars"
                            name="dashboard-lesson-stars"
                            questions={this.state.lesson.questions}
                            correct={this.state.lesson.correct}
                        />
                    );
                    // caption under stars
                    lesson.push(
                        <StyledShortStatsContainer key="dashboard-lesson-short-stats">
                            <p>
                                {t("dashboard.course.lesson.totalQuestions")}: {this.state.lesson.questions}
                            </p>
                            <p>
                                {t("dashboard.course.lesson.correctQuestions")}: {this.state.lesson.correct}
                            </p>
                        </StyledShortStatsContainer>
                    );

                    // send lesson stats button
                    lesson.push(
                        <StyledSendStatsButtonContainer key="dashboard-lesson-send-stats">
                            <button onClick={() => this.toggleLessonStatsModalVisibility(true)}>
                                {t("dashboard.course.lesson.sendStats")}
                            </button>
                        </StyledSendStatsButtonContainer>
                    );
                    // lesson stats modal
                    lesson.push(
                        <LessonStatsModal
                            open={this.state.isLessonStatsModalVisible}
                            onClose={() => this.toggleLessonStatsModalVisibility(false)}
                            key="dashboard-lesson-footer-stats"
                            extraMaterial={this.state.lesson.extraMaterial}
                            correct={this.state.lesson.correct}
                            demo={this.props.demo}
                            courseId={this.state.courseId}
                            lessonId={this.state.lessonId}
                            innerWidth={this.props.innerWidth}
                            section={this.state.lesson.section}
                            questions={this.state.lesson.questions}
                            questionBlocks={this.state.lesson.blocks.filter((x) => x.blockType === "question")}
                        />
                    );
                }

                // next lesson link
                lesson.push(
                    <StyledNextLessonLinkContainer key="dashboard-lesson-next-lesson">
                        {this.state.lesson.nextLesson && this.state.lesson.nextLesson._id ? (
                            <div className="container" onClick={this.handleUpdateLastLesson}>
                                <span className="link">{t("dashboard.course.lesson.nextLesson")}</span>
                                <img className="img" src={nextArrow} alt="" />
                            </div>
                        ) : (
                            <div className="container">
                                <span className="link inactive">{t("dashboard.course.lesson.endLesson")}</span>
                            </div>
                        )}
                    </StyledNextLessonLinkContainer>
                );
            }
        }

        return (
            <DocumentTitle title={lessonSectionName + lessonName}>
                <StyledLessonContainer>
                    {forbiddenContainer}
                    {notFoundContainer}
                    {this.state.loading ? <Loader active size="large" /> : lesson}
                </StyledLessonContainer>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    firstName: state.user.user.firstName,
    payment: state.user.user.payment,
    courses: state.user.user.courses,
    isTimerVisible: state.ui.isTimerVisible,
});

const mapDispatchToProps = (dispatch) => ({
    getUser: () => dispatch(getUser()),
    logout: () => dispatch(logout()),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(Lesson))
);
