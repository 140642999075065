// modules
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { withTranslation } from "react-i18next";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Checkbox, Form, Statistic } from "semantic-ui-react";
import "./index.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

class Question extends Component {
    state = {
        answer: this.props.block.answer ? JSON.parse(this.props.block.answer) : "",
        answered: !!this.props.block.answer,
        correct: this.props.block.correct,
        elapsed: 0,
        multiple: this.props.block.multiple ? [] : undefined,
        options: this.props.block.options ? JSON.parse(this.props.block.options) : undefined,
        showHint: false,
        testQuestion: this.props.block.testQuestion,
    };

    componentDidMount() {
        if (!this.state.answered) {
            // start the timer
            this.timer = setInterval(this.tick, 1000);
            this.start = new Date();
            // set answering to false (for parent Lesson component)
            this.props.handleAnswering(true);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    // tick-tack time is running
    tick = () => {
        this.setState({
            elapsed: Math.round((new Date() - this.start) / 1000),
        });
    };

    handleChange = (e, data) => {
        if (data.name === "open" || data.name === "closed") {
            this.setState({
                answer: data.value,
            });
        } else if (data.name === "multiple") {
            if (data.checked) {
                const multiple = [...this.state.multiple];
                multiple.push(data.value);
                this.setState({ multiple: multiple });
            } else {
                const multiple = this.state.multiple.filter((x) => x !== data.value);
                this.setState({ multiple: multiple });
            }
        } else {
            this.setState({
                [data.name]: data.value,
            });
        }
    };

    handleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    };

    handleAnswer = () => {
        // stop timer
        clearInterval(this.timer);
        // pick necessary fields and correct answer
        let courseId = this.props.courseId;
        let lessonId = this.props.lessonId;
        let blockId = this.props.block._id;
        let correct = false;
        let { answer, elapsed, multiple } = this.state;
        let correctAnswer = JSON.parse(this.props.block.correctAnswer);
        // record answer
        if (this.props.block.openQuestion) {
            // lowercase answer
            answer = answer.toLowerCase();
            // remove whitespaces
            answer = answer.replace(/\s/g, "");
            if (isNaN(correctAnswer)) {
                // text answer
                if (answer === correctAnswer) {
                    correct = true;
                }
            } else {
                // number answer
                let corr = Number(correctAnswer);
                if (this.props.block.approximate) {
                    // approximate
                    let corrDown = corr - corr * 0.1;
                    let corrUp = corr + corr * 0.1;
                    answer = Number(answer);
                    if (answer >= corrDown && answer <= corrUp) {
                        correct = true;
                    }
                } else {
                    if (answer === correctAnswer) {
                        correct = true;
                    }
                }
            }
        } else {
            if (this.props.block.multiple) {
                let multipleCorrect = false;
                if (this.state.multiple.length === correctAnswer.length) {
                    multipleCorrect = true;
                    for (let i = 0; i < correctAnswer.length; i++) {
                        if (!this.state.multiple.includes(correctAnswer[i])) {
                            multipleCorrect = false;
                            break;
                        }
                    }
                }
                correct = multipleCorrect;
            } else {
                if (answer === correctAnswer) {
                    correct = true;
                }
            }
        }
        // set answering to false (for parent Lesson component)
        this.props.handleAnswering(false);
        if (correct) {
            // increment correct counter
            this.props.handleCorrect();
        }
        // mark time (for parent Lesson component)
        this.props.handleMarkTime(this.props.index, elapsed);
        // answer payload
        const payload = {
            answer: this.props.block.multiple ? JSON.stringify(multiple) : JSON.stringify(answer),
            correct: correct,
            time: elapsed,
        };
        // axios call
        let answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
        if (this.props.demo) {
            answerRoute = dashboardRoutes.answerIntro(courseId, blockId);
        }
        axios.post(answerRoute, payload).then(() => {
            this.setState({ answered: true, correct: correct });
            if (this.props.block.multiple) {
                this.setState({
                    answer: [...this.state.multiple],
                    multiple: [],
                });
            }
        });
    };

    render() {
        const { t } = this.props;

        let answerButton = (
            <Form.Button
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                        ? "medium"
                        : this.props.innerWidth > 500
                        ? "tiny"
                        : "mini"
                }
                type="submit"
            >
                {t("dashboard.course.lesson.question.answer")}
            </Form.Button>
        );

        let header = this.props.section
            ? `${t("dashboard.course.lesson.question.task")} ${this.props.section}.${this.props.questionNumber}`
            : `${t("dashboard.course.lesson.question.task")} ${this.props.questionNumber}`;
        let hint;
        let question;
        let explanation;
        // display hint
        if (this.props.block.hint) {
            hint = (
                <div className="dashboard-lesson-block-question-hint">
                    <Button
                        className="dashboard-lesson-block-question-hint-button"
                        onClick={this.handleHint}
                        size={
                            this.props.innerWidth > 700
                                ? "large"
                                : this.props.innerWidth > 600
                                ? "medium"
                                : this.props.innerWidth > 500
                                ? "tiny"
                                : "mini"
                        }
                    >
                        {this.state.showHint
                            ? t("dashboard.course.lesson.question.hideHint")
                            : t("dashboard.course.lesson.question.showHint")}
                    </Button>
                    {this.state.showHint ? (
                        <div className="dashboard-lesson-block-question-hint-text">
                            {ReactHtmlParser(this.props.block.hint)}
                        </div>
                    ) : null}
                </div>
            );
        }
        // display explanataion for answered question
        if (this.state.answered && !this.state.testQuestion) {
            // explanation text
            let explanationText = "";
            if (this.state.correct) {
                explanationText = this.props.block.correctText;
            } else {
                explanationText = this.props.block.wrongText;
            }
            // replace name
            if (!!explanationText) {
                explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
            }
            // replace time
            if (this.props.block.time) {
                explanationText = explanationText.replace("$$time$$", this.props.block.time);
            }
            // replace rec time
            if (this.props.block.recTime) {
                explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
            }
            explanation = (
                <div
                    className={
                        this.state.correct === true
                            ? "dashboard-lesson-block-question-explanation-correct"
                            : "dashboard-lesson-block-question-explanation-wrong"
                    }
                    id={`lesson-${this.props.lessonId}-question-${this.props.block._id}-explanation`}
                >
                    {ReactHtmlParser(explanationText)}
                </div>
            );
        }
        // question form
        if (this.props.block.openQuestion) {
            // open question form
            question = (
                <Form autoComplete="off" className="dashboard-lesson-block-question-form" onSubmit={this.handleAnswer}>
                    <div className="dashboard-lesson-block-question-form-instruction">
                        {t("dashboard.course.lesson.question.instruction")}
                    </div>
                    <Form.Input
                        className="dashboard-lesson-block-question-form-input"
                        fluid
                        name="open"
                        required
                        placeholder={
                            this.props.block.approximate
                                ? t("dashboard.course.lesson.question.placeholder")
                                : t("dashboard.course.lesson.question.placeholderApproximate")
                        }
                        readOnly={this.state.answered}
                        size="large"
                        value={this.state.answer}
                        onChange={this.handleChange}
                    />
                    {this.state.answered ? null : this.props.showTimer ? (
                        <div>
                            <Statistic
                                className="dashboard-lesson-block-question-form-time"
                                floated="right"
                                size="tiny"
                            >
                                <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                                <Statistic.Value>{this.state.elapsed}</Statistic.Value>
                            </Statistic>
                            {answerButton}
                        </div>
                    ) : (
                        <div>{answerButton}</div>
                    )}
                </Form>
            );
        } else {
            // closed question form
            let options = JSON.parse(this.props.block.options);
            if (this.props.block.multiple) {
                // multiple choice (checkboxes)
                question = (
                    <Form
                        autoComplete="off"
                        className="dashboard-lesson-block-question-form"
                        onSubmit={this.handleAnswer}
                    >
                        <div className="dashboard-lesson-block-question-form-instruction">
                            {t("dashboard.course.lesson.question.instructionMultiple")}
                        </div>
                        {options.map((option, index) => (
                            <Form.Field key={"dashboard-lesson-block-question-" + this.props.block._id + "-" + index}>
                                <Checkbox
                                    className="dashboard-lesson-block-question-form-checkbox"
                                    checked={
                                        this.state.answered
                                            ? this.state.answer.includes(option)
                                            : this.state.multiple.includes(option)
                                    }
                                    label={option}
                                    name="multiple"
                                    readOnly={this.state.answered}
                                    value={option}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        ))}
                        {this.state.answered ? null : this.props.showTimer ? (
                            <div>
                                <Statistic
                                    className="dashboard-lesson-block-question-form-time"
                                    floated="right"
                                    size="tiny"
                                >
                                    <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                                    <Statistic.Value>{this.state.elapsed}</Statistic.Value>
                                </Statistic>
                                {answerButton}
                            </div>
                        ) : (
                            <div>{answerButton}</div>
                        )}
                    </Form>
                );
            } else {
                // one choice (radio buttons)
                question = (
                    <Form
                        autoComplete="off"
                        className="dashboard-lesson-block-question-form"
                        onSubmit={this.handleAnswer}
                    >
                        <div className="dashboard-lesson-block-question-form-instruction">
                            {t("dashboard.course.lesson.question.instructionMultipleOne")}
                        </div>
                        {options.map((option, index) => (
                            <Form.Field key={"dashboard-lesson-block-question-" + this.props.block._id + "-" + index}>
                                <Checkbox
                                    className="dashboard-lesson-block-question-form-radio"
                                    checked={option === this.state.answer}
                                    label={option}
                                    name="closed"
                                    radio
                                    readOnly={this.state.answered}
                                    value={option}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        ))}
                        {this.state.answered ? null : this.props.showTimer ? (
                            <div>
                                <Statistic
                                    className="dashboard-lesson-block-question-form-time"
                                    floated="right"
                                    size="tiny"
                                >
                                    <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                                    <Statistic.Value>{this.state.elapsed}</Statistic.Value>
                                </Statistic>
                                {answerButton}
                            </div>
                        ) : (
                            <div>{answerButton}</div>
                        )}
                    </Form>
                );
            }
        }

        return (
            <div
                className="dashboard-lesson-block-question"
                id={`lesson-${this.props.lessonId}-question-${this.props.block._id}`}
            >
                <div className="dashboard-lesson-block-question-header">{header}</div>
                <div className="dashboard-lesson-block-question-text">
                    <MathjaxWrapper text={ReactHtmlParser(this.props.text)} />
                </div>
                {this.props.block.audio && <CustomAudioPlayer src={this.props.block.audio} />}
                {hint}
                {question}
                {explanation}
            </div>
        );
    }
}

export default withTranslation()(Question);
