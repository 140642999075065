// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
// assets
// styles
// components
import Admin from "containers/Admin";
import Dashboard from "containers/Dashboard";
import Landing from "containers/Landing";
import ProtectedRoute from "./ProtectedRoute";
// redux

class App extends Component {
    render() {
        const { isAuthenticated, role } = this.props;

        return (
            <Router>
                <Switch>
                    <ProtectedRoute
                        isAuthenticated={isAuthenticated}
                        condition={role !== "user"}
                        path="/admin"
                        component={Admin}
                    />
                    <ProtectedRoute
                        isAuthenticated={isAuthenticated}
                        condition={role === "user"}
                        path="/dashboard"
                        component={Dashboard}
                    />
                    <Route exact path="/" component={Landing} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    isAuthenticated: !!state.user.user.email,
    role: state.user.user.scope,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
