// modules
import React, { Component } from "react";
import axios from "axios";
// assets
import { bold } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
import { mongoObjectId } from "assets/utils";
// styles
import { Button, Checkbox, Container, Form, Image, Input, Label, Loader, Message, TextArea } from "semantic-ui-react";
import "./index.css";
// components
// redux

// add style
const addStyle = {
    backgroundColor: "#0dab76",
    color: "#fff",
    borderRadius: "0px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    marginTop: "1em",
    marginBottom: "1em",
};
// edit style
const editStyle = {
    backgroundColor: "#1099d3",
    color: "#fff",
    borderRadius: "0px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    marginBottom: "1em",
};
// delete style
const deleteStyle = {
    backgroundColor: "#cc515d",
    color: "#fff",
    borderRadius: "0px",
    boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
    marginBottom: "1em",
};

class Question extends Component {
    // load state from props
    state = {
        _id: this.props.question ? this.props.question._id : mongoObjectId(),
        question: this.props.question
            ? JSON.parse(this.props.question.question)
            : {
                  text: "",
                  openQuestion: false,
                  approximate: false,
                  options: [],
                  correctAnswer: [],
                  hint: "",
                  recTime: 0,
                  indefinite: false,
                  explanationText: "",
                  correctText: "",
                  wrongText: "",
                  testQuestion: false,
              },
        newOption: "",
        adding: false,
        editing: false,
        deleting: false,
        uploading: false,
    };

    // optimizing render strategy
    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.question !== nextState.question ||
            this.props.question !== nextProps.question ||
            this.state.newOption !== nextState.newOption
        ) {
            return true;
        }
        return false;
    }

    // load updated question from props to state
    // componentDidUpdate(prevProps) {
    //     if (this.props.question !== this.state.question && this.props.question !== prevProps.question) {
    //         this.setState({
    //             question: JSON.parse(this.props.question.question)
    //         });
    //     }
    // }

    addQuestion = () => {
        if (!this.state.question.indefinite && this.state.question.correctAnswer.length === 0) {
            alert("Правильный ответ не выбран");
            return;
        }
        // confirmation
        const r = window.confirm("Добавить новый практический вопрос?");
        if (r) {
            this.setState({ adding: true });
            // stringify question
            let question = { _id: this.state._id, question: JSON.stringify({ ...this.state.question }) };
            // build payload
            const payload = {
                question: question,
                position: this.props.position,
            };
            // axios call
            axios.post(adminRoutes.practices.addQuestion(this.props.practiceId), payload).then(
                () => {
                    this.setState({ adding: false });
                    this.props.fetchPractice(this.props.practiceId);
                },
                (error) => {
                    this.setState({ adding: false });
                    console.error(error);
                }
            );
        }
    };

    editQuestion = () => {
        if (!this.state.question.indefinite && this.state.question.correctAnswer.length === 0) {
            alert("Правильный ответ не выбран");
            return;
        }
        // confirmation
        const r = window.confirm("Редактировать этот вопрос?");
        if (r) {
            this.setState({ editing: true });
            // stringify question
            let question = {
                _id: this.state._id ? this.state._id : mongoObjectId(),
                question: JSON.stringify({ ...this.state.question }),
            };
            // axios call
            axios
                .post(adminRoutes.practices.editQuestion(this.props.practiceId, this.props.index), {
                    question: question,
                })
                .then(
                    () => {
                        this.setState({ editing: false });
                        this.props.fetchPractice(this.props.practiceId);
                    },
                    (error) => {
                        this.setState({ editing: false });
                        console.error(error);
                    }
                );
        }
    };

    deleteQuestion = () => {
        const r = window.confirm("Удалить этот вопрос?");
        if (r) {
            this.setState({ deleting: true });
            axios.delete(adminRoutes.practices.deleteQuestion(this.props.practiceId, this.props.index)).then(
                () => {
                    this.setState({ deleting: false });
                    this.props.fetchPractice(this.props.practiceId);
                },
                (error) => {
                    this.setState({ deleting: false });
                    console.error(error);
                }
            );
        }
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    handleChangeQuestion = (e, data) => {
        let result = data.value;
        if (
            data.name === "approximate" ||
            data.name === "indefinite" ||
            data.name === "openQuestion" ||
            data.name === "testQuestion"
        ) {
            result = data.checked;
        }
        this.setState((prevState) => ({
            question: {
                ...prevState.question,
                [data.name]: result,
            },
        }));
    };

    handleAddOption = () => {
        let newOption = this.state.newOption;
        // append an option
        this.setState((prevState) => ({
            question: {
                ...prevState.question,
                options: [...prevState.question.options, newOption],
            },
            newOption: "",
        }));
    };

    handleDeleteOption = (index) => {
        // copy options and correct answer(s)
        let options = [...this.state.question.options];
        let correctAnswer = [...this.state.question.correctAnswer];
        // remove the option from correct answer (if applicable)
        if (correctAnswer.length !== 0) {
            correctAnswer = correctAnswer.filter((x) => x !== options[index]);
        }
        // remove the option
        options.splice(index, 1);
        this.setState((prevState) => ({
            question: {
                ...prevState.question,
                correctAnswer: correctAnswer,
                options: options,
            },
        }));
    };

    handleChangeCorrect = (e, data) => {
        // copy option and correct answer(s)
        let option = this.state.question.options[data.index];
        let correctAnswer = [...this.state.question.correctAnswer];
        if (data.checked) {
            // add option to correct (if it is not there already)
            if (!correctAnswer.includes(option)) {
                correctAnswer.push(option);
            }
        } else {
            // remove option from correct (if it is there)
            correctAnswer = correctAnswer.filter((x) => x !== option);
        }
        this.setState((prevState) => ({
            question: {
                ...prevState.question,
                correctAnswer: correctAnswer,
            },
        }));
    };

    handleImageSelect = async (e) => {
        // create a new form data
        const data = new FormData();
        // append file
        data.append("file", e.target.files[0]);
        // get file extension
        let extension = e.target.files[0].name.split(".");
        extension = extension[extension.length - 1];
        // append filename
        data.append("filename", `practice-${this.props.practiceId}-question-${this.state._id}.${extension}`);
        // set uploading to true
        this.setState({ uploading: true });
        // axios call
        const response = await axios.post(adminRoutes.upload.uploadImage(), data).catch((error) => {
            this.setState({ uploading: false });

            return;
        });
        // set picture to state
        this.setState((prevState) => ({
            question: {
                ...prevState.question,
                image: response.data.message.link,
            },
            uploading: false,
        }));
    };

    render() {
        const { newOption, question } = this.state;

        return (
            <div>
                {bold("Текст вопроса:")}
                <TextArea
                    autoHeight
                    name="text"
                    value={question.text}
                    onChange={this.handleChangeQuestion}
                    style={{ marginBottom: "1em", width: "100%", minHeight: 150 }}
                />

                {bold("Загрузить изображение:")}
                {this.state.uploading ? (
                    <Loader active inline="centered" size="large" style={{ marginBottom: "2em" }} />
                ) : (
                    <input type="file" onChange={this.handleImageSelect} style={{ marginBottom: "1em" }} />
                )}
                {bold("Ссылка на изображение:")}
                <Input
                    fluid
                    name="image"
                    value={question.image || ""}
                    onChange={this.handleChangeQuestion}
                    style={{ marginBottom: "1em" }}
                />

                <div style={{ marginBottom: "2em" }}>
                    {bold("Изображение:")}
                    {question.image ? (
                        <Image alt="" src={question.image || ""} />
                    ) : (
                        <div>
                            <Image size="small">
                                <Label content="Image not found!" icon="warning" />
                            </Image>
                        </div>
                    )}
                </div>

                <Container style={{ marginBottom: "1em" }}>
                    <Checkbox
                        label="Тестовый вопрос?"
                        toggle
                        name="testQuestion"
                        checked={question.testQuestion}
                        onChange={this.handleChangeQuestion}
                        style={{ fontWeight: 400 }}
                    />
                </Container>
                <br />
                <Container style={{ marginBottom: "1em" }}>
                    <Checkbox
                        label="Нет ответа на вопрос?"
                        toggle
                        name="indefinite"
                        checked={question.indefinite}
                        onChange={this.handleChangeQuestion}
                        style={{ fontWeight: 400 }}
                    />
                </Container>
                <br />
                {question.indefinite ? null : (
                    <div>
                        <Container style={{ marginBottom: "1em" }}>
                            <Checkbox
                                label="Открытый вопрос?"
                                toggle
                                name="openQuestion"
                                checked={question.openQuestion}
                                onChange={this.handleChangeQuestion}
                                style={{ fontWeight: 400 }}
                            />
                        </Container>
                        {question.openQuestion ? (
                            <Container style={{ marginBottom: "1em" }}>
                                <Checkbox
                                    label="Приблизительный ответ? (засчитывается числовой ответ студента +- 10% от правильного ответа)"
                                    toggle
                                    name="approximate"
                                    checked={question.approximate}
                                    onChange={this.handleChangeQuestion}
                                    style={{ fontWeight: 400 }}
                                />
                            </Container>
                        ) : null}
                        {question.openQuestion ? (
                            <Container style={{ marginBottom: "1em" }}>
                                {bold("Правильный ответ:")}
                                <Input
                                    fluid
                                    name="correctAnswer"
                                    value={question.correctAnswer}
                                    onChange={this.handleChangeQuestion}
                                />
                            </Container>
                        ) : (
                            <Container style={{ marginBottom: "1em" }}>
                                {bold("Варианты ответа:")}
                                <Message
                                    icon="check"
                                    info
                                    header="Выбор ответа(-ов)"
                                    content="Чтобы выбрать правильный вариант(ы) ответа, нажмите на них"
                                />
                                <Form style={{ marginBottom: "1em" }}>
                                    {question.options.length !== 0
                                        ? question.options.map((option, index) => (
                                              <Form.Field
                                                  inline
                                                  key={
                                                      "admin-lesson-practice-question-" +
                                                      question._id +
                                                      "-option-" +
                                                      index
                                                  }
                                              >
                                                  <Checkbox
                                                      checked={question.correctAnswer.includes(option)}
                                                      index={index}
                                                      label={option}
                                                      onChange={this.handleChangeCorrect}
                                                      value={option}
                                                  />
                                                  <span
                                                      className="admin-practice-question-delete-option"
                                                      onClick={() => this.handleDeleteOption(index)}
                                                  >
                                                      (удалить)
                                                  </span>
                                              </Form.Field>
                                          ))
                                        : null}
                                </Form>
                                {bold("Добавить вариант ответа:")}
                                <Input
                                    fluid
                                    name="newOption"
                                    placeholder="Вариант ответа"
                                    onChange={this.handleChange}
                                    value={newOption}
                                    style={{ marginBottom: "1em" }}
                                />
                                <Button onClick={this.handleAddOption}>Добавить вариант ответа</Button>
                            </Container>
                        )}
                    </div>
                )}
                {bold("Подсказка:")}
                <TextArea
                    autoHeight
                    name="hint"
                    value={question.hint}
                    onChange={this.handleChangeQuestion}
                    style={{ width: "100%", minHeight: 100 }}
                />
                {bold("Рекоммендуемое время (в секундах):")}
                <Input
                    fluid
                    name="recTime"
                    type="number"
                    value={question.recTime}
                    onChange={this.handleChangeQuestion}
                />
                <Message
                    info
                    icon="stopwatch"
                    header="Время решения вопроса"
                    content="Если хочешь добавить время в объяснении вопроса (при правильном/неправильном/неопределенном ответе), введи $$time$$, программа сама заменит время на результат пользователя. Тоже самое с $$rec_time$$ для указания рекоммендуемого времени на указанное задание."
                />

                {question.testQuestion ? null : question.indefinite ? (
                    <div>
                        {bold("Текст после ответа:")}
                        <TextArea
                            autoHeight
                            name="explanationText"
                            value={question.explanationText}
                            onChange={this.handleChangeQuestion}
                            style={{ marginBottom: "1em", width: "100%", minHeight: 100 }}
                        />
                    </div>
                ) : (
                    <div>
                        {bold("Текст при правильном ответе:")}
                        <TextArea
                            autoHeight
                            name="correctText"
                            value={question.correctText}
                            onChange={this.handleChangeQuestion}
                            style={{ marginBottom: "1em", width: "100%", minHeight: 100 }}
                        />
                        {bold("Текст при неправильном ответе:")}
                        <TextArea
                            autoHeight
                            name="wrongText"
                            value={question.wrongText}
                            onChange={this.handleChangeQuestion}
                            style={{ marginBottom: "1em", width: "100%", minHeight: 100 }}
                        />
                    </div>
                )}
                {this.props.question ? (
                    <div>
                        <Button
                            key={"admin-practice-question-" + question._id + "-edit-button"}
                            loading={this.state.editing}
                            onClick={this.editQuestion}
                            style={editStyle}
                        >
                            Редактировать вопрос
                        </Button>
                        <Button
                            key={"admin-practice-question-" + question._id + "-delete-button"}
                            loading={this.state.deleting}
                            onClick={this.deleteQuestion}
                            style={deleteStyle}
                        >
                            Удалить вопрос
                        </Button>
                    </div>
                ) : (
                    <Button
                        key={"admin-practice-question-" + question._id + "-add-button"}
                        type="button"
                        onClick={this.addQuestion}
                        style={addStyle}
                    >
                        Добавить вопрос
                    </Button>
                )}
            </div>
        );
    }
}

export default Question;
