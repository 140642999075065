// modules
import React from "react";
import AudioPlayer from "react-h5-audio-player";
// assets
// styles
import "react-h5-audio-player/lib/styles.css";
// components
// redux

const CustomAudioPlayer = (props) => {
    const baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:8080" : "https://workternship.com";

    return <AudioPlayer src={`${baseUrl}${props.audio}`} showJumpControls={false} />;
};

export default CustomAudioPlayer;
