// modules
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import { Button, Icon } from "semantic-ui-react";
import "./index.css";
// assets
// components
// redux

const formatter = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits: 0,
});

class Payment extends Component {
    state = {
        amount: 10000,
        saleAmount: 6990,
    };

    render() {
        const { amount, saleAmount } = this.state;
        const { email } = this.props;

        return (
            <section id="payment" className="landing-payment-section">
                <div className="landing-payment-section-container">
                    <h1>Оплата</h1>
                    {/* <h2>Старт продаж курса запланирован на 1 июня</h2> */}
                    {/* <p>Курс будет доступен сразу после оплаты.</p> */}
                    <div className="amount">
                        <p className="amount-header">Стоимость курса:</p>
                        <p className="amount-text">
                            <span style={{ textDecoration: "line-through" }}>{formatter.format(amount)}</span>
                        </p>
                        <p className="amount-text">{formatter.format(saleAmount)}</p>
                    </div>

                    <div className="note">
                        *Если в течении недели вы поймете, что курс вам не подходит, то мы вернём вам 90% стоимости (при
                        условии, что вы прошли менее 30% курса)
                    </div>
                    <br />
                    <p>
                        <b>Выберите вариант оплаты:</b>
                    </p>
                    <form method="POST" action="https://money.yandex.ru/quickpay/confirm.xml">
                        <input type="hidden" name="receiver" value="410017743619663" />
                        <input type="hidden" name="quickpay-form" value="small" />
                        <input type="hidden" name="targets" value="Оплата курса consultantfromscratch.com" />
                        <div>
                            <input id="yandex-money" name="paymentType" type="radio" value="PC" />
                            <label htmlFor="yandex-money">Яндекс.Деньгами</label>
                        </div>
                        <div>
                            <input id="bank-card" name="paymentType" type="radio" value="AC" />
                            <label htmlFor="bank-card">Банковской картой</label>
                        </div>
                        <label />
                        <input type="hidden" name="sum" value={saleAmount} data-type="number" />
                        <input type="hidden" name="formcomment" value="Consultantfromscratch: покупка курса" />
                        <input type="hidden" name="short-dest" value="Consultantfromscratch: покупка курса" />
                        <input type="hidden" name="label" value={email} />
                        <input type="hidden" name="need-email" value="false" />
                        <input type="hidden" name="successURL" value="https://consultantfromscratch.com" />
                        <Button
                            className="pay-button"
                            // disabled
                            icon
                            labelPosition="right"
                            primary
                            type="submit"
                            size={
                                this.props.innerWidth > 700
                                    ? "large"
                                    : this.props.innerWidth > 600
                                    ? "medium"
                                    : this.props.innerWidth > 500
                                    ? "tiny"
                                    : "mini"
                            }
                        >
                            Перевести
                            <Icon name="payment" />
                        </Button>
                    </form>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    email: state.user.user.email,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Payment);
