// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import DocumentTitle from 'react-document-title';
// assets
import { analyticsRoutes } from 'assets/routes';
import { compareUsers } from 'assets/utils';
import { redirect } from 'utils';
// styles
import { Container, Header, Input, List, Loader } from 'semantic-ui-react';
import './index.css';
// components
// redux
import { logout } from 'store/User';

class Analytics extends Component {
  state = {
    loading: false,
    search: ''
  };

  componentDidMount() {
    this.getUsers();
  }

  getUsers = async () => {
    this.setState({ loading: true });
    const response = await axios.get(analyticsRoutes.getUsers()).catch(error => {
      this.setState({ loading: false });
      if (error.response.status === 401) {
        this.props.logout();
      }
    });
    this.setState({ loading: false, users: response.data.message });
  };

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    });
  };

  render() {
    let users = [];
    let userItems = [];
    let usersNumber = 0;

    if (this.state.users && this.state.users.length !== 0) {
      // filter out users
      users = this.state.users.filter(user => user.scope === 'user');
      // count the number of users
      usersNumber = users.length;
      // filter users by name
      users = users.filter(value => {
        let name = (value.lastName + ' ' + value.firstName).toLowerCase();
        return name.includes(this.state.search.toLowerCase());
      });
      // sort users
      users.sort(compareUsers);

      // add users to list
      for (let i = 0; i < users.length; i++) {
        let user = users[i];
        userItems.push(
          <List.Item key={'admin-analytics-users-' + i}>
            <List.Icon name="user" />
            <List.Content>
              <List.Header
                style={{
                  color: '#3697dd',
                  cursor: 'pointer',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '8px'
                }}
                onClick={() => redirect(this, `/admin/analytics/${user.email}`)}
              >
                {`${user.lastName} ${user.firstName}`}
              </List.Header>
              <List.Description>{user.email}</List.Description>
            </List.Content>
          </List.Item>
        );
      }
    }

    return (
      <DocumentTitle title="Аналитика">
        {this.state.loading ? (
          <Loader active size="large" />
        ) : (
          <Container>
            <Header size="large" textAlign="center">
              Аналитика по ученикам
            </Header>

            <div className="admin-analytics-subheader">Ученики: {usersNumber}</div>
            <div className="admin-analytics-search">
              <Input
                fluid
                icon="search"
                placeholder="Поиск учеников по полному имени"
                name="search"
                onChange={this.handleChange}
              />
            </div>
            <List divided relaxed="very">
              {userItems}
            </List>
          </Container>
        )}
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Analytics);
