// modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// styles
import { Modal } from 'semantic-ui-react';
import './index.css';
// assets
import cross from 'assets/images/cross.svg';
// components
// redux
import { forgotPassword, login } from 'store/User';

class LoginModal extends Component {
  state = {
    email: '',
    password: '',
    toggleForgot: false,
    errorMsg: ''
  };

  // toggle forgot to false when modal is opened
  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevProps.open) {
      this.setState({ toggleForgot: false });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  toggleForgot = () => {
    this.setState(prevState => ({ toggleForgot: !prevState.toggleForgot }));
  };

  handleForgot = event => {
    event.preventDefault();
    this.props.forgotPassword(this.state.email).catch(error => {
      console.error(error);
    });
  };

  handleLogin = event => {
    event.preventDefault();
    const { email, password } = this.state;
    const { t } = this.props;

    this.props
      .login(email, password)
      .then(() => {
        // action based on role
        switch (this.props.role) {
          case 'admin':
          case 'teacher':
          case 'author':
            this.props.history.push('/admin');
            break;
          default:
            this.props.history.push('/dashboard');
        }
      })
      .catch(error => {
        console.error(error);
        let errorMsg;
        switch (this.props.status) {
          case 401:
            errorMsg = t('errors.401');
            break;
          case 403:
            errorMsg = t('errors.403');
            break;
          case 404:
            errorMsg = t('errors.404');
            break;
          case 503:
            errorMsg = t('errors.503');
            break;
          default:
            error = t('errors.default');
        }
        this.setState({ errorMsg: errorMsg });
      });
  };

  render() {
    const { email, password, toggleForgot, errorMsg } = this.state;
    const { loginError, forgotError, forgotDone, notVerified, verified, t } = this.props;
    let loginErrorMsg;
    let forgotErrorMsg;
    let forgotDoneMsg;
    let verifiedMsg;
    let verifiedErrorMsg;

    if (loginError) {
      loginErrorMsg = <div className="auth-modal-error-msg">{errorMsg}</div>;
    }
    if (forgotDone) {
      forgotDoneMsg = <div className="auth-modal-success-msg">{t('landing.forgotmodal.msg.success')}</div>;
    }
    if (forgotError) {
      forgotErrorMsg = <div className="auth-modal-error-msg">{t('landing.forgotmodal.msg.error')}</div>;
    }
    if (verified) {
      verifiedMsg = <div className="auth-modal-success-msg">{t('landing.verification.msg.success')}</div>;
    }
    if (notVerified) {
      verifiedErrorMsg = <div className="auth-modal-error-msg">{t('landing.verification.msg.error')}</div>;
    }

    return (
      <Modal
        className="auth-modal"
        closeOnDimmerClick={false}
        open={this.props.open}
        onClose={this.props.onClose}
        size="small"
      >
        <div>
          <div className="auth-modal-cross" onClick={this.props.onClose}>
            <img src={cross} alt="Cross icon" />
          </div>
          {toggleForgot ? (
            <div>
              <div className="auth-modal-header">
                <span>{t('landing.forgotmodal.header')}</span>
              </div>
              <p className="auth-modal-subheader">{t('landing.forgotmodal.text1')}</p>
              <p className="auth-modal-subheader">{t('landing.forgotmodal.text2')}</p>
              {forgotDoneMsg}
              {forgotErrorMsg}
              <form className="auth-modal-form" onSubmit={this.handleForgot}>
                <input
                  className="auth-modal-input"
                  autoFocus={true}
                  name="email"
                  value={email}
                  type="email"
                  required
                  placeholder="Email"
                  onChange={this.handleChange}
                />
                <div className="auth-modal-forgot" onClick={this.toggleForgot}>
                  {t('landing.forgotmodal.login')}
                </div>
                <div className="auth-modal-buttons">
                  <input type="submit" value={t('send')} />
                </div>
              </form>
            </div>
          ) : (
            <div>
              <div className="auth-modal-header">
                <span>{t('landing.loginmodal.header')}</span>
              </div>
              {verifiedMsg}
              {verifiedErrorMsg}
              {loginErrorMsg}
              <form className="auth-modal-form" onSubmit={this.handleLogin}>
                <input
                  className="auth-modal-input"
                  name="email"
                  value={email}
                  required
                  placeholder="Email"
                  onChange={this.handleChange}
                />
                <input
                  className="auth-modal-input"
                  name="password"
                  value={password}
                  type="password"
                  required
                  placeholder={t('password')}
                  onChange={this.handleChange}
                />
                <div className="auth-modal-forgot" onClick={this.toggleForgot}>
                  {t('landing.loginmodal.forgot')}
                </div>
                <div className="auth-modal-buttons">
                  <input type="submit" value={t('login')} />
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  role: state.user.user.scope,
  loginError: state.user.login.fail,
  forgotDone: state.user.forgotPassword.done,
  forgotError: state.user.forgotPassword.fail,
  status: state.user.login.status
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  forgotPassword: email => dispatch(forgotPassword(email)),
  login: (email, password) => dispatch(login(email, password))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(LoginModal))
);
