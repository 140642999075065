// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import DocumentTitle from "react-document-title";
// assets
import { header, property } from "assets/formatUtils";
import { analyticsRoutes } from "assets/routes";
import { formatDate } from "assets/utils";
// styles
import { Accordion, Container, Loader } from "semantic-ui-react";
// components
import CourseAnalytics from "../CourseAnalytics";
// redux
import { logout } from "store/User";

class UserAnalytics extends Component {
    state = {
        loading: false,
        user: {},
    };

    componentDidMount() {
        const email = this.props.match.params.email;

        // get user
        this.getUser(email);
    }

    getUser = (email) => {
        this.setState({ loading: true });
        axios
            .get(analyticsRoutes.getUser(email))
            .then((response) => {
                this.setState({ loading: false, user: response.data.message });
            })
            .catch((error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            });
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    render() {
        const { email, firstName, lastName } = this.state.user;

        const name = `${lastName} ${firstName}`;
        const courses = [];

        if (this.state.user.courses && this.state.user.courses.length !== 0) {
            for (let i = 0; i < this.state.user.courses.length; i++) {
                // extract course object from array
                const course = this.state.user.courses[i];
                // create empty array for lessons
                const lessons = [];

                if (!course || !course.course) {
                    continue;
                }

                if (course.lessons && course.lessons.length !== 0) {
                    for (let j = 0; j < course.lessons.length; j++) {
                        // extract lesson object
                        const lesson = course.lessons[j];
                        // find full lesson object from course lessons
                        const fullLesson = course.course.lessons.find((x) => x._id === lesson._id);
                        // populate lesson fields
                        if (fullLesson) {
                            lesson.name = fullLesson.name;
                            lesson.section = fullLesson.section;
                            lesson.length = fullLesson.length;
                            lesson.questions = fullLesson.questions;
                            lesson.blocks = fullLesson.blocks;
                            lesson.results = fullLesson.results;
                        }
                        // add lesson
                        lessons.push(lesson);
                    }
                }

                // add accordion panel
                courses.push({
                    key: `admin-analytics-user-course-${i}`,
                    title: `${course.course.name}`,
                    content: {
                        content: <CourseAnalytics courseId={course.course._id} lessons={lessons} />,
                    },
                });
            }
        }

        return (
            <DocumentTitle title="Аналитика пользователя">
                {this.state.loading ? (
                    <Loader active size="large" />
                ) : (
                    <Container>
                        {header(name, "24px", 600)}
                        {property("Полное имя", name)}
                        {property("Email", email)}
                        {property("Дата регистрации", formatDate(this.state.user ? this.state.user.createdAt : null))}

                        {header("Пройденные курсы", "20px", 600)}
                        <Accordion fluid styled panels={courses} style={{ marginBottom: "2em" }} />
                    </Container>
                )}
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAnalytics);
