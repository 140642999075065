// auth routes
export const authRoutes = {
  getUser: () => `/api/users`,
  register: () => `/api/users/register`,
  login: () => `/api/users/login`,
  logout: () => `/api/users/logout`,
  edit: attribute => `/api/users/edit/${attribute}`,
  forgotPassword: email => `/api/users/forgot/${email}`
};

// dashboard routes
export const dashboardRoutes = {
  fetchCourses: () => `/api/courses`,
  fetchCourseInfo: courseId => `/api/courses/${courseId}`,
  // intro lesson
  fetchIntroLessonName: courseId => `/api/courses/${courseId}/lessons/intro/name`,
  fetchIntroLesson: courseId => `/api/courses/${courseId}/lessons/intro`,
  updateIntroBlock: courseId => `/api/courses/${courseId}/lessons/intro/update`,
  fetchIntroBetterThan: courseId => `/api/courses/${courseId}/lessons/intro/better`,
  answerIntro: (courseId, blockId) => `/api/courses/${courseId}/lessons/intro/${blockId}/answer`,
  // lesson
  fetchLessons: courseId => `/api/courses/${courseId}/lessons`,
  fetchLesson: (courseId, lessonId) => `/api/courses/${courseId}/lessons/${lessonId}`,
  fetchBetterThan: (courseId, lessonId) => `/api/courses/${courseId}/lessons/${lessonId}/better`,

  updateLesson: courseId => `/api/courses/${courseId}/lessons/update`,
  updateBlock: (courseId, lessonId) => `/api/courses/${courseId}/lessons/${lessonId}/update`,

  uploadUserFile: (courseId, lessonId, blockId) => `/api/courses/${courseId}/lessons/${lessonId}/${blockId}/upload`,

  answer: (courseId, lessonId, blockId) => `/api/courses/${courseId}/lessons/${lessonId}/${blockId}/answer`
};

// practices routes
export const practicesRoutes = {
  getPractices: courseId => `/api/courses/${courseId}/practices`,
  getPractice: (courseId, practiceId) => `/api/courses/${courseId}/practices/${practiceId}`,
  updateLastQuestion: (courseId, practiceId) => `/api/courses/${courseId}/practices/${practiceId}/update`,
  answer: (courseId, practiceId, questionId) => `/api/courses/${courseId}/practices/${practiceId}/${questionId}/answer`,
  getResults: (courseId, practiceId) => `/api/courses/${courseId}/practices/${practiceId}/results`
};

// admin routes
export const adminRoutes = {
  introLesson: {
    getIntroLesson: () => `/api/admin/lessons/intro`,
    addIntroLesson: () => `/api/admin/lessons/intro/add`,
    editIntroLesson: () => `/api/admin/lessons/intro`,
    addBlock: () => `/api/admin/lessons/intro/block`,
    editBlock: position => `/api/admin/lessons/intro/${position}`,
    deleteBlock: position => `/api/admin/lessons/intro/${position}`
  },
  courses: {
    getCourses: () => `/api/admin/courses`,
    getCourse: id => `/api/admin/courses/${id}`,
    addCourse: () => `/api/admin/courses`,
    editCourse: id => `/api/admin/courses/${id}`,
    deleteCourse: id => `/api/admin/courses/${id}`,
    addLesson: id => `/api/admin/courses/${id}/lessons`
  },
  lessons: {
    getLesson: id => `/api/admin/lessons/${id}`,
    editLesson: id => `/api/admin/lessons/${id}`,
    deleteLesson: id => `/api/admin/lessons/${id}`,
    addBlock: id => `/api/admin/lessons/${id}/block`,
    editBlock: (id, position) => `/api/admin/lessons/${id}/${position}`,
    deleteBlock: (id, position) => `/api/admin/lessons/${id}/${position}`,
    editExtraMaterial: id => `/api/admin/lessons/${id}/extra`
  },
  practices: {
    getPractices: () => `/api/admin/practices`,
    getPractice: id => `/api/admin/practices/${id}`,
    addPractice: () => `/api/admin/practices`,
    editPractice: id => `/api/admin/practices/${id}`,
    deletePractice: id => `/api/admin/practices/${id}`,
    addQuestion: id => `/api/admin/practices/${id}/question`,
    editQuestion: (id, position) => `/api/admin/practices/${id}/${position}`,
    deleteQuestion: (id, position) => `/api/admin/practices/${id}/${position}`
  },
  teachers: {
    getTeachers: () => `/api/admin/teachers`,
    getTeacher: email => `/api/admin/teachers/${email}`,
    createTeacher: () => `/api/admin/teachers`
  },
  authors: {
    getAuthors: () => `/api/admin/authors`,
    getAuthor: email => `/api/admin/authors/${email}`,
    createAuthor: () => `/api/admin/authors`,
    editAuthor: email => `/api/admin/authors/${email}`
  },
  dialoguePersons: {
    getDialoguePersons: () => `/api/admin/dialoguepersons`,
    createDialoguePerson: () => `/api/admin/dialoguepersons`,
    editDialoguePerson: id => `/api/admin/dialoguepersons/${id}`,
    deleteDialoguePerson: id => `/api/admin/dialoguepersons/${id}`
  },
  upload: {
    uploadImage: () => `/api/admin/upload/images`,
    uploadAudio: () => `/api/admin/upload/audio`,
    uploadFile: () => `/api/admin/upload/files`
  },
  users: {
    getUsers: () => `/api/admin/users`,
    getUser: email => `/api/admin/users/${email}`,
    createUser: () => `/api/admin/users`,
    editUser: email => `/api/admin/users/${email}`,
    deleteUser: email => `/api/admin/users/${email}`,
    resetProgress: (email, courseId) => `/api/admin/users/${email}/reset/${courseId}`
  }
};

export const analyticsRoutes = {
  getUsers: () => `/api/analytics`,
  getUser: email => `/api/analytics/${email}`
};
