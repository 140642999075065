export const roles = {
  admin: 'администратор',
  author: 'автор',
  teacher: 'учитель',
  user: 'ученик'
};

export const blockTypes = {
  text: 'текст',
  nickname: 'ввод никнейма',
  video: 'видео',
  picture: 'изображение',
  dialogue: 'диалог',
  indefiniteQuestion: 'вопрос без правильного ответа',
  question: 'вопрос',
  iframe: 'iframe блок',
  interactive: 'интерактивный блок'
};

export const userActions = {
  registration: 'регистрация',
  verification: 'подтверждение регистрации',
  login: 'вход',
  'forgot password': 'запрос восстановления пароля',
  'reset password': 'восстановление пароля',
  'editing profile': 'изменение профиля',

  'loading lesson': 'загрузка урока',
  'updating last lesson': 'переход на следующую главу',
  'updating lesson current block': 'переход на следующий блок в главе',
  answer: 'ответ на вопрос',

  'reset progress': 'сброс прогресса (администратором)'
};
