// modules
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import styled from "styled-components";
// assets
import { redirect } from "utils";
// styles
import { Modal, Search } from "semantic-ui-react";
// components
// redux

const StyledSearchContainer = styled.div`
    display: flex;
    justify-content: center;

    .search,
    .input {
        width: 100%;
    }
`;

class SearchModal extends Component {
    state = {
        loading: false,
        results: [],
        value: "",
    };

    handleResultSelect = (e, data) => {
        if (data.result.id > this.props.lastLesson.id) {
            alert(this.props.t("lessonNotReached"));
            return;
        }

        // redirect
        redirect(this, `/dashboard/courses/${this.props.courseId}/lesson/${data.result._id}`);
        // close modal
        this.props.onClose();
    };

    handleSearchChange = (e, data) => {
        this.setState({ loading: true, value: data.value });

        setTimeout(() => {
            const re = new RegExp(_.escapeRegExp(this.state.value), "i");
            const isMatch = (result) => re.test(JSON.stringify(result.title) + JSON.stringify(result.keywords));

            this.setState({
                loading: false,
                results: _.filter(this.props.lessonKeywords, isMatch),
            });
        }, 300);
    };

    render() {
        const { loading, results, value } = this.state;

        return (
            <Modal open={this.props.open} onClose={this.props.onClose}>
                <Modal.Content>
                    <StyledSearchContainer>
                        <Search
                            fluid
                            loading={loading}
                            onResultSelect={this.handleResultSelect}
                            onSearchChange={this.handleSearchChange}
                            results={results}
                            value={value}
                        />
                    </StyledSearchContainer>
                </Modal.Content>
            </Modal>
        );
    }
}

export default withRouter(SearchModal);
