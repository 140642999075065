// modules
import React, { Component } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
// assets
// styles
import { Container, Header, Table } from 'semantic-ui-react';
// components
// redux

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://workternship.com';

class LessonAnalytics extends Component {
  state = {
    data: [],
    userFiles: [],
    column: null,
    direction: null
  };

  componentDidMount() {
    const data = [];
    const userFiles = [];

    for (let i = 0; i < this.props.answers.length; i++) {
      const item = {};

      const block = this.props.blocks.find(x => x._id === this.props.answers[i]._id);
      if (block) {
        if (block.correctAnswer) {
          item['correctAnswer'] = block.correctAnswer;
        } else {
          item['correctAnswer'] = 'нет верного ответа';
        }
      } else {
        item['correctAnswer'] = '---';
      }
      item['question'] = `${i + 1}`;
      item['answer'] = this.props.answers[i].answer;

      item['correct'] = this.props.answers[i].correct === false ? 'нет' : this.props.answers[i].correct === true ? 'да' : '---';
      item['time'] = this.props.answers[i].time;
      data.push(item);
    }

    for (let i = 0; i < this.props.userFiles.length; i++) {
      const item = {};

      const blockIndex = this.props.blocks.findIndex(x => x._id === this.props.userFiles[i]._id);
      if (blockIndex !== -1) {
        item['blockIndex'] = blockIndex;
      } else {
        item['correctAnswer'] = '';
      }
      item['filename'] = this.props.userFiles[i].name;
      item['path'] = `${baseUrl}${this.props.userFiles[i].path}`;
      userFiles.push(item);
    }

    this.setState({ data, userFiles });
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: sortBy(data, [clickedColumn]),
        direction: 'ascending'
      });
      return;
    }
    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    });
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <Container>
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={column === 'number' ? direction : null} onClick={this.handleSort('number')}>
                № задания
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'correctAnswer' ? direction : null}
                onClick={this.handleSort('correctAnswer')}
              >
                Правильный ответ
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'answer' ? direction : null} onClick={this.handleSort('answer')}>
                Ответ ученика
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'correct' ? direction : null} onClick={this.handleSort('correct')}>
                Ответ верный?
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'time' ? direction : null} onClick={this.handleSort('time')}>
                Время на вопрос (секунд)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {map(data, (lesson, index) => (
              <Table.Row
                key={`admin-analytics-user-course-${this.props.courseId}-lesson-${this.props.lessonId}-detailed-table-row-${index}`}
              >
                <Table.Cell>{lesson.question}</Table.Cell>
                <Table.Cell>{lesson.correctAnswer}</Table.Cell>
                <Table.Cell>{lesson.answer}</Table.Cell>
                <Table.Cell>{lesson.correct}</Table.Cell>
                <Table.Cell>{lesson.time}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Container>
          <Header textAlign="center" size="small">
            Загруженные файлы
          </Header>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>№ блока</Table.HeaderCell>
                <Table.HeaderCell>Название файла</Table.HeaderCell>
                <Table.HeaderCell>Ссылка на файл</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.userFiles.map((userFile, index) => (
                <Table.Row
                  key={`admin-analytics-user-course-${this.props.courseId}-lesson-${this.props.lessonId}-user-files${index}`}
                >
                  <Table.Cell>{userFile.blockIndex}</Table.Cell>
                  <Table.Cell>{userFile.filename}</Table.Cell>
                  <Table.Cell>
                    <a href={userFile.path} rel="noopener noreferrer" target="_blank">
                      {userFile.filename}
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      </Container>
    );
  }
}

export default LessonAnalytics;
