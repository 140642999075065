// modules
import React from "react";
import styled from "styled-components";
// assets
// styles
// components
// redux

const StyledIframeContainer = styled.div``;

const IframeBlock = (props) => {
    return (
        <StyledIframeContainer>
            <iframe
                title={`${props.src} iframe`}
                src={`/iframe/${props.src}/index.html`}
                width="100%"
                height="480"
                style={{ border: "none" }}
            />
        </StyledIframeContainer>
    );
};

export default IframeBlock;
