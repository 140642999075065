// modules
import React from "react";
import { Redirect, Route } from "react-router-dom";
// assets
// styles
// components
// redux

const ProtectedRoute = ({ component: Component, isAuthenticated, condition, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (isAuthenticated === true && condition ? <Component {...props} /> : <Redirect to="/" />)}
    />
);

export default ProtectedRoute;
