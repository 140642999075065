// modules
// imports
// action type
const TYPE = type => 'workternship/UI/' + type;
// axios promises

// action types
const TOGGLE_SIDEBAR_MENU_VISIBILITY = TYPE('TOGGLE_SIDEBAR_MENU_VISIBILITY');
const TOGGLE_FORMULAS_MODAL_VISIBILITY = TYPE('TOGGLE_FORMULAS_MODAL_VISIBILITY');
const TOGGLE_SEARCH_MODAL_VISIBILITY = TYPE('TOGGLE_SEARCH_MODAL_VISIBILITY');
const TOGGLE_TIMER_VISIBILITY = TYPE('TOGGLE_TIMER_VISIBILITY');
const SET_SIDEBAR_MENU_ITEMS = TYPE('SET_SIDEBAR_MENU_ITEMS');

// initial state
const initialState = {
  isSidebarMenuVisible: false,
  isFormulasModalVisible: false,
  isSearchModalVisible: false,
  isTimerVisible: false,
  sidebarMenuItems: []
};

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_SIDEBAR_MENU_VISIBILITY:
      return { ...state, isSidebarMenuVisible: action.payload };
    case TOGGLE_FORMULAS_MODAL_VISIBILITY:
      return { ...state, isFormulasModalVisible: action.payload };
    case TOGGLE_SEARCH_MODAL_VISIBILITY:
      return { ...state, isSearchModalVisible: action.payload };
    case TOGGLE_TIMER_VISIBILITY:
      return { ...state, isTimerVisible: action.payload };
    case SET_SIDEBAR_MENU_ITEMS:
      return { ...state, sidebarMenuItems: action.payload };
    default:
      return state;
  }
}

// action creators
export const toggleSidebarMenuVisibility = value => dispatch => {
  dispatch({
    type: TOGGLE_SIDEBAR_MENU_VISIBILITY,
    payload: value
  });
};

export const toggleFormulasModalVisibility = value => dispatch => {
  dispatch({
    type: TOGGLE_FORMULAS_MODAL_VISIBILITY,
    payload: value
  });
};

export const toggleSearchModalVisibility = value => dispatch => {
  dispatch({
    type: TOGGLE_SEARCH_MODAL_VISIBILITY,
    payload: value
  });
};

export const toggleTimerVisibility = value => dispatch => {
  dispatch({
    type: TOGGLE_TIMER_VISIBILITY,
    payload: value
  });
};

export const setSidebarMenuItems = items => dispatch => {
  dispatch({
    type: SET_SIDEBAR_MENU_ITEMS,
    payload: items
  });
};
