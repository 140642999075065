// modules
import styled from 'styled-components';
import media from 'styled-media-query';
// assets
// styles
// components
// redux

const StyledNavButton = styled.button`
    padding: 0px !important;
    position: fixed;
    z-index: 100;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    outline: none;
    cursor: pointer;

    ${media.lessThan('400px')`
        width: 36px;
        height: 36px;

        svg {
            width: 16px;
            height: 16px;
        }
    `}

    ${media.between('400px', '600px')`
        width: 48px;
        height: 48px;

        svg {
            width: 20px;
            height: 20px;
        }
    `}

    ${media.between('600px', '800px')`
        width: 56px;
        height: 56px;

        svg {
            width: 24px;
            height: 24px;
        }
    `}
        
    ${media.greaterThan('800px')`
        width: 64px;
        height: 64px;

        svg {
            width: 32px;
            height: 32px;
        }
    `}
`;

export default StyledNavButton;
