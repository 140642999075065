// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import styled from "styled-components";
import media from "styled-media-query";
// assets
import menu from "assets/images/menu.svg";
// styles
import "./index.css";
// components
// import { Dropdown } from "semantic-ui-react";
import SidebarMenu from "./SidebarMenu";
import StyledNavButton from "components/StyledNavButton";
import Profile from "./Profile";
import CoursesList from "./CoursesList";
import Course from "./Course";
import Payment from "./Payment";
// redux
import { setLocale } from "store/Config";
import { toggleSidebarMenuVisibility } from "../../store/UI";
import { logout } from "store/User";

const StyledMenuButton = styled(StyledNavButton)`
    border: 2px solid #000;

    ${media.lessThan("400px")`
        right: 8px;
        bottom: 6px;

        img {
            width: 16px;
            height: 16px;
        }
    `}

    ${media.between("400px", "600px")`
        right: 12px;
        bottom: 8px;

        img {
            width: 24px;
            height: 24px;
        }
    `}

    ${media.between("600px", "800px")`
        right: 16px;
        top: 16px;

        img {
            width: 28px;
            height: 28px;
        }
    `}

    ${media.greaterThan("800px")`
        right: 24px;
        top: 24px;

        img {
            width: 32px;
            height: 32px;
        }
    `}
`;

class Dashboard extends Component {
    state = {
        loading: false,
        isSidebarMenuVisible: false,
    };

    toggleSidebarVisibility = (value) => {
        this.setState({ isSidebarMenuVisible: value });
    };

    handleLocale = (locale) => {
        // set locale
        this.props.setLocale(locale);
        // refresh page
        window.location.reload();
    };

    render() {
        const { locale, t } = this.props;

        return (
            <DocumentTitle title={t("dashboard.title")}>
                <div className="dashboard">
                    <SidebarMenu />

                    <StyledMenuButton
                        circular
                        id="menu-button"
                        onClick={() => this.props.toggleSidebarMenuVisibility(true)}
                    >
                        <img src={menu} alt="" />
                    </StyledMenuButton>

                    {/* <Dropdown className="dashboard-change-locale" selection text={t("interfaceLanguage")}>
                        <Dropdown.Menu>
                            <Dropdown.Item active={locale === "ru"} onClick={() => this.handleLocale("ru")}>
                                {t("ru")}
                            </Dropdown.Item>
                            <Dropdown.Item active={locale === "kk"} onClick={() => this.handleLocale("kk")}>
                                {t("kk")}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}

                    <Switch>
                        <Route exact path="/dashboard/profile" component={Profile} />
                        <Route exact path="/dashboard/payment" component={Payment} />
                        <Route exact path="/dashboard/courses" component={CoursesList} />
                        <Route path="/dashboard/courses/:courseId" component={Course} />
                        <Redirect to="/dashboard/courses" />
                    </Switch>
                </div>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    locale: state.config.locale,
});

const mapDispatchToProps = (dispatch) => ({
    setLocale: (locale) => dispatch(setLocale(locale)),
    toggleSidebarMenuVisibility: (value) => dispatch(toggleSidebarMenuVisibility(value)),
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Dashboard));
