// modules
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { Element } from "react-scroll";
// assets
import star_yellow from "assets/images/star_yellow.svg";
import star_black from "assets/images/star_black.svg";
// styles
import { Image } from "semantic-ui-react";
// components
// redux

const StyledStarsContainer = styled(Element)`
    display: flex;
    flex-flow: row;
    justify-content: center;
    
    ${media.lessThan("400px")`
        margin-bottom: 2em;
    `}

    ${media.between("400px", "600px")`
        margin-bottom: 2em;
    `}

    ${media.between("600px", "800px")`
        margin-bottom: 3em;
    `}

    ${media.greaterThan("800px")`
        margin-bottom: 3em;
    `}
`;

const StyledStar = styled(Image)`
    opacity: 1;
    -webkit-animation-name: star-animation;
    -webkit-animation-duration: 5s;
    animation-name: star-animation;
    animation-duration: 5s;

    @-webkit-keyframes star-animation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes star-animation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    ${media.lessThan("400px")`
        margin: 0 8px;
        width: 24px;
        height: 24px;
    `}

    ${media.between("400px", "600px")`
        margin: 0 8px;
        width: 32px;
        height: 32px;  
    `}

    ${media.between("600px", "800px")`
        margin: 0 12px;
        width: 40px;
        height: 40px;
    `}

    ${media.greaterThan("800px")`
        margin: 0 16px;
        width: 48px;
        height: 48px;
    `}
`;

const StyledSingleStar = styled(StyledStar)`
    ${media.lessThan("400px")`
        width: 48px;
        height: 48px;
    `}

    ${media.between("400px", "600px")`
        width: 56px;
        height: 56px;
    `}

    ${media.between("600px", "800px")`
        width: 64px;
        height: 64px;
    `}

    ${media.greaterThan("800px")`
        width: 80px;
        height: 80px;
    `}
`;

const StarsComponent = (props) => {
    const stars = [];

    if (props.questions !== 0) {
        for (let i = 0; i < props.questions; i++) {
            if (i < props.correct) {
                stars.push(<StyledStar key={`stars-component-star-${i}`} src={star_yellow} />);
            } else {
                stars.push(<StyledStar key={`stars-component-star-${i}`} src={star_black} />);
            }
        }
    } else {
        stars.push(<StyledSingleStar key={`stars-component-star-0`} src={star_yellow} />);
    }

    return <StyledStarsContainer name={props.name}>{stars}</StyledStarsContainer>;
};

export default StarsComponent;
