// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import axios from "axios";
// assets
import { formatSeconds } from "assets/formatUtils";
import { dashboardRoutes } from "assets/routes";
import { scrollToElement } from "utils/scroll";
// styles
import { Loader, Modal, Table } from "semantic-ui-react";
import "./index.css";
// components
// redux

class LessonStatsModal extends Component {
    state = {
        betterThan: "",
        loading: false,
        loadingBetterThan: false,
    };

    componentDidMount() {
        if (this.props.demo) {
            this.fetchIntroBetterThan(this.props.courseId);
            this.fetchIntroQuestionBlocks(this.props.courseId);
        } else {
            this.fetchBetterThan(this.props.courseId, this.props.lessonId);
            this.fetchQuestionBlocks(this.props.courseId, this.props.lessonId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lessonId !== this.props.lessonId) {
            if (this.props.demo) {
                this.fetchIntroBetterThan(this.props.courseId);
                this.fetchIntroQuestionBlocks(this.props.courseId);
            } else {
                this.fetchBetterThan(this.props.courseId, this.props.lessonId);
                this.fetchQuestionBlocks(this.props.courseId, this.props.lessonId);
            }
        }
    }

    fetchQuestionBlocks = async (courseId, lessonId) => {
        this.setState({ loading: true });
        await axios
            .get(dashboardRoutes.fetchLesson(courseId, lessonId))
            .then((response) => {
                let blocks = response.data.message.lesson.blocks || [];
                let questionBlocks = response.data.message.userLesson.answers || [];
                for (let i = 0; i < questionBlocks.length; i++) {
                    let found = blocks.find((x) => x._id === questionBlocks[i]._id);
                    if (!!found && found.blockType === "indefiniteQuestion") {
                        questionBlocks[i].correct = undefined;
                    }
                    if (!!found) {
                        questionBlocks[i].testQuestion = !!found.testQuestion;
                    }
                }

                // set lesson
                this.setState({ loading: false, questionBlocks: questionBlocks });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    fetchIntroQuestionBlocks = async (courseId) => {
        this.setState({ loading: true });
        await axios
            .get(dashboardRoutes.fetchIntroLesson(courseId))
            .then((response) => {
                let blocks = response.data.message.lesson.blocks || [];
                let questionBlocks = response.data.message.userLesson.answers || [];
                for (let i = 0; i < questionBlocks.length; i++) {
                    let found = blocks.find((x) => x._id === questionBlocks[i]._id);
                    if (!!found && found.blockType === "indefiniteQuestion") {
                        questionBlocks[i].correct = undefined;
                    }
                    if (!!found) {
                        questionBlocks[i].testQuestion = !!found.testQuestion;
                    }
                }

                // set lesson
                this.setState({ loading: false, questionBlocks: questionBlocks });
            })
            .catch((error) => {
                console.error(error);
            });
    };

    fetchBetterThan = async (courseId, lessonId) => {
        this.setState({ loadingBetterThan: true });
        let response = await axios.get(dashboardRoutes.fetchBetterThan(courseId, lessonId)).catch((error) => {
            console.error("Error fetching results\n", error);
            this.setState({ loadingBetterThan: false });
        });
        if (response) {
            let betterThan = 0;
            let results = response.data.message.filter((x) => x.userId !== this.props.userId);
            let answers = results.length;
            if (answers > 0) {
                let moreThan = results.filter((x) => this.props.correct >= x.correct).length;
                betterThan = Math.floor((moreThan * 100) / answers);
            } else {
                betterThan = 100;
            }

            // set "better than" results
            this.setState({ loadingBetterThan: false, betterThan: betterThan });
        }
    };

    fetchIntroBetterThan = async (courseId) => {
        this.setState({ loadingBetterThan: true });
        let response = await axios.get(dashboardRoutes.fetchIntroBetterThan(courseId)).catch((error) => {
            console.error("Error fetching results\n", error);
            this.setState({ loadingBetterThan: false });
        });
        if (response) {
            let betterThan = 0;
            let results = response.data.message.filter((x) => x.userId !== this.props.userId);
            let answers = results.length;
            if (answers > 0) {
                let moreThan = results.filter((x) => x.correct < this.props.correct).length;
                betterThan = Math.floor((moreThan * 100) / answers);
            } else {
                betterThan = 100;
            }

            // set "better than" results
            this.setState({ loadingBetterThan: false, betterThan: betterThan });
        }
    };

    render() {
        const { open, t } = this.props;
        let smallTable = this.props.innerWidth < 800;
        let totalTime = 0;
        let tableRows = [];
        // sum up total time and get individual questions
        if (this.state.questionBlocks && this.state.questionBlocks.length !== 0) {
            for (let i = 0; i < this.state.questionBlocks.length; i++) {
                let question = this.state.questionBlocks[i];

                // skip indefinite answer questions
                if (question.correct === undefined) {
                    continue;
                }

                if (question.time) {
                    totalTime += question.time;
                }
                let questionResult;
                if (question.correct === undefined) {
                    questionResult = (
                        <b className="dashboard-lesson-footer-questions-indefinite">
                            {t("dashboard.course.lesson.footerstats.indefinite")}
                        </b>
                    );
                } else if (question.correct === true) {
                    questionResult = (
                        <b className="dashboard-lesson-footer-questions-correct">
                            {t("dashboard.course.lesson.footerstats.correct")}
                        </b>
                    );
                } else if (question.correct === false) {
                    questionResult = (
                        <b className="dashboard-lesson-footer-questions-wrong">
                            {t("dashboard.course.lesson.footerstats.wrong")}
                        </b>
                    );
                }
                let section = this.props.section;
                if (this.props.demo) {
                    section = "0";
                }
                tableRows.push(
                    <Table.Row key={`dashboard-lesson-footer-question-${i}`}>
                        <Table.Cell>
                            <b>{`${
                                smallTable ? t("dashboard.course.lesson.footerstats.indefinite") + ": " : ""
                            }${section}.${i + 1}`}</b>
                        </Table.Cell>
                        <Table.Cell>
                            <b
                                onClick={() =>
                                    scrollToElement(`lesson-${this.props.lessonId}-question-${question._id}`, "linear")
                                }
                                style={{ cursor: "pointer", color: "#3c80c0" }}
                            >
                                <i>{t("dashboard.course.lesson.footerstats.task")}</i>
                            </b>
                        </Table.Cell>
                        <Table.Cell>
                            {smallTable ? t("dashboard.course.lesson.footerstats.result") + ": " : ""}
                            {questionResult}
                        </Table.Cell>
                        <Table.Cell>{`${
                            smallTable ? t("dashboard.course.lesson.footerstats.indefinite") + ": " : ""
                        }${formatSeconds(question.time)}`}</Table.Cell>
                    </Table.Row>
                );
            }
        }

        return (
            <Modal closeIcon open={open} onClose={this.props.onClose}>
                <div className="dashboard-lesson-footer-wrapper">
                    <div className="dashboard-lesson-footer-header" style={{ marginBottom: "2em", fontWeight: 500 }}>
                        {t("dashboard.course.lesson.footerstats.results")}
                    </div>
                    {this.state.loadingBetterThan ? (
                        <Loader active inline="centered" size="large" style={{ marginBottom: "24px" }} />
                    ) : // <div className="dashboard-lesson-footer-header">
                    //     {t("dashboard.course.lesson.footerstats.better")}: {this.state.betterThan}%
                    // </div>
                    null}
                    <div className="dashboard-lesson-footer-stats">
                        <p className="dashboard-lesson-footer-stats-right-text">
                            {t("dashboard.course.lesson.footerstats.correctAnswers")}
                        </p>
                        <p className="dashboard-lesson-footer-stats-total-text">
                            {t("dashboard.course.lesson.footerstats.questions")}
                        </p>
                        <p className="dashboard-lesson-footer-stats-time-text">
                            {t("dashboard.course.lesson.footerstats.totalTime")}
                        </p>
                        <p className="dashboard-lesson-footer-stats-right-number">{this.props.correct}</p>
                        <p className="dashboard-lesson-footer-stats-total-number">{this.props.questions}</p>
                        <p className="dashboard-lesson-footer-stats-time-number">
                            {totalTime} {t("dashboard.course.lesson.footerstats.seconds")}
                        </p>
                    </div>
                    {this.state.loading ? (
                        <Loader active inline="centered" size="large" style={{ marginBottom: "24px" }} />
                    ) : smallTable ? (
                        <div className="dashboard-lesson-footer-questions">
                            <Table basic="very" size="small">
                                <Table.Body>{tableRows}</Table.Body>
                            </Table>
                        </div>
                    ) : (
                        <Table basic="very" size="small">
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>№</Table.Cell>
                                    <Table.Cell>{t("dashboard.course.lesson.footerstats.question")}</Table.Cell>
                                    <Table.Cell>{t("dashboard.course.lesson.footerstats.result")}</Table.Cell>
                                    <Table.Cell>{t("dashboard.course.lesson.footerstats.time")}</Table.Cell>
                                </Table.Row>
                                {tableRows}
                            </Table.Body>
                        </Table>
                    )}
                    {/* <div className="dashboard-lesson-footer-extra">
                        <div className="dashboard-lesson-footer-extra-header">
                            {t("dashboard.course.lesson.footerstats.extra")}:
                        </div>
                        {this.props.extraMaterial ? ReactHtmlParser(this.props.extraMaterial) : null}
                    </div> */}
                </div>
            </Modal>
        );
    }
}

export default connect(
    (store) => ({
        userId: store.user.user._id,
    }),
    {}
)(withTranslation()(LessonStatsModal));
