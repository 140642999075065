// modules
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { withTranslation } from "react-i18next";
// assets
import { dashboardRoutes } from "assets/routes";
// styles
import { Button, Form, Statistic } from "semantic-ui-react";
import "../Question/index.css";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
import CustomAudioPlayer from "components/CustomAudioPlayer";
// redux

class IndefiniteQuestion extends Component {
    state = {
        answer: this.props.block.answer ? JSON.parse(this.props.block.answer) : "",
        answered: !!this.props.block.answer,
        elapsed: 0,
        showHint: false,
        testQuestion: this.props.block.testQuestion,
    };

    componentDidMount() {
        if (!this.state.answered) {
            // start the timer
            this.timer = setInterval(this.tick, 1000);
            this.start = new Date();
            // set answering to false (for parent Lesson component)
            this.props.handleAnswering(true);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    // tick-tack time is running
    tick = () => {
        this.setState({
            elapsed: Math.round((new Date() - this.start) / 1000),
        });
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    handleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    };

    handleAnswer = () => {
        // stop timer
        clearInterval(this.timer);
        // pick necessary fields and correct answer
        let courseId = this.props.courseId;
        let lessonId = this.props.lessonId;
        let blockId = this.props.block._id;
        let correct = undefined;
        let { answer, elapsed } = this.state;
        // set answering to false (for parent Lesson component)
        this.props.handleAnswering(false);
        // mark time (for parent Lesson component)
        this.props.handleMarkTime(this.props.index, elapsed);
        // answer payload
        const payload = {
            answer: JSON.stringify(answer),
            correct: correct,
            time: elapsed,
        };
        // axios call
        let answerRoute = dashboardRoutes.answer(courseId, lessonId, blockId);
        if (lessonId === "demo") {
            answerRoute = dashboardRoutes.answerIntro(courseId, blockId);
        }
        axios.post(answerRoute, payload).then(() => {
            this.setState({ answered: true, correct: correct });
        });
    };

    render() {
        const { t } = this.props;

        let answerButton = (
            <Form.Button
                size={
                    this.props.innerWidth > 700
                        ? "large"
                        : this.props.innerWidth > 600
                        ? "medium"
                        : this.props.innerWidth > 500
                        ? "tiny"
                        : "mini"
                }
                type="submit"
            >
                {t("dashboard.course.lesson.question.answer")}
            </Form.Button>
        );

        let header = this.props.section
            ? `${t("dashboard.course.lesson.question.task")} ${this.props.section}.${this.props.questionNumber}`
            : `${t("dashboard.course.lesson.question.task")} ${this.props.questionNumber}`;
        let hint;
        let question;
        let explanation;
        // display hint
        if (this.props.block.hint) {
            hint = (
                <div className="dashboard-lesson-block-question-hint">
                    <Button
                        className="dashboard-lesson-block-question-hint-button"
                        onClick={this.handleHint}
                        size={
                            this.props.innerWidth > 700
                                ? "large"
                                : this.props.innerWidth > 600
                                ? "medium"
                                : this.props.innerWidth > 500
                                ? "tiny"
                                : "mini"
                        }
                    >
                        {this.state.showHint
                            ? t("dashboard.course.lesson.question.hideHint")
                            : t("dashboard.course.lesson.question.showHint")}
                    </Button>
                    {this.state.showHint ? (
                        <div className="dashboard-lesson-block-question-hint-text">
                            {ReactHtmlParser(this.props.block.hint)}
                        </div>
                    ) : null}
                </div>
            );
        }
        // display explanataion for answered question
        if (this.state.answered && !this.state.testQuestion) {
            // explanation text
            let explanationText = "";

            // replace name
            if (this.props.block.explanationText) {
                explanationText = this.props.block.explanationText;
                explanationText = explanationText.replace("$$placeholder$$", this.props.nickname);
            }
            // replace time
            if (this.props.block.time) {
                explanationText = explanationText.replace("$$time$$", this.props.block.time);
            }
            // replace rec time
            if (this.props.block.recTime) {
                explanationText = explanationText.replace("$$rec_time$$", this.props.block.recTime);
            }

            if (explanationText) {
                explanation = (
                    <div
                        className="dashboard-lesson-block-question-explanation-indefinite"
                        id={`lesson-${this.props.id}-question-${this.props.block._id}-explanation`}
                    >
                        {ReactHtmlParser(explanationText)}
                    </div>
                );
            }
        }

        // question form
        question = (
            <Form autoComplete="off" className="dashboard-lesson-block-question-form" onSubmit={this.handleAnswer}>
                <div className="dashboard-lesson-block-question-form-instruction">
                    {t("dashboard.course.lesson.question.instruction")}
                </div>
                <Form.TextArea
                    className="dashboard-lesson-block-question-form-input"
                    name="answer"
                    required
                    placeholder={t("dashboard.course.lesson.question.placeholder")}
                    readOnly={this.state.answered}
                    rows={5}
                    size="large"
                    value={this.state.answer}
                    onChange={this.handleChange}
                />
                {this.state.answered ? null : this.props.showTimer ? (
                    <div>
                        <Statistic className="dashboard-lesson-block-question-form-time" floated="right" size="tiny">
                            <Statistic.Label>{t("dashboard.course.lesson.question.time")}</Statistic.Label>
                            <Statistic.Value>{this.state.elapsed}</Statistic.Value>
                        </Statistic>
                        {answerButton}
                    </div>
                ) : (
                    <div>{answerButton}</div>
                )}
            </Form>
        );

        return (
            <div
                className="dashboard-lesson-block-question"
                id={`lesson-${this.props.id}-question-${this.props.block._id}`}
            >
                <div className="dashboard-lesson-block-question-header">{header}</div>
                <div className="dashboard-lesson-block-question-text">
                    <MathjaxWrapper text={ReactHtmlParser(this.props.text)} />
                </div>
                {this.props.block.audio && <CustomAudioPlayer src={this.props.block.audio} />}
                {hint}
                {question}
                {explanation}
            </div>
        );
    }
}

export default withTranslation()(IndefiniteQuestion);
