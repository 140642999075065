// modules
import React, { Component } from "react";
// assets
import { bold } from "assets/formatUtils";
// styles
// components
import Editor from "../../../../Editor";
// redux

class TextBlock extends Component {
    render() {
        return (
            <div>
                <div style={{ marginBottom: "2em" }}>
                    {bold("Текст в блоке:")}
                    <Editor
                        html={this.props.block.text || ""}
                        onChange={this.props.onTextChange}
                    />
                </div>
            </div>
        );
    }
}

export default TextBlock;
