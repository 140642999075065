import store from "../store/Store";
import { setLocale } from "../store/Config";
import { logout } from "../store/User";

// get selected text
export const getSelectionText = () => {
    var text = "";
    if (window.getSelection) {
        text = window.getSelection().toString();
    }
    return text;
};

// text chunk selection event listener
// document.onmouseup = document.onkeyup = document.onselectionchange = function(event) {
//     let text = getSelectionText();
//     if (event.type === "selectionchange") {
//         window.selectedText = true;
//     }
//     if (event.type === "mouseup" && window.selectedText && text.length > 50) {
//         window.selectedText = false;
//         console.log("Selected text:", text);
//         window.prompt(`Добавить следующий текст в закладки?\n${text}`);
//     }
// };

// redirect to a given path using specified context (for router)
export const redirect = (context, path) => {
    context.props.history.push(path);
};

// set locale and reload page
export const changeLocale = (locale) => {
    // dispatch set locale action
    store.dispatch(setLocale(locale));
    // refresh page
    window.location.reload();
};

// logout and scroll to page top
export const logoutAndScrollToTop = (...next) => {
    // dispatch logout action
    store.dispatch(logout());
    // scroll to page top
    window.scrollTo(0, 0);
};
