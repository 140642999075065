// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import DocumentTitle from "react-document-title";
// assets
import { header, property } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
// styles
import { Button, Container, Icon, Image, List, Loader } from "semantic-ui-react";
import "./index.css";
// components
// redux
import { logout } from "store/User";

const button = (disabled, loading, backgroundColor, onClick, text) => (
    <Button
        disabled={disabled}
        loading={loading}
        style={{
            backgroundColor: backgroundColor,
            color: "#fff",
            borderRadius: "0px",
            marginBottom: "8px",
        }}
        onClick={onClick}
    >
        {text}
    </Button>
);

const containsCourse = (authoredCourses, course) => {
    for (let authoredCourse of authoredCourses) {
        if (course._id === authoredCourse || course._id === authoredCourse._id) {
            return true;
        }
    }
    return false;
};

class Author extends Component {
    state = {
        loading: false,
        editing: false,
        deleting: false,
        author: {
            authoredCourses: [],
        },
        courses: [],
    };

    componentDidMount() {
        const email = this.props.match.params.email;
        this.fetchAuthor(email);
        this.fetchCourses();
    }

    fetchAuthor = (email) => {
        this.setState({ loading: true });
        axios.get(adminRoutes.authors.getAuthor(email)).then(
            (response) => {
                this.setState({ loading: false, author: response.data.message });
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    fetchCourses = () => {
        this.setState({ loading: true });
        axios.get(adminRoutes.courses.getCourses()).then(
            (response) => {
                this.setState({ loading: false, courses: response.data.message });
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    addAuthoredCourse = (id) => {
        const authoredCourses = [...this.state.author.authoredCourses, id];

        this.setState((prevState) => ({
            ...prevState,
            author: {
                ...prevState.author,
                authoredCourses: authoredCourses,
            },
        }));
    };

    deleteAuthorCourse = (id) => {
        const authoredCourses = this.state.author.authoredCourses.filter((x) => x !== id);

        this.setState((prevState) => ({
            ...prevState,
            author: {
                ...prevState.author,
                authoredCourses: authoredCourses,
            },
        }));
    };

    handleChange = (e, data) => {
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                [data.name]: data.value,
            },
        }));
    };

    handleEdit = () => {
        const r = window.confirm("Редактировать этого автора?");
        if (r) {
            this.setState({ editing: true });
            axios
                .post(adminRoutes.authors.editAuthor(this.state.author.email), {
                    authoredCourses: this.state.author.authoredCourses,
                })
                .then(
                    () => {
                        this.setState({ editing: false });
                        this.fetchAuthor(this.state.author.email);
                    },
                    (error) => {
                        console.error("Error:", error);
                        this.setState({ editing: false });
                    }
                );
        }
    };

    handleDelete = () => {
        const r = window.confirm("Удалить этого автора?");
        if (r) {
            this.setState({ deleting: true });
            axios.delete(adminRoutes.users.deleteUser(this.state.author.email)).then(
                () => {
                    this.setState({ deleting: false });
                    this.props.history.push("/admin/authors");
                },
                (error) => {
                    console.error("Error:", error);
                    this.setState({ deleting: false });
                }
            );
        }
    };

    render() {
        const { email, firstName, lastName } = this.state.author;

        const name = `${lastName} ${firstName}`;
        const courseItems = [];

        for (let i = 0; i < this.state.courses.length; i++) {
            const course = this.state.courses[i];
            const isCourseAuthored = containsCourse(this.state.author.authoredCourses, course);

            courseItems.push(
                <List.Item key={`admin-author-courses-${i}`}>
                    <List.Content floated="right">
                        <Button
                            icon
                            labelPosition="right"
                            positive={!isCourseAuthored}
                            negative={isCourseAuthored}
                            onClick={
                                isCourseAuthored
                                    ? () => this.deleteAuthorCourse(course._id)
                                    : () => this.addAuthoredCourse(course._id)
                            }
                        >
                            {isCourseAuthored ? <Icon name="remove" /> : <Icon name="add" />}
                            {isCourseAuthored ? "Удалить" : "Добавить"}
                        </Button>
                    </List.Content>
                    <Image avatar src={course.cover} />
                    <List.Content style={{ fontWeight: isCourseAuthored ? 600 : 400 }}>{course.name}</List.Content>
                </List.Item>
            );
        }

        return (
            <DocumentTitle title="Автор">
                {this.state.loading ? (
                    <Loader active size="large" />
                ) : (
                    <Container className="admin-author-container">
                        {header(name, "24px", 600)}
                        {property("Полное имя", name)}
                        {property("Логин", email)}

                        {header("Доступные курсы", "20px", 600)}
                        <List divided relaxed="very" verticalAlign="middle" style={{ marginBottom: "2em" }}>
                            {courseItems}
                        </List>

                        {button(false, this.state.editing, "#254441", this.handleEdit, "Редактировать")}
                        {button(false, this.state.deleting, "#cc515d", this.handleDelete, "Удалить")}
                    </Container>
                )}
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Author);
