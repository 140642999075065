// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import omit from "lodash/omit";
import pick from "lodash/pick";
// assets
import { bold, header, property } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
import { blockTypes } from "assets/strings";
// styles
import {
    Accordion,
    Button,
    Checkbox,
    Container,
    Dropdown,
    Form,
    Header,
    Icon,
    Input,
    List,
    Loader,
    Message,
} from "semantic-ui-react";
// components
import Block from "../Block";
import ExtraMaterial from "../ExtraMaterial";
// redux
import { logout } from "store/User";

const loader = (
    <Loader active size="large">
        Загрузка урока
    </Loader>
);

const redirect = (context, path) => {
    context.props.history.push(path);
};

class Lesson extends Component {
    state = {
        adding: false,
        deleting: false,
        editing: false,
        loading: false,
        position: 0,
        practicePosition: 0,
        lessonsOptions: [],
        newKeyword: "",
        dialoguePersons: []
    };

    componentDidMount() {
        const { courseId, lessonId } = this.props.match.params;

        this.setState(
            {
                courseId: courseId,
                lessonId: lessonId,
            },
            () => {
                // get lesson
                this.fetchLesson(lessonId);
                // get dialogue persons
                this.fetchDialoguePersons();
            }
        );
    }

    fetchCourse = (courseId) => {
        this.setState({ loading: true });
        axios.get(adminRoutes.courses.getCourse(courseId)).then(
            (response) => {
                let lessons = response.data.message.lessons.filter((lesson) => lesson._id !== this.state.lessonId);
                let lessonsOptions = lessons.map((option, index) => ({
                    text: option.name,
                    value: index,
                }));
                lessonsOptions.splice(0, 0, {
                    text: "Нет",
                    value: -1,
                });

                this.setState({
                    loading: false,
                    lessons: lessons,
                    lessonsOptions: lessonsOptions,
                });
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    fetchLesson = (lessonId) => {
        this.setState({ loading: true });
        axios.get(adminRoutes.lessons.getLesson(lessonId)).then(
            (response) => {
                const lesson = omit(response.data.message, ["blocks"]);
                const blocks = pick(response.data.message, ["blocks"]).blocks;

                // build block position options
                this.positions = [{ text: "В начало", value: 0 }];
                if (blocks.length > 0) {
                    this.positions.push({ text: "В конец", value: blocks.length });
                }
                for (let i = 0; i < blocks.length - 1; i++) {
                    this.positions.push({
                        text: `После ${i + 1} блока`,
                        value: i + 1,
                    });
                }

                // set state
                this.setState({
                    loading: false,
                    lesson: lesson,
                    blocks: blocks,
                    position: blocks.length,
                });

                // fetch course lessons
                this.fetchCourse(this.state.courseId);
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    fetchDialoguePersons = () => {
        this.setState({ loading: true });
        axios.get(adminRoutes.dialoguePersons.getDialoguePersons()).then(
            (response) => {
                this.setState({
                    dialoguePersons: response.data.message
                });
            },
            (error) => {
                this.setState({ loading: false });
                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    }

    editLesson = () => {
        const r = window.confirm("Редактировать описание этого урока?");
        if (r) {
            this.setState({ editing: true });
            const lesson = pick(this.state.lesson, [
                "name",
                "displayName",
                "type",
                "hidden",
                "section",
                "nextLesson",
                "prevLesson",
                "keywords",
            ]);
            axios.post(adminRoutes.lessons.editLesson(this.state.lesson._id), { lesson: lesson }).then(
                () => {
                    this.setState({ editing: false });
                    this.fetchLesson(this.state.lesson._id);
                },
                () => {
                    this.setState({ editing: false });
                    alert("Ошибка редактирования урока");
                }
            );
        }
    };

    deleteLesson = () => {
        const r = window.confirm("Удалить этот урок?");
        if (r) {
            this.setState({ deleting: true });
            axios.delete(adminRoutes.lessons.deleteLesson(this.state.lesson._id)).then(
                () => {
                    this.setState({ deleting: false });
                    this.props.history.push("/admin/lessons");
                },
                () => {
                    this.setState({ deleting: false });
                    alert("Ошибка удаления урока");
                }
            );
        }
    };

    addBlock = (block) => {
        const r = window.confirm("Добавить новый блок?");

        if (r) {
            this.setState({ adding: true });
            const payload = {
                block: block,
                position: this.state.position,
            };
            // axios call
            axios.post(adminRoutes.lessons.addBlock(this.state.lesson._id), payload).then(
                () => {
                    this.fetchLesson(this.state.lesson._id);
                    this.setState({ adding: false });
                },
                () => {
                    this.setState({ adding: false });
                }
            );
        }
    };

    addKeyword = () => {
        if (
            this.state.lesson &&
            this.state.lesson.keywords &&
            this.state.lesson.keywords.length !== 0 &&
            this.state.lesson.keywords.includes(this.state.newKeyword)
        ) {
            return;
        }

        const keywords =
            this.state.lesson && this.state.lesson.keywords
                ? [...this.state.lesson.keywords, this.state.newKeyword]
                : [];

        this.setState((prevState) => ({
            lesson: {
                ...prevState.lesson,
                keywords: keywords,
            },
            newKeyword: "",
        }));
    };

    deleteKeyword = (value) => {
        const keywords =
            this.state.lesson && this.state.lesson.keywords
                ? this.state.lesson.keywords.filter((x) => x !== value)
                : [];

        this.setState((prevState) => ({
            lesson: {
                ...prevState.lesson,
                keywords: keywords,
            },
        }));
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    handleChangeLesson = (e, data) => {
        let result = data.value;
        if (data.name === "hidden") {
            result = data.checked;
        }
        if (data.name === "nextLesson" || data.name === "prevLesson") {
            if (data.value === -1) {
                result = {
                    _id: "",
                    id: null,
                    name: "",
                };
            } else {
                let lesson = this.state.lessons[data.value];
                result = {
                    _id: lesson._id,
                    id: lesson.id,
                    name: lesson.name,
                };
            }
        }
        this.setState((prevState) => ({
            lesson: {
                ...prevState.lesson,
                [data.name]: result,
            },
        }));
    };

    handleNewBlock = (block) => {
        this.setState(
            {
                block: block,
            },
            () => {
                this.addBlock(block);
            }
        );
    };

    render() {
        let keywords = [];
        let blocks = [];
        let lesson;
        let name;

        if (this.state.lesson) {
            name = {
                section: this.state.lesson.section,
                name: this.state.lesson.name,
            };

            // lesson keywords
            if (this.state.lesson.keywords && this.state.lesson.keywords.length !== 0) {
                for (let i = 0; i < this.state.lesson.keywords.length; i++) {
                    const keyword = this.state.lesson.keywords[i];

                    keywords.push(
                        <List.Item key={`admin-lesson-keywords-${i}`}>
                            <List.Content style={{ display: "flex", flexDirection: "row" }}>
                                <span>{keyword}</span>
                                <Icon
                                    name="remove"
                                    onClick={() => this.deleteKeyword(keyword)}
                                    style={{ cursor: "pointer", marginLeft: "8px" }}
                                />
                            </List.Content>
                        </List.Item>
                    );
                }
            }

            // lesson blocks
            if (this.state.blocks && this.state.blocks.length !== 0) {
                for (let i = 0; i < this.state.blocks.length; i++) {
                    blocks.push({
                        key: `admin-lesson-block-${i}`,
                        title: `Блок ${i + 1} (${blockTypes[this.state.blocks[i].blockType]})`,
                        content: {
                            content: (
                                <Block
                                    block={this.state.blocks[i]}
                                    index={i}
                                    lessonId={this.state.lesson._id}
                                    dialoguePersons={this.state.dialoguePersons}
                                    fetchLesson={this.fetchLesson}
                                />
                            ),
                        },
                    });
                }
            }

            lesson = (
                <div style={{ fontSize: "15px" }}>
                    <Button basic color="blue" onClick={() => redirect(this, `/admin/courses/${this.state.courseId}`)}>
                        Вернуться к курсу
                    </Button>

                    <Header size="large" textAlign="center">
                        {name.name}
                    </Header>

                    {bold("Название урока")}
                    <Input
                        fluid
                        name="name"
                        placeholder="Название урока"
                        value={this.state.lesson.name}
                        onChange={this.handleChangeLesson}
                        style={{ marginBottom: "1em" }}
                    />

                    {bold("Краткое название урока")}
                    <Input
                        fluid
                        name="displayName"
                        placeholder="Краткое название урока"
                        value={this.state.lesson.displayName}
                        onChange={this.handleChangeLesson}
                        style={{ marginBottom: "1em" }}
                    />

                    {/* {bold("Секция")}
                    <Input
                        fluid
                        name="section"
                        placeholder="Секция"
                        value={this.state.lesson.section}
                        onChange={this.handleChangeLesson}
                        style={{ marginBottom: "1em" }}
                    /> */}

                    {bold("Ключевые слова")}
                    <div style={{ marginBottom: "2em" }}>
                        <List divided ordered style={{ marginBottom: "1em" }}>
                            {keywords}
                        </List>

                        <Form onSubmit={this.addKeyword}>
                            <Form.Input
                                fluid
                                name="newKeyword"
                                placeholder="Введите новое ключевое слово"
                                value={this.state.newKeyword}
                                onChange={this.handleChange}
                                style={{ marginBottom: "1em" }}
                            />
                            <Form.Button>Добавить</Form.Button>
                        </Form>
                    </div>

                    {bold("Скрыть урок?")}
                    <Checkbox
                        checked={this.state.lesson.hidden || false}
                        name="hidden"
                        toggle
                        onChange={this.handleChangeLesson}
                        style={{ marginBottom: "1em" }}
                    />

                    {property("Количество блоков", this.state.lesson.length)}
                    {property("Количество вопросов", this.state.lesson.questions)}

                    {bold("Следующая глава")}
                    {this.state.lessons && this.state.lessons.length !== 0 ? (
                        <Dropdown
                            fluid
                            selection
                            name="nextLesson"
                            value={
                                this.state.lesson.nextLesson._id
                                    ? this.state.lessons.findIndex((x) => x._id === this.state.lesson.nextLesson._id)
                                    : -1
                            }
                            options={this.state.lessonsOptions}
                            onChange={this.handleChangeLesson}
                            style={{ marginBottom: "1em" }}
                        />
                    ) : null}

                    {bold("Предыдущая глава")}
                    {this.state.lessons && this.state.lessons.length !== 0 ? (
                        <Dropdown
                            fluid
                            selection
                            name="prevLesson"
                            value={
                                this.state.lesson.prevLesson._id
                                    ? this.state.lessons.findIndex((x) => x._id === this.state.lesson.prevLesson._id)
                                    : -1
                            }
                            options={this.state.lessonsOptions}
                            onChange={this.handleChangeLesson}
                            style={{ marginBottom: "1em" }}
                        />
                    ) : null}

                    <Button
                        loading={this.state.editing}
                        onClick={this.editLesson}
                        style={{
                            backgroundColor: "#32936f",
                            color: "#fff",
                            borderRadius: "0px",
                            boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
                            marginBottom: "8px",
                        }}
                    >
                        Редактировать урок
                    </Button>

                    <Button
                        loading={this.state.deleting}
                        onClick={this.deleteLesson}
                        style={{
                            backgroundColor: "#cc515d",
                            color: "#fff",
                            borderRadius: "0px",
                            boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
                            marginBottom: "2em",
                        }}
                    >
                        Удалить урок
                    </Button>

                    {header("Дополнительные материалы", "20px", 400)}
                    <ExtraMaterial
                        extraMaterial={this.state.lesson.extraMaterial}
                        id={this.state.lesson._id}
                        fetchLesson={this.fetchLesson}
                    />

                    {header("Блоки урока", "20px", 400)}
                    <Accordion fluid styled panels={blocks} style={{ marginBottom: "2em", padding: "1em" }} />

                    {header("Добавить новый блок", "20px", 400)}
                    <Message
                        info
                        icon="hand pointer outline"
                        header="Новый блок"
                        content="Выбери тип нового блока, затем появятся нужные поля"
                    />
                    {bold("Куда добавить блок?")}
                    <Dropdown
                        fluid
                        selection
                        name="position"
                        value={this.state.position}
                        options={this.positions}
                        onChange={this.handleChange}
                        style={{ marginBottom: "1em" }}
                    />
                    <Block 
                        new
                        lessonId={this.state.lesson._id}
                        dialoguePersons={this.state.dialoguePersons}
                        handleNewBlock={this.handleNewBlock}
                    />
                </div>
            );
        }

        return <Container>{this.state.loading ? loader : lesson}</Container>;
    }
}

export default connect(
    (store) => ({}),
    {
        logout,
    }
)(Lesson);
