// modules
import React, { Component } from "react";
import axios from "axios";
// assets
import { bold, header } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
// styles
import { Button, Container, Input, Message } from "semantic-ui-react";
// components
// redux

const redirect = (context, path) => {
    context.props.history.push(path);
};

class NewPractice extends Component {
    state = {
        name: "",
        section: "",
        groupName: "",
        groupNumber: "",
        questions: [],
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    handleAddPractice = () => {
        // show alert if name was not entered
        if (!this.state.name || !this.state.groupName || !this.state.groupNumber) {
            alert("Название урока/группы или номер группы не был введен");
            return;
        }
        // add practice
        axios.post(adminRoutes.practices.addPractice(), { practice: { ...this.state } }).then(() => {
            redirect(this, "/admin/practices");
        });
        // reset state
        this.setState({
            name: "",
            section: "",
            groupName: "",
            groupNumber: "",
            questions: [],
        });
    };

    render() {
        return (
            <Container>
                {header("Добавить новый практический урок", "24px", 600)}
                <Message
                    info
                    icon="hand pointer outline"
                    header="Вопросы практического урока"
                    content="Добавлять вопросы в этот практический урок можно будет после создания, выбрав соответствующий практический урок из списка"
                />
                {bold("Название урока:")}
                <Input
                    fluid
                    required
                    name="name"
                    placeholder="Название урока"
                    value={this.state.name}
                    onChange={this.handleChange}
                    style={{ marginBottom: "1em" }}
                />
                {bold("Секция:")}
                <Input
                    fluid
                    name="section"
                    placeholder="Секция"
                    value={this.state.section}
                    onChange={this.handleChange}
                    style={{ marginBottom: "2em" }}
                />
                {bold("Название группы:")}
                <Input
                    fluid
                    name="groupName"
                    placeholder="Название группы"
                    value={this.state.groupName}
                    onChange={this.handleChange}
                    style={{ marginBottom: "2em" }}
                />
                {bold("Номер группы:")}
                <Input
                    fluid
                    name="groupNumber"
                    placeholder="Номер группы"
                    type="number"
                    value={this.state.groupNumber}
                    onChange={this.handleChange}
                    style={{ marginBottom: "2em" }}
                />
                <Button
                    type="button"
                    onClick={this.handleAddPractice}
                    style={{
                        backgroundColor: "#0e79b2",
                        color: "#fff",
                        borderRadius: "0px",
                        boxShadow: "0px 0px 48px 0px rgba(0, 0, 0, 0.4)",
                        marginBottom: "1em",
                    }}
                >
                    Добавить практический урок
                </Button>
            </Container>
        );
    }
}

export default NewPractice;
