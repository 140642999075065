// modules
import React from "react";
// assets
import { bold } from "assets/formatUtils";
// styles
import { Input } from "semantic-ui-react";
// components
// redux

const IframeBlock = (props) => {
    return (
        <div style={{ marginBottom: "2em" }}>
            {bold("Папка с iframe:")}
            <Input fluid name="iframeSrc" value={props.block.iframeSrc || ""} onChange={props.onChange} />
        </div>
    );
};

export default IframeBlock;
