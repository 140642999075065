// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import DocumentTitle from "react-document-title";
// assets
import { adminRoutes } from "assets/routes";
// styles
import { Button, Card, Container, Header, Image, Loader } from "semantic-ui-react";
import "./index.css";
// components
// redux
import { logout } from "store/User";

const redirect = (context, path) => {
    context.props.history.push(path);
};

class Courses extends Component {
    state = {
        loading: false,
    };

    componentDidMount() {
        this.fetchCourses();
    }

    fetchCourses = () => {
        this.setState({ loading: true });
        axios.get(adminRoutes.courses.getCourses()).then(
            (response) => {
                this.setState({ loading: false, courses: response.data.message });
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    render() {
        let courseCards = [];

        if (this.state.courses && this.state.courses.length !== 0) {
            for (let i = 0; i < this.state.courses.length; i++) {
                const course = this.state.courses[i];

                courseCards.push(
                    <Card key={"admin-course-" + i} href={`/admin/courses/${course._id}`}>
                        <Image src={course.cover} alt="Course card" />
                        <Card.Content>
                            <Card.Header className="admin-courses-card-header">{course.name}</Card.Header>
                        </Card.Content>
                    </Card>
                );
            }
        }

        return (
            <DocumentTitle title="Курсы">
                {this.state.loading ? (
                    <Loader active size="large" />
                ) : (
                    <Container>
                        <Header size="large" textAlign="center">
                            Курсы
                        </Header>

                        <Button
                            className="admin-courses-new-course"
                            onClick={() => redirect(this, "/admin/courses/new")}
                        >
                            Создать новый курс
                        </Button>

                        <Card.Group>{courseCards}</Card.Group>
                    </Container>
                )}
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Courses);
