// modules
import React from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import media from "styled-media-query";
// assets
// styles
import { Modal } from "semantic-ui-react";
// components
import MathjaxWrapper from "components/MathjaxWrapper";
// redux

const StyledHeaderContainer = styled.div``;

const StyledContentContainer = styled.div`
    .ql-align-center {
        text-align: center;
    }

    ${media.lessThan("small")`
        font-size: 12px;
    `}

    ${media.between("small", "medium")`
        font-size: 14px;
    `}

    ${media.between("medium", "large")`
        font-size: 16px;
    `}

    ${media.greaterThan("large")`
        font-size: 18px;
    `}
`;

const FormulasModal = (props) => {
    const { t } = props;

    return (
        <Modal closeIcon style={{ padding: "1em" }} open={props.open} onClose={props.onClose} size="small">
            <Modal.Header>
                <StyledHeaderContainer>{t("dashboard.course.navigation.formulas.header")}</StyledHeaderContainer>
            </Modal.Header>
            <Modal.Content>
                <StyledContentContainer>
                    <MathjaxWrapper text={ReactHtmlParser(props.text)} />
                </StyledContentContainer>
            </Modal.Content>
        </Modal>
    );
};

export default withTranslation()(FormulasModal);
