// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import axios from "axios";
import styled from "styled-components";
// assets
import { dashboardRoutes } from "../../../assets/routes";
// styles
import { Card, Image, Loader } from "semantic-ui-react";
// components
// redux
import { setSidebarMenuItems } from "../../../store/UI";
import { setLocale } from "../../../store/Config";
import { logout } from "../../../store/User";

const StyledImage = styled(Image)`
    width: 100%;
`;

class CoursesList extends Component {
    state = {
        courses: [],
        loading: false,
    };

    componentDidMount() {
        this.fetchCourses();
        this.props.setSidebarMenuItems([]);
    }

    fetchCourses = async () => {
        this.setState({ loading: true });

        axios
            .get(dashboardRoutes.fetchCourses())
            .then((response) => {
                this.setState({
                    loading: false,
                    courses: response.data.message,
                });
            })
            .catch((error) => {
                console.error("Error fetching courses " + error);
                if (error.response.status === 401) {
                    this.props.logout();
                }
            });
    };

    goToCourseAndChangeLocale = (id, locale) => {
        // set locale
        this.props.setLocale(locale);
        // redirect to course (with refresh)
        window.location = `/dashboard/courses/${id}`;
    };

    render() {
        const { t } = this.props;

        const cards = [];
        if (this.state.courses && this.state.courses.length !== 0) {
            for (let i = 0; i < this.state.courses.length; i++) {
                const course = this.state.courses[i];
                cards.push(
                    <Card
                        key={`dashboard-courses-card-${i}`}
                        onClick={() => this.goToCourseAndChangeLocale(course._id, course.language)}
                    >
                        <StyledImage src={course.cover} alt="Course cover image" />
                        <Card.Content>
                            <Card.Header>{course.name}</Card.Header>
                        </Card.Content>
                    </Card>
                );
            }
        }

        return (
            <DocumentTitle title={t("dashboard.courses.header")}>
                <main>
                    <h1>{t("dashboard.courses.header")}</h1>
                    {this.state.loading ? <Loader active size="large" /> : <Card.Group>{cards}</Card.Group>}
                </main>
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    setSidebarMenuItems: (items) => dispatch(setSidebarMenuItems(items)),
    setLocale: (locale) => dispatch(setLocale(locale)),
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CoursesList));
