// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import DocumentTitle from "react-document-title";
// assets
import { italics } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
import { roles } from "assets/strings";
import { compareUsers } from "assets/utils";
import { redirect } from "utils";
// styles
import { Button, Container, Header, Input, List, Loader } from "semantic-ui-react";
import "./index.css";
// components
// redux
import { logout } from "store/User";

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

class Users extends Component {
    state = {
        search: "",
        loading: false,
    };

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = () => {
        this.setState({ loading: true });
        axios
            .get(adminRoutes.users.getUsers())
            .then((response) => {
                this.setState({ loading: false, users: response.data.message });
            })
            .catch((error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            });
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    render() {
        let users = [];
        let userItems = [];
        let usersNumber = 0;

        if (this.state.users && this.state.users.length !== 0) {
            // filter out users
            users = this.state.users.filter((user) => user.scope === "user");
            // count the number of users
            usersNumber = users.length;
            // filter users by name
            users = users.filter((value) => {
                let name = (value.lastName + " " + value.firstName).toLowerCase();
                return name.includes(this.state.search.toLowerCase());
            });
            // sort users
            users.sort(compareUsers);

            // add users to list
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                const verified = user.verification ? user.verification.status : false;
                const paid = user.payment ? user.payment.completed : false;

                userItems.push(
                    <List.Item key={"admin-users-" + i}>
                        <List.Icon name="user" />
                        <List.Content>
                            <List.Header
                                style={{
                                    color: "#3697dd",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginBottom: "8px",
                                }}
                                onClick={() => redirect(this, `/admin/users/${user.email}`)}
                            >
                                {`${user.lastName} ${user.firstName}`} ({verified ? "подтвержден" : "не подтвержден"},{" "}
                                {paid ? "оплатил(-а)" : "не оплатил(-а)"})
                            </List.Header>
                            <List.Description>
                                {italics(capitalize(roles[user.scope]))}
                                {user.email}
                            </List.Description>
                        </List.Content>
                    </List.Item>
                );
            }
        }

        return (
            <DocumentTitle title="Управление учениками">
                {this.state.loading ? (
                    <Loader active size="large" />
                ) : (
                    <Container>
                        <Header size="large" textAlign="center">
                            Управление учениками
                        </Header>

                        {this.props.role === "teacher" && (
                            <Button className="admin-users-new-user" onClick={() => redirect(this, "/admin/users/new")}>
                                Создать нового ученика
                            </Button>
                        )}

                        <div className="admin-users-subheader">Ученики: {usersNumber}</div>
                        <div className="admin-users-search">
                            <Input
                                fluid
                                icon="search"
                                placeholder="Поиск учеников по полному имени"
                                name="search"
                                onChange={this.handleChange}
                            />
                        </div>
                        <List divided relaxed="very">
                            {userItems}
                        </List>
                    </Container>
                )}
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    role: state.user.user.scope,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);
