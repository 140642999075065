// modules
import React, { Component } from "react";
import { connect } from "react-redux";
// assets
import { header } from "assets/formatUtils";
// styles
import { Accordion, Container, Table } from "semantic-ui-react";
// components
import LessonAnalytics from "../LessonAnalytics";
// redux
import { logout } from "store/User";

class CourseAnalytics extends Component {
    render() {
        const table = [];
        const lessons = [];

        if (this.props.lessons && this.props.lessons.length !== 0) {
            for (let i = 0; i < this.props.lessons.length; i++) {
                // extract lesson object
                const lesson = this.props.lessons[i];

                // compute better than
                let betterThan = 0;
                if (lesson.completed) {
                    let results = lesson.results.filter((x) => x.userId !== this.props.user._id);
                    let answers = results.length;
                    if (answers > 0) {
                        let moreThan = results.filter((x) => lesson.correct >= x.correct).length;
                        betterThan = Math.floor((moreThan * 100) / answers);
                    } else {
                        betterThan = 100;
                    }
                }

                // add table row
                table.push(
                    <Table.Row key={`admin-analytics-user-course-${this.props.courseId}-table-row-${i}`}>
                        <Table.Cell>{lesson.section}</Table.Cell>
                        <Table.Cell>{lesson.name}</Table.Cell>
                        <Table.Cell>{lesson.questions}</Table.Cell>
                        <Table.Cell>{lesson.correct}</Table.Cell>
                        <Table.Cell>
                            {lesson.questions !== 0 ? Math.floor((lesson.correct / lesson.questions) * 100) : "---"}
                        </Table.Cell>
                        <Table.Cell>{lesson.completed ? `${betterThan}% учеников` : "урок еще не завершен"}</Table.Cell>
                    </Table.Row>
                );

                // add detailed lesson panels
                lessons.push({
                    key: `admin-analytics-user-course-${this.props.courseId}-lesson-${i}`,
                    title: `${lesson.name}`,
                    content: {
                        content: (
                            <LessonAnalytics
                                courseId={this.props.courseId}
                                lessonId={lesson._id}
                                answers={lesson.answers}
                                userFiles={lesson.userFiles}
                                blocks={lesson.blocks}
                            />
                        ),
                    },
                });
            }
        }

        return (
            <Container style={{ padding: "8px" }}>
                <Table celled selectable style={{ marginBottom: "2em" }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Секция</Table.HeaderCell>
                            <Table.HeaderCell>Название урока</Table.HeaderCell>
                            <Table.HeaderCell>Кол-во вопросов</Table.HeaderCell>
                            <Table.HeaderCell>Кол-во правильных ответов</Table.HeaderCell>
                            <Table.HeaderCell>% правильных ответов</Table.HeaderCell>
                            <Table.HeaderCell>Лучше чем</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{table}</Table.Body>
                </Table>

                {header("Детализация по урокам", "20px", 600)}
                <Accordion fluid styled panels={lessons} style={{ marginBottom: "2em" }} />
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseAnalytics);
