// modules
import axios from 'axios';
// imports
import { authRoutes } from 'assets/routes';
// action type
const TYPE = type => 'workternship/User/' + type;
// axios promises
const PENDING = action => action + '_PENDING';
const DONE = action => action + '_FULFILLED';
const FAIL = action => action + '_REJECTED';

// action types
const GET_USER = TYPE('GET_USER');
const REGISTER = TYPE('REGISTER');
const LOGIN = TYPE('LOGIN');
const LOGOUT = TYPE('LOGOUT');
const FORGOT_PASSWORD = TYPE('FORGOT_PASSWORD');
const EDIT = TYPE('EDIT');
const SET = TYPE('SET');

// initial state
const initialState = {
  getUser: { pending: null, done: null, fail: null },
  register: { pending: null, done: null, fail: null },
  login: { pending: null, done: null, fail: null },
  logout: { pending: null, done: null, fail: null },
  forgotPassword: { pending: null, done: null, fail: null },
  edit: { pending: null, done: null, fail: null },
  user: {}
};

// reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PENDING(GET_USER):
      return { ...state, getUser: { pending: true } };
    case DONE(GET_USER):
      return { ...state, getUser: { done: true }, user: action.payload.data.message };
    case FAIL(GET_USER):
      return { ...state, getUser: { fail: true } };
    case PENDING(REGISTER):
      return { ...state, register: { pending: true } };
    case DONE(REGISTER):
      return { ...state, register: { done: true } };
    case FAIL(REGISTER):
      return { ...state, register: { fail: true } };
    case PENDING(LOGIN):
      return { ...state, login: { pending: true } };
    case DONE(LOGIN):
      return { ...state, login: { done: true } };
    case FAIL(LOGIN):
      return { ...state, login: { fail: true, status: action.payload.response.status } };
    case PENDING(LOGOUT):
      return { ...state, logout: { pending: true } };
    case DONE(LOGOUT):
      return { ...state, logout: { done: true } };
    case FAIL(LOGOUT):
      return { ...state, logout: { fail: true, status: action.payload.response.status } };
    case PENDING(FORGOT_PASSWORD):
      return { ...state, forgotPassword: { pending: true } };
    case DONE(FORGOT_PASSWORD):
      return { ...state, forgotPassword: { done: true } };
    case FAIL(FORGOT_PASSWORD):
      return { ...state, forgotPassword: { fail: true } };
    case PENDING(EDIT):
      return { ...state, edit: { pending: true } };
    case DONE(EDIT):
      return { ...state, edit: { done: true } };
    case FAIL(EDIT):
      return { ...state, edit: { fail: true } };
    case SET:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}

// action creators
export const getUser = () => async dispatch => {
  await dispatch({
    type: GET_USER,
    payload: axios.get(authRoutes.getUser())
  })
    .then(response => {
      // TODO: set user to local storage
      // save user to local storage
      // localStorage.setItem("workternship-user", JSON.stringify(response.value.data.message));
    })
    .catch(() => {
      dispatch(logout());
    });
};

export const register = (firstName, lastName, email, phone, password) => dispatch => {
  return dispatch({
    type: REGISTER,
    payload: axios.post(authRoutes.register(), {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      password: password
    })
  });
};

export const login = (email, password) => dispatch => {
  return dispatch({
    type: LOGIN,
    payload: axios.post(authRoutes.login(), {
      email: email,
      password: password
    })
  }).then(response => {
    dispatch(set({ ...response.value.data.message, headers: response.value.headers }));
  });
};

export const logout = () => dispatch => {
  return dispatch({
    type: LOGOUT,
    payload: axios.post(authRoutes.logout())
  }).then(() => {
    dispatch(unset());
  });
};

export const forgotPassword = email => dispatch => {
  return dispatch({
    type: FORGOT_PASSWORD,
    payload: axios.get(authRoutes.forgotPassword(email))
  });
};

export const edit = (attribute, value) => dispatch => {
  return dispatch({
    type: EDIT,
    payload: axios.post(authRoutes.edit(attribute), { value: value })
  });
};

export const set = user => dispatch => {
  // append authorization header to axios requests
  axios.defaults.headers.common['Authorization'] = user.headers['authorization'];
  // save authorization header to local storage
  localStorage.setItem('workternship-authorization', user.headers['authorization']);
  // save user to local storage
  localStorage.setItem('workternship-user', JSON.stringify(user));

  // dispatch
  dispatch({
    type: SET,
    payload: user
  });
};

export const unset = () => dispatch => {
  // remove authorization header from axios requests
  axios.defaults.headers.common['Authorization'] = '';
  // remove authorization header from local storage
  localStorage.removeItem('workternship-authorization');
  // remove user from local storage
  localStorage.removeItem('workternship-user');

  // dispatch
  dispatch({
    type: SET,
    payload: {}
  });
};
