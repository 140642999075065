// modules
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// components
// redux
import store from './store/Store';
// assets
import translations from './i18n/translations.json';
// styles

i18n.use(initReactI18next).init({
  resources: translations,
  lng: store.getState().config.locale,
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
