// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import DocumentTitle from "react-document-title";
import axios from "axios";
import styled from "styled-components";
import media from "styled-media-query";
// assets
import { authRoutes } from "assets/routes";
// styles
import { Accordion, Button, Icon, Input, Message } from "semantic-ui-react";
// components
// redux
import { getUser } from "store/User";

const StyledWrapper = styled.div`
    font-weight: 500;

    ${media.lessThan("small")`
        font-size: 12px;
    `}

    ${media.between("small", "medium")`
        font-size: 14px;
    `}

    ${media.between("medium", "large")`
        font-size: 16px;
    `}

    ${media.greaterThan("large")`
        font-size: 18px;
    `}
`;

const StyledBlock = styled.div`
    margin-bottom: 2em;
`;

const StyledLink = styled(Link)`
    color: #4682e0;
`;

class Profile extends Component {
    state = {
        activeIndex: -1,
        changed: false,
        changing: false,
        loading: false,
        password: "",
        firstName: this.props.user.firstName || "",
        lastName: this.props.user.lastName || "",
        user: this.props.user,
    };

    fetchUser = () => {
        this.setState({ loading: true });
        axios
            .get(authRoutes.getUser())
            .then((response) => {
                this.setState({ loading: false, user: response.data.message });
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.props.logout();
                }
            });
    };

    handleChange = (e, data) => {
        this.setState({
            [data.name]: data.value,
        });
    };

    handleAccordion = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    };

    handleDismiss = () => {
        this.setState({ changed: false });
    };

    handleUpdatePassword = async () => {
        this.setState({ changing: true });
        const payload = {
            value: this.state.password,
        };
        await axios.post(authRoutes.edit("password"), payload).catch((error) => {
            this.setState({ changing: false });
        });
        this.setState({ changed: true, changing: false, password: "" }, () => {
            setTimeout(this.handleDismiss, 3000);
        });
    };

    handleUpdateFirstName = () => {
        this.setState({ changing: true });
        const payload = {
            value: this.state.firstName,
        };
        axios.post(authRoutes.edit("firstName"), payload).catch((error) => {
            this.setState({ changing: false });
        });
        this.setState({ changed: true, changing: false }, () => {
            setTimeout(this.handleDismiss, 3000);
        });
        this.fetchUser();
        this.props.getUser();
    };

    handleUpdateLastName = () => {
        this.setState({ changing: true });
        const payload = {
            value: this.state.lastName,
        };
        axios.post(authRoutes.edit("lastName"), payload).catch((error) => {
            this.setState({ changing: false });
        });
        this.setState({ changed: true, changing: false }, () => {
            setTimeout(this.handleDismiss, 3000);
        });
        this.fetchUser();
        this.props.getUser();
    };

    render() {
        const { activeIndex } = this.state;
        const { t } = this.props;

        return (
            <DocumentTitle title={t("dashboard.profile.title")}>
                <StyledWrapper>
                    <h1>{t("dashboard.profile.title")}</h1>
                    <StyledBlock>
                        <StyledLink to="/dashboard/courses">{t("dashboard.profile.back")}</StyledLink>
                    </StyledBlock>

                    <StyledBlock>
                        <Accordion fluid>
                            <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleAccordion}>
                                <Icon name="dropdown" />
                                {t("dashboard.profile.changePassword.header")}
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 0}>
                                <Input
                                    fluid
                                    name="password"
                                    placeholder={t("password")}
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    style={{ marginBottom: "1em" }}
                                />
                                <Button
                                    loading={this.state.changing}
                                    onClick={this.handleUpdatePassword}
                                    style={{ marginBottom: "1em" }}
                                >
                                    {t("dashboard.profile.changePassword.header")}
                                </Button>
                            </Accordion.Content>

                            <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleAccordion}>
                                <Icon name="dropdown" />
                                {t("dashboard.profile.changeFirstName.header")}
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                <Input
                                    fluid
                                    name="firstName"
                                    placeholder={t("firstName")}
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                    style={{ marginBottom: "1em" }}
                                />
                                <Button
                                    loading={this.state.changing}
                                    onClick={this.handleUpdateFirstName}
                                    style={{ marginBottom: "1em" }}
                                >
                                    {t("dashboard.profile.changeFirstName.header")}
                                </Button>
                            </Accordion.Content>

                            <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleAccordion}>
                                <Icon name="dropdown" />
                                {t("dashboard.profile.changeLastName.header")}
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 2}>
                                <Input
                                    fluid
                                    name="lastName"
                                    placeholder={t("lastName")}
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                    style={{ marginBottom: "1em" }}
                                />
                                <Button
                                    loading={this.state.changing}
                                    onClick={this.handleUpdateLastName}
                                    style={{ marginBottom: "1em" }}
                                >
                                    {t("dashboard.profile.changeLastName.header")}
                                </Button>
                            </Accordion.Content>
                        </Accordion>

                        <Message
                            hidden={!this.state.changed}
                            positive
                            onDismiss={this.handleDismiss}
                            header={t("dashboard.profile.msg.header")}
                            content={t("dashboard.profile.msg.content")}
                        />
                    </StyledBlock>
                </StyledWrapper>
            </DocumentTitle>
        );
    }
}

export default connect(
    (store) => ({
        user: store.user.user,
    }),
    {
        getUser,
    }
)(withTranslation()(Profile));
