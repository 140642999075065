// modules
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import DocumentTitle from "react-document-title";
// assets
import { header, property } from "assets/formatUtils";
import { adminRoutes } from "assets/routes";
import { userActions } from "assets/strings";
import { formatDate, roleOptions } from "assets/utils";
// styles
import { Button, Checkbox, Container, Dropdown, Icon, Image, List, Loader } from "semantic-ui-react";
import "./index.css";
// components
// redux
import { logout } from "store/User";

const button = (disabled, loading, backgroundColor, onClick, text) => (
    <Button
        disabled={disabled}
        loading={loading}
        style={{
            backgroundColor: backgroundColor,
            color: "#fff",
            borderRadius: "0px",
            marginBottom: "8px",
        }}
        onClick={onClick}
    >
        {text}
    </Button>
);

class User extends Component {
    state = {
        loading: false,
        editing: false,
        resetting: false,
        deleting: false,
        user: {
            lastAction: {},
            payment: {},
            courses: [],
        },
    };

    componentDidMount() {
        const email = this.props.match.params.email;
        this.fetchUser(email);
    }

    fetchUser = (email) => {
        this.setState({ loading: true });
        axios.get(adminRoutes.users.getUser(email)).then(
            (response) => {
                this.setState({ loading: false, user: response.data.message });
            },
            (error) => {
                this.setState({ loading: false });

                if (error.response.status === 401) {
                    this.props.logout();
                }
            }
        );
    };

    handleChange = (e, data) => {
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                [data.name]: data.value,
            },
        }));
    };

    handleVerification = (e, data) => {
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                verification: {
                    ...prevState.user.verification,
                    status: data.checked,
                },
            },
        }));
    };

    handlePayment = (e, data) => {
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                payment: {
                    ...prevState.user.payment,
                    completed: data.checked,
                },
            },
        }));
    };

    handleResetCourse = (courseId) => {
        const r = window.confirm("Сбросить прогресс ученика на этом курсе?");
        if (r) {
            this.setState({ resetting: true });
            axios.post(adminRoutes.users.resetProgress(this.state.user.email, courseId)).then(
                () => {
                    this.setState({ resetting: false });
                    this.fetchUser(this.state.user.email);
                },
                (error) => {
                    console.error("Error:", error);
                    this.setState({ resetting: false });
                }
            );
        }
    };

    handleEdit = () => {
        const r = window.confirm("Редактировать этого ученика?");
        if (r) {
            this.setState({ editing: true });
            axios
                .post(adminRoutes.users.editUser(this.state.user.email), {
                    payment: this.state.user.payment.completed,
                    scope: this.state.user.scope,
                    verificationStatus: this.state.user.verification.status,
                })
                .then(
                    () => {
                        this.setState({ editing: false });
                        this.fetchUser(this.state.user.email);
                    },
                    (error) => {
                        console.error("Error:", error);
                        this.setState({ editing: false });
                    }
                );
        }
    };

    handleDelete = () => {
        const r = window.confirm("Удалить этого ученика?");
        if (r) {
            this.setState({ deleting: true });
            axios.delete(adminRoutes.users.deleteUser(this.state.user.email)).then(
                () => {
                    this.setState({ deleting: false });
                    this.props.history.push("/admin/users");
                },
                (error) => {
                    console.error("Error:", error);
                    this.setState({ deleting: false });
                }
            );
        }
    };

    render() {
        const { lastName, firstName, email, lastAction, scope, verification } = this.state.user;

        const name = `${lastName} ${firstName}`;
        const courseItems = [];

        for (let i = 0; i < this.state.user.courses.length; i++) {
            const course = this.state.user.courses[i].course;

            if (!course) {
                continue;
            }

            courseItems.push(
                <List.Item key={`admin-user-courses-${i}`}>
                    <List.Content floated="right">
                        <Button icon negative labelPosition="right" onClick={() => this.handleResetCourse(course._id)}>
                            <Icon name="remove" />
                            Сбросить прогресс курса
                        </Button>
                    </List.Content>
                    <Image avatar src={course.cover || null} />
                    <List.Content>{course.name}</List.Content>
                </List.Item>
            );
        }

        return (
            <DocumentTitle title="Пользователь">
                {this.state.loading ? (
                    <Loader active size="large" />
                ) : (
                    <Container className="admin-user-container">
                        {header(name, "24px", 600)}
                        {property("Полное имя", name)}
                        {property("Email", email)}
                        {property(
                            "Последнее действие",
                            `${userActions[lastAction.action]} (${formatDate(lastAction.date)})`
                        )}

                        <div className="admin-user-block">
                            <b>Подтверждение регистрации: </b>
                            <Checkbox
                                checked={verification ? verification.status : false}
                                onChange={this.handleVerification}
                            />
                        </div>

                        {this.props.role === "admin" && (
                            <div className="admin-user-block">
                                {property("Роль пользователя")}
                                <Dropdown
                                    selection
                                    name="scope"
                                    value={scope}
                                    options={roleOptions}
                                    onChange={this.handleChange}
                                />
                            </div>
                        )}

                        {/* <div className="admin-user-subheader">Оплата за курс</div>
                        {property("Размер", payment && payment.completed && payment.amount ? payment.amount : 0)}
                        {property("Дата", payment && payment.completed && payment.date ? payment.date : "---")}
                        <div className="admin-user-block">
                            <b>Оплатил курс: </b>
                            <Checkbox checked={payment && payment.completed} onChange={this.handlePayment} />
                        </div> */}

                        {header("Курсы ученика", "20px", 600)}
                        <List divided relaxed="very" verticalAlign="middle" style={{ marginBottom: "2em" }}>
                            {courseItems}
                        </List>

                        {button(false, this.state.editing, "#254441", this.handleEdit, "Редактировать")}
                        {button(scope === "admin", this.state.deleting, "#cc515d", this.handleDelete, "Удалить")}
                    </Container>
                )}
            </DocumentTitle>
        );
    }
}

const mapStateToProps = (state) => ({
    role: state.user.user.scope,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(User);
